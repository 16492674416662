@import '../utilities/lib';
div.boxGrid {
	&.jsTreeControls {
		.box {
			padding-left: 0;
			padding-top: 0;
			padding-right: 0;
			a , div.inactive {
				padding: 0;
				padding-left: 0 ! important;
				margin-bottom: 0;
			}
		}

		.ym-gr {
			padding-left: 0;
		}

		div.boxGrid.jsTreeControls .ym-gl {
			padding: 0;
		}

		a.icon.moveLeft , div.icon.move_left_inactive {
			margin-right: 5px;
		}

		a.icon.moveRight , div.icon.move_right_inactive {
			margin-left: 5px;
		}

		.boxRow {
			margin-bottom: 0;
		}
	}

	.ym-gl div.ym-gbox {
		margin-right: 4px;
	}

	.ym-gr div.ym-gbox {
		margin-left: 3px;
	}

	.jsTreeControls {
		margin-bottom: 5px;
	}
}

div.box {
	padding: 10px;
	a , div.inactive {
		padding-bottom: 5px;
		padding-left: 20px ! important;
	}

	div.inactive {
		background-position: left top;
	}
}

div.boxRow {
	margin-bottom: 7px;
	&.last {
		margin-bottom: 0;
	}
}

div.box a , div.box div.inactive , .box > *:last-child {
	margin: 0 0 5px;
}
