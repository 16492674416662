@import '../../utilities/lib.less';
span {
	&.infotype {
		padding: 10px;
		color: @DarkGrey2;
		display: block;
	}
}

.bas-inputArea .bas-label {
	background-color: @LightGrey1;
	text-align: center;
	width: 120px;
}

.itemBox span span {
	padding-right: 0;
}

#favoritText{
	line-height: 24px;
}