@import "../utilities/lib.less";
#dynamicTree.jstree , div.treeNode {
	li[data-accessright="editTemplatesAll"] a , li[data-accessright="testAutomation"] a , li.nodedisabled a {
		& , &.jstree-clicked {
			position: relative;
		}
	}

	li.nodedisabled a , li.data-nodedisabled a {
		color: #9f9f9f;
		.doubleStrike (20px , #9f9f9f);
	}

	tr , li {
		&[data-accessright="editTemplates"], &[data-accessright="showHiddenSelectTreeNodes"] {
			a , label {
				text-decoration: line-through;
			}

			a {
				color: #000;
			}
		}

		&[data-accessright="editTemplatesAll"] , &[data-accessright="testAutomation"] {
			a {
				& , &.jstree-clicked {
					color: #000 ! important;
				}

				.doubleStrike (20px , #000);
			}

			label, span {
				.doubleStrike (0px , #000);
			}
		}
		
		&[data-accessright="invalid"] {
			a {
				& , &.jstree-clicked {
					color: #000 ! important;
				}

				text-decoration: line-through;
			}

			label, span {
				text-decoration: line-through;
			}
		}
	}
}

.legendElement {
	.selectTreeLegendInvisible {
		color: #9f9f9f;
		.doubleStrike (0 , #9f9f9f);
	}

	.selectTreeLegendEditTemplates {
		text-decoration: line-through;
		color: #000;
	}

	.selectTreeLegendEditTemplatesAll {
		.doubleStrike (0 , #000);
	}

	.selectTreeLegendOutdated {
		text-decoration: line-through;
		color: red;
	}

	.selectTreeLegendError {
		color: red;
	}
}

#areaTree.jstree {
	li.invalid a {
		color: red;
	}
}

.jstree-default ins {
	&.jstree-checkbox , &.jstree-icon {
		background-image: url("../../img/jstree-icons-sprite.png");
	}
}

.nodehidecheckbox > a > .jstree-checkbox {
	display: none;
}

#areaCbTree, #dynamicTree {
	display: none;
	&.jstree-default {
		display: block;
	}
}

