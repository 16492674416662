@import '../../utilities/lib.less';
.ui-autocomplete {
	z-index: @Index_AutoComplete ! important;
}

.ui-autocomplete , ui-menu {
	.ui-menu-item {
		&.ui-state-hover , &.ui-state-active , &.ui-state-focus {
			background: @White;
			border: 1px solid @White;
			a {
				margin: 0;
			}
		}
	}
}

.ui-menu .ui-menu-item {
	list-style-image: none ! important;
	a {
		color: #444;
		&hover , &.ui-state-focus {
			color: @White;
		}
	}
}

.ui-menu-item a.ui-corner-all , .ui-menu-item a.ui-corner-all:hover {
	border: none;
}

.ui-state-hover , .ui-widget-content .ui-state-hover , .ui-widget-header .ui-state-hover , .ui-state-focus , .ui-widget-content .ui-state-focus , .ui-widget-header .ui-state-focus {
	border: none;
}

.ui-widget-content {
	li.ui-state-focus a {
		color: @White;
		.bas-gradient (@LightBlue , @Blue);
	}
}

