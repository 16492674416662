div.tabulation {
	width: 100%;
	div.col {
		display: table-cell;
	}
}

.tabulationContainer.scrollable {
	overflow-x: auto;
	overflow-y: hidden;
	td {
		select {
			display: block ! important;
			visibility: hidden;
		}

		.chosen-container , .chosen-drop {
			position: absolute;
		}
	}
}

table.tabulation {
	table-layout: auto;

	&.fixed {
		table-layout: fixed;
	}

	div.sortIcons {
		display: block;
	}

	td.shrink {
		width: 0.1%;
		white-space: nowrap;
	}

	td.actioncolumn {
		.flex {
			display: flex;
		}
	}

	td.actionColumn {
		a.icon:last-of-type {
			margin-right: 0;
			.iconContainer {
				margin-right: 0;
			}
		}
	}

	td[align=right] {
		text-align: right;
		input {
			text-align: right;
		}
	}

	td[align=center] {
		text-align: center;
		.bas-inputCheck {
			text-align: center ! important;
			display: inline-block;
		}

		& .icon {
			float: none;
		}
	}

	td[align=left] {
		text-align: left;
		input {
			text-align: left;
		}
	}

	tr {
		&.overlayContainer {
			display: none;
		}

		&.footer td.last {
			a:last-child {
				margin-right: 0;
			}
		}
	}
}

div.footer {
	tablePagination {
		.resultsPerPageWrapper {
			float: right;
			> * {
				display: inline-block;
			}
		}
	}

	.pagination {
		.resultsPerPageWrapper {
			float: right;
			> * {
				display: inline-block;
			}
		}
	}
}

table.businesshoursLabelsTabelle {
	width: auto;
}

td.cols_weekday {
	width: 128px;
}

td.cols_time {
	width: 114px;
}

div.mySet {
	div.col {
		display: table-cell;
	}
}

