@import '../utilities/lib.less';
#col3_content {
	form {
		div.panel.first {
			padding-top: 7px;
		}

		+ form {
			div.panel {
				&.first {
					padding-top: 2px;
				}

				&.last {
					padding-bottom: 7px;
					div.panelContent {
						padding-bottom: 7px;
					}
				}

				&:last-of-type {
					margin-bottom: 10px;
				}
			}
		}
	}
}

div.panel {
	background-color: @White;
	padding: 5px 7px 2px;
	font-size: @FontSize;
	z-index: auto;
	.transition (box-shadow 0.5s ease-in);
	.transition (z-index 1.0s ease-in);
	&.toggle {
		div.panelHeader {
			padding-left: 0;

			&, & * {
				cursor: pointer;
			}

			& > * {
				line-height: 20px;
				vertical-align: top;
			}
		}
	}

	div.panelHeader {
		padding-left: 21px;
	}

	&.selecttree.last {
		padding-bottom: 7px;
	}

	input[type=text] {
		&.colsQuarter {
			width: @ColsInputQuarter;
		}

		&.colsThird {
			width: @ColsInputThird;
		}

		&.colsHalf {
			width: @ColsInputHalf;
		}

		&.cols1 {
			width: @ColsInput1;
		}

		&.cols2 {
			width: @ColsInput2;
		}

		&.cols3 {
			width: @ColsInput3;
		}

		&.cols4 {
			width: @ColsInput4;
		}

		&.cols5 {
			width: @ColsInput5;
		}

		&.cols6 {
			width: @ColsInput6;
		}
	}

	input.hasDatepicker {
		width: 170px;
	}

	.counter {
		margin-left: 5px;
	}

	&.containsErrors {
		.panelHeader {
			border: 1px solid @DarkOrange;
			&.expand {
				border-bottom: 0;
				border-radius: @DefaultBorderRadius @DefaultBorderRadius 0 0;
			}

			.panelLabel label , .counter {
				color: @DarkOrange;
			}
		}

		.panelContent {
			border: 1px solid @DarkOrange;
			border-radius: 0 0 @DefaultBorderRadius @DefaultBorderRadius;
			border-top: 0;
		}
	}
}

div.panelHeader {
	background: @LightGrey_2021;
	padding: 5px 5px 0 0;
	height: 30px;
	color: @DarkGrey_2021;
	border-radius: @DefaultBorderRadius;

	&:focus-visible {
		outline: @DefaultOutlineWidth @DefaultOutlineStyle @DefaultOutlineColor;
	}
	a.icon:last-of-type {
		padding-right: 0;
	}

	.toggleIcon {
		background-color: var(--svg-icon-color-normal);
		mask: url("../../img/icons/panels/panel_open_2021.svg") no-repeat 0 -3px;
	}

	&.expand .toggleIcon {
		mask-image: url("../../img/icons/panels/panel_close_2021.svg");
	}
}

.toggleIcon {
	height: 25px;
	width: 25px;
	margin: 0 4px 0 4px;
}

.toggleIcon , div.pictureWithFooter {
	float: left;
}

div.panelContent {
	padding: 7px 7px 12px;
}

.toggleAllPanelWrapper {
	margin-bottom: 10px;
	.bas-inputButton {
		float: right;
	}
}

