@import '../utilities/lib.less';
#basketTrigger{
	padding: 0;
	margin: 0;
	cursor: pointer;
}

#basketContent {
	position: absolute;
	text-align: left;
	.basketElement {
		text-align: left;
		display: inline-block;
		width: 90%;
		.left {
			cursor: default;
			float: left;
		}
		.right {
			cursor: default;
			float: right;
		}
	}
	
}