#editMask.newsletter {
	.bas-formSection {
		.item.horizontal.ym-clearfix {
			width: 100%;
			> .itemBox {
				float: none;
				vertical-align: top;
				input[type=text], .inlineckeditor {
					&.cols1 {
						width: 70px ! important;
					}

					&.cols12 {
						width: 285px ! important;
					}

					&.cols2 {
						width: 312px ! important;
					}

					&.cols_tele_1 {
						width: 35px ! important;
					}

					&.cols_tele_3 {
						width: 142px ! important;
					}

					&.cols_tele_2 , &.cols_tele_4 {
						width: 79px ! important;
					}

					&.cols_tele_34 {
						width: 234px ! important;
					}
				}

				.bas-inputCheck , .bas-inputCheck .lockcheckbox {
					padding-right: 0;
					margin-right: 0;
				}

				.bas-inputCheck .lockcheckbox {
					background-position: left top;
					min-width: 16px;
				}

				#imageSelectionRectangleSubmit {
					width: 362px;
				}
			}
		}
	}

	select.cols3 {
		width: 360px ! important;
	}

	div.charcounter {
		text-align: right;
	}
	
	.item.horizontal {
		&.contains-input.contains-tooltip {
			.itemBox.next-element-is-tooltip {
				& , > * {
					margin-right: -11px;
				}
			}
		}
	}
}

