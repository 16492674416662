@import '../utilities/lib.less';
#notification_success, #notification_selected {
	.circle,.check {
		fill: none;
		stroke-miterlimit:10;
		stroke: @SuccessColor;
	}
	.circle {
		stroke-width: 5;
	}
	.check {
		stroke-width:6;
		stroke-linecap:round;
		stroke-linejoin: round;
	}
}

#notification_warning {
	.circle,.dash {
		fill: none;
		stroke: @WarningColor;
		stroke-width: 5;
		stroke-miterlimit:10;
	}
	.dash {
		stroke-linecap:round;
		stroke-linejoin:round;
	}
}

#notification_arrow {
	.circle, .arrow {
		fill:none;
		stroke: @SuccessColor;
		stroke-width:5;
		stroke-miterlimit:10;
	}
	
	.arrow {
		stroke-linecap:round;
		stroke-linejoin:round;
	}
}

#notification_mail {
	.path {
		fill: @SuccessColor;
	}
}
#notification_error {
	.path {
		fill: @ErrorColor;
	}
}