@import "../utilities/lib.less";

.item.linkGroup {
	padding: 10px 5px 4px;
	display: flex;

	a, span {
		padding: 0 8px;

		&.icon {
			padding: unset;
			margin: 0 8px;
		}
	}

	&.paginationLinks {
		:focus-visible {
			outline: @DefaultOutlineWidth @DefaultOutlineStyle @DefaultOutlineColor;
		}

		a {
			span {
				width: 20px;
			}
		}
	}

	a {
		span {
			display: flex;
			padding: 0;
		}
	}

	span {
		&.paginationCurrentPage {
			text-decoration: underline;
			margin: 0 0 3px 0;
			line-height: normal;
		}

		&.mtPageCurrent {
			color: @White;
			cursor: default;
			background-color: @Green;

			&.incomplete {
				background-color: @DarkOrange;
			}

			&.notCustomizeable {
				background-color: @DarkGrey3;
			}
		}
	}

	a.editPage, span.mtPageCurrent {
		display: inline-block;
		border: 1px solid @LightGrey1;
		margin: 2px;
		padding: 7px 5px;
		font-weight: bold;
	}

	a.editPage span {
		padding: 0;
	}
}

#col3_content .item.linkGroup a.editPage {
	&.notCustomizable {
		background-color: @White;
		color: @DarkGrey3;
	}

	&.complete {
		background-color: @White;
		color: @Green;
	}

	&.incomplete {
		background-color: @White;
		color: @DarkOrange;
	}
}

.tablePagination {
	background: @TableColorDarkGrey;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.tablePagination,
.pagination {
	position: relative;
	padding: 5px;

	> div {
		display: inline-block;
	}

	.resultsPerPageWrapper {
		padding: 3px 0;
		float: right;

		> div {
			display: inline-block;
			padding-left: 5px;
		}
	}

	.resultsPerPageInfo {
		+ .bas-inputSelect {
			+ .item.linkGroup {
				padding: 0;
			}
		}
	}
}

.resultsPerPageWrapper, .pagination {
	.pagelength {
		width: 47px;
	}
}

