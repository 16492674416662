.flex {
	display: flex;
	&.horizontal {
		flex-direction: row;
		&.right{
			justify-content: flex-end;
			&> .itemBox:last-child {
				padding-right: 0;
			}
		}
		> .itemBox {
			display: inline-block;
			float: left;
		}

		> span {
			display: inline;
		}
	}

	&.vertical {
		flex-direction: column;
		.bas-inputButton {
			display: inline-block;
		}

		> .itemBox {
			display: block;
		}
	}

	&.expand {
		width: 100%;
	}
}
