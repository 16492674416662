/* Platz f�r Bildausschnitte schaffen */
.icon, .noIcon{
	height:16px;
	
	background-repeat:no-repeat;
	background-position: left top;
}

.icon{
	width:16px;
}