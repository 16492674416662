.highlightFlash {
	z-index: 1000 ! important;
}

div.panelHeader , div.panelContent {
	display: block;
	position: relative;
}

div.panelHeader a.icon.resetFilter {
	float: right;
}

div.panelToggle {
	float: right;
}

div.panelContent {
	display: none;
	div.bas-inputArea {
		display: block;
	}
}

.toggleIcon {
	outline: 0;
}

.toggle {
	> .panelHeader , > .panelHeader * {
		cursor: pointer;
	}
}

.toggleAllPanelWrapper {
	position: relative;
	width: 100%;
	height: 36px;
	.bas-inputButton > .toggleAllPanels {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
}

