@import '../utilities/lib.less';
#wofWrapper {
	position: fixed;
	padding: 0;
	box-sizing: border-box;
	text-align: left;
	top: 50%;
	overflow: hidden;
	transform: translateY(-50%);
	.transition(width @DefaultAnimation);
	.transition(top @DefaultAnimation);
	.wofSectionGroup {
		overflow: hidden;
		position: relative;
		box-sizing: border-box;
		cursor: pointer;
		float: right;
		clear: right;
		.wofElement {
			white-space: nowrap;
			position: relative;
			&:before {
				content: "";
				position: absolute;
			}
		}
		&,&:hover {
			&.wofSeparator {
				cursor: default;
			} 
		}
	}
	&:hover {
		.wofSectionGroup {
			.transition(width @DefaultAnimation);
			width: 100%;
		}
	}
	
}

div.wofArrow {
	background-repeat: no-repeat;
	background-position: center center;
	position: fixed;
	height: 20px;
	width: 20px;
}
