@import '../utilities/lib.less';

#notification_selected {
	.circle {
		fill: @LightGrey6;
	}
}

.optionTrigger {
	svg {
		&.preferences, &.preferences_scheduled {
			path {
				fill: @White;
			}
			
			.blocker {
				fill: @DarkGrey6;
			}
			
		}
	}
}

.active .optionTrigger svg.preferences_scheduled .blocker {
	fill: @Orange;
}

svg {
	&.preview_lupe {
		path {
			fill: var(--svg-icon-color-normal);
			fill-rule:evenodd;
			clip-rule:evenodd;
		}

		&:hover path {
			fill: var(--svg-icon-color-hover);
		}
	}
}
