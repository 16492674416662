/*
Resetting the default yaml styles 
*/

.ym-cbox , .ym-gbox {
	padding: 0;
}

.box {
	border: 0 none;
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	color: inherit;
	padding: 0;
	margin: 0;
}