@import '../utilities/lib';

#infoPageContents {
	width: @PageWidth;
	
	footer {
		border: 0;
		background-color: @LightGrey_2021;
		text-transform: none;
		padding: 8px 10px;
		box-sizing: content-box;
		a {
			margin-right: 15px;
			padding-bottom: 0;
			line-height: 18px;
		}
	}

	.currentTemplate {
		footer {
			opacity: 0.8;
			margin-top: -34px;
		}
	}
}

div.container {
	footer {
		border-top: 1px solid @LightGrey_2021;
		padding: @GuttingDefault @GuttingDefault @GuttingDefault 445px;
		height: 18px;
		a {
			margin-right: 10px;
		}
	}
}

.SmallBox {
	.bas-gradient(@LightGrey5,@LightGrey6);
	color: @DarkGrey1;
	border: 1px solid @LightGrey;
	&.active {
		background-color: @White;
	}

	&.expanded {
		.bas-gradient(@LightGrey6,@LightGrey5);
		color: @DarkGrey1;
		border: 1px solid @LightGrey;
	}
}

