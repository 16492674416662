@import '../utilities/lib';
.kompakt {
	.resultImage {
		width: 150px;
		img {
			border: 1px solid @LightGrey1;
		}
		&:hover img {
			border-color: #009EE0;
		}
	}

	.resultTemplateInfo a {
		background: url("../../img/arrow_right_blue.png") no-repeat left center;
		padding-left: 10px;
	}
}


.kompakt {
	display: flex;
	flex-wrap: wrap;
	padding-left: 2px;
	p {
		margin: 0;
	}

	h3 {
		font-size: 12px;
		line-height: 15px;
		text-transform: none;
	}

	.portlet {
		border: 1px solid @TertiaryLayoutingColor;
		width: @SelectSiteItemWidth;
		min-height: 297px;
		margin: 10px 20px 10px 0;
		position: relative;
		&:nth-child(2n) {
			margin-right: 0;
		}

		&:hover {
			background-color: @TertiaryLayoutingColor;
		}

		.resultTemplateType {
			color: @DarkGrey_2021;
			bottom: 15px;
			left: 15px;
			margin: 0;
		}

		.templatePreview {
			height: 260px;
			width: 200px;
			& , img {
				float: left;
			}

			img {
				max-height: 260px;
				max-width: 200px;
			}
		}

		.themeTag .iconContainer {
			background-position: -60px 0;
		}

		.localizedTag .iconContainer {
			background-position: -60px 0;
		}
	}
}


div.resultSet.kompakt p {
	margin: 0;
}

.resultSet.kompakt .resultSetRow {
	display: flex;
}

div.resultSet.detail {
	background-color: @White;
	.resultImage a {
		&:hover img {
			border-color: #009EE0;
		}
	}

	p {
		margin: 0;
	}
}

.resultInfoHeader header {
	display: inline-block;
	line-height: 22px;
}

.detail {
	.resultTemplateInfo a {
		padding-right: 3px;
		&.edit_small {
			width: auto;
			height: 16px;
			line-height: 14px;
		}

		&.text.icon {
			padding-left: 20px;
		}

		img {
			margin-bottom: -2px;
		}
	}
	.resultImage img {
		max-width: 150px;
		border: 1px solid @LightGrey1;
	}
}

.cmsTeaser {
	margin-top: 10px;
	margin-bottom: 10px;
}

div.resultSet {
	background-color: @White;
	
	&.superview .resultImage {
		text-align: center;
	}
}

div.resultInfoHeader , div.resultSetSection, div.resultInfoFooter {
	padding-left: 25px;
}

section.resultSetItem {
	border: none;
	.formatnotfound{
		margin-top:1em;
	}
	.admin {
		margin-top: 1em;
		padding: 4px 5px;
	}
	a {
		display: table-cell;
	}

	// div.resultImage a {
	// 	display: block;
	// }
}

header.resultTemplateTitle {
	color: #000;
	font-size: 17px;
	margin-bottom: 5px;
}

.resultInformation {
	position: relative;
	.ym-gl {
		box-sizing: content-box;
		padding-right: 5px;
	}
}

div.templateDescription {
	padding: 10px 5px 5px 25px;
	border-top: 1px solid @LightGrey1;
}

div.resultSetSection {
	border-top: 1px solid @LightGrey1;
	padding-bottom: 3px;
	padding-top: 3px;
	&.active {
		background: url("../../img/current_selected.png") @White no-repeat 6px 7px;
		&.linkAsText {
			background: @White;
		}
	}
}

div.resultInfoHeader {
	padding-bottom: 3px;
	padding-top: 3px;
}

div.resultTemplateDescription {
	margin-bottom: 10px;
}

div.resultTemplateInfo {
	display: table;
	a {
		padding-right: 3px;
		vertical-align: middle;
	}
}

div.previewTag {
	text-align: center;
	font-size: 13px;
}

div.customerInfoWarningSinglePicture , div.customerInfoWarningGroupPicture {
	margin: 10px 0;
	font-weight: bold;
	position: relative;
	div.customerInfoWarningImage {
		background-repeat: no-repeat;
		width: 30px;
		float: left;
		height: 30px;
		margin-right: 5px;
	}
}

div.customerInfoWarningSinglePicture {
	div.customerInfoWarningText {
		color: @DarkOrange;
	}

	div.customerInfoWarningImage {
		background-image: url("../../img/icon_warning_orange.png");
	}
}

div.customerInfoWarningGroupPicture {
	div.customerInfoWarningText {
		color: #F2A900;
	}

	div.customerInfoWarningImage {
		background-image: url("../../img/icon_warning_yellow.png");
	}
}

.portlet {
	.portletLink {
		// width: 100%;
		// height: 100%;
		box-sizing: border-box;
		padding: 0;
		position: relative;
		line-height: 16px;
		display: block;
		& , &:hover {
			border-bottom: none;
		}

		&.inactive {
			cursor: default;
		}
	}

	.templatePreview {
		overflow: hidden;
		border: none;
		margin-right: 12px;
		margin-top: 15px;
		margin-left: 15px;
		img {
			display: block;
		}

		.additionalTemplateNote , .additionalTemplateNoteCms {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			overflow: hidden;
		}

		.portletOverlay {
			width: 100%;
			height: 100%;
			//background-color: @PrimaryBackgroundColor;
			opacity: 0.8;
			top: 0;
			left: 0;
			position: absolute;
			&.postproduction {
				display: none;
			}
		}

		.portletNote {
			width: 110%;
			position: absolute;
			top: 50%;
			left: 50%;
			color: red;
			.translate (-50% , -50%);
			text-align: center;
			z-index: 10;
			> span {
				white-space: pre;
				font-family: @DefaultFont;
				font-weight: 500;
				font-style: normal;
				font-size: 20px;
				width: 100%;
				left: -7%;
				//background: @PrimaryBackgroundColor;
				border: 3px solid red;
				display: inline-block;
				position: relative;
				padding: 10px;
				.rotate (-15deg);
			}
		}

		.previewIcon {
			//background-image: url("../../../../../default/img/loupe.png");
			position: relative;
			top: auto ! important;
			left: auto ! important;
		}
	}

	.wkztag {
		width: 33px;
		position: absolute;
		bottom: -34px;
		right: -31px;
		&_simple {
			text-align: center;
			line-height: 25px;
			font-size: 25px;
			cursor: default;
			color: @HoverColor;
		}
	}

	@abstand: 15px;

	.interactionPortlet {
		position: absolute;
		height: calc(100% - @abstand *2);
		top: @abstand;
		right: @abstand;
		> * {
			display: block;
			margin: 0 auto;
			width: 26px;
			height: 26px;

			&.tooltip {
				display: none;
			}
		}
		
		div.videoicon {
			width: 40px;
			height: 40px;
		}

		.superviewButton {
			width: 32px;
			height: 32px;
			cursor: pointer;
		}

		.infoForUser {
			margin: 0 auto;
			display: block;
		}

		.svg_percent {
			fill: @HoverColor;
			transform: scale(1.2);
		}
	}

	.resultTemplateType {
		span {
			display: inline-block;
			margin-left: 5px;
			&:first-child {
				margin-left: 0;
			}
		}
	}

	.familyDescription {
		font-family: @AllianzNeoBold;
		padding: 15px 44px 5px 0;
		display: flex;
		//clear: both;
		span {
			line-height: 16px;
		}

		.shortdescription {
			display: block;
		}
	}
	.templatedescription{
		margin-bottom: 15px;
		-ms-word-break: break-all;
		word-break: break-word;
		hyphens: auto;
		padding-top: 30px;
		padding-right: 60px;
		font-size: 14px;
		color: @DarkGrey_2021;
		display: -webkit-box;
		-webkit-line-clamp: 10;
		overflow: hidden;
		-webkit-box-orient: vertical;
		/* autoprefixer: off ,important comment or -webkit-box-orient: vertical will disappear from styles!*/
	}

	&.grid .lightboxOverlay .actions .icon.edit_small .iconContainer {
		background-position: -21px -100px;
	}
}

.resultFormatGroup {
	display: block;
	overflow: hidden;
	> a {
		clear: left;
		float: left;
		margin-bottom: 10px;
		display: inline-block;
		&.addTemplateLink {
			display: inline-block;
			margin-bottom: 0;
		}
	}
}

