@import '../../utilities/lib.less';
.ui-datepicker {
	border-radius: @DefaultBorderRadius;
	.ui-datepicker- {
		&header {
			border: 1px solid @LightGrey5;
			border-radius: @DefaultBorderRadius;
		}

		&prev , &next {
			top: 6px;
		}
	}

	tbody {
		tr , td {
			border: 1px solid @MediumGrey6;
			border-radius: @DefaultBorderRadius;
		}
	}

	td {
		padding: 0;
		a.ui-state-default:hover {
			color: @DarkBlue;
		}
	}

	.ui-datepicker-unselectable {
		& , &:hover {
			opacity: 1;
			.ui-state-default {
				color: @InactiveFontColor;
			}
		}
	}

	.early-start-not-selected .ui-state-default {
		color: @DarkOrange;
	}


	.ui-datepicker-today .ui-state-default {
		& , &:hover {
			background: @Blue;
			color: @White;
		}
	}
	.ui-datepicker-current-day {
		& , &.ui-datepicker-today {
			.ui-state-default {
				& , &:hover {
					background-color: @DarkBlue;
					color: @White;
				}
			}
		}
	}
}

.ui-datepicker-month , .ui-datepicker-year {
	width: auto ! important;
}

.ui-datepicker-month {
	float: left;
}

.ui-datepicker-year {
	float: right;
}

.ui-datepicker-calendar {
	.ui-state- {
		&default , &hover , &highlight , &active {
			border: none;
			background: none;
			color: @Black;
		}
	}
}

.ui-widget-header {
	.ui-datepicker- {
		&prev , &next {
			.ui-icon {
				background-repeat: no-repeat;
				background-position: 0 0;
				cursor: pointer;
			}

			&.ui-state-disabled .ui-icon {
				cursor: default;
			}
		}

		&prev {
			&-hover {
				left: 2px;
			}

			.ui-icon {
				background-image: url("../../pluginPacks/jqueryUi/images/icon_pagination_last_left.svg");
				&:hover {
					background-image: url("../../pluginPacks/jqueryUi/images/icon_pagination_last_left_hover.svg");
				}
			}
		}

		&next {
			&-hover {
				right: 2px;
			}

			.ui-icon {
				background-image: url("../../pluginPacks/jqueryUi/images/icon_pagination_last_right.svg");
				&:hover {
					background-image: url('../../pluginPacks/jqueryUi/images/icon_pagination_last_right_hover.svg');
				}
			}
		}
	}
}

span.fromTo {
	float: left;
	margin: 0;
}

input.datepicker , input.clockpicker {
	width: 98px;
	max-width: none;
	margin-right: 1px;
}

