div.panel {
	position: relative;
	&.smartFilter {
		.panelContent {
			display: flex;
			.filter {
				position: relative;
				margin: 10px;
				padding: 10px;
				padding-bottom: 70px;
				.filterGroup {
					&[data-toggle-group] {
						display: none;
					}

					&.button {
						position: absolute;
						bottom: 0;
						left: 10px;
					}
				}

				a[data-toggle-target] {
					margin: 20px 20px 0 0;
					.iconContainer , span {
						cursor: pointer;
					}
				}

				.filterGroup.separator {
					padding: 5px 0;
					margin-top: 5px;
				}
			}
		}
	}
}

