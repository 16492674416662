@import '../utilities/lib.less';
@import '../yaml/core/base.css';
@import '../yaml/screen/typography.css';
@import '../yaml/add-ons/accessible-tabs/tabs.css';
@import 'content_reset.less';
@import 'forms.less';
@import 'extensions.less';

#debugItems {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	border: 1px solid #000;
	padding: 20px;
	z-index: @Index_Debug;
	background-color: #fff;
	.debugItem {
		display: inline-block;
		margin-right: 10px;
		&.active {
			color: red;
		}
	}

	> div {
		display: inline-block;
		float: left;
		margin-right: 10px;
		&:last-child {
			margin-right: 0;
		}
	}

	fieldset {
		border: 1px solid #6b6b6b;
		box-sizing: border-box;
		display: inline-block;
		box-shadow: 0 2px 5px #949494;
		legend {
			padding: 0 5px;
			text-align: center;
		}
	}

	.debugFunctions {
		fieldset {
			padding: 10px;
			a {
				display: inline-block;
				clear: left;
				float: left;
			}
		}
	}

	.pluginListContainer {
		.pluginListFieldSet {
			ul {
				padding: 10px;
				list-style: none;
				margin-bottom: 0;
				input[type="checkbox"] {
					margin-top: 3px;
				}

				div.checkbox {
					display: inline-block;
					padding-right: 5px;
				}
			}

			.bas-inputButton {
				text-align: center;
				a {
					margin: 0;
				}
			}
		}
	}
}

html {
	height: 100%;
	overflow-y: scroll;
}

body {
	&.noScrollbars {
		overflow: hidden;
	}
}

.jcrop-holder img , .ui-datepicker-trigger {
	max-width: none;
}

.toggle_visibility {
	display: none !important;
}
