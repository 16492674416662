.ym-wrapper {
	width: 1024px;
	margin: 0 auto;
	text-align: left;
}

#header {
	position: relative;
}

#footer {
	position: relative;
}

#topnav {
	position: absolute;
	right: 20px;
	top: 20px;
}

div.automaticreload {
	display: none;
}

span.number {
	float: right;
}

.input-prompt {
	position: absolute;
	font-style: italic;
	color: #aaa;
	margin: 0.2em 0 0 0.5em;
}

div {
	&.placeholderpicture , &.cmsplaceholderpicture {
		background-color: #D0D0D0;
		padding: 100px 0;
		text-align: center;
		margin: 0 0 7px 0;
	}
}

#errorFlag {
	visibility: hidden;
}

.ui-autocomplete {
	text-align: left;
}

img.lightboxPreview {
	width: 100%;
	display: block;
	margin: 0 auto
}

.noOverflow {
	overflow: hidden;
	height: 100%;
}

.imageList {
	display: none;
}

.cke_combo__fontsize .cke_combo_text {
	width: 50px ! important;
}

.whiteSpace {
	width: 100%;
	height: 25px;
}

.cropBox[data-crop-rounded] .jcrop-holder > div > div {
	border-radius: 50%;
	.jcrop-hline, .jcrop-vline {
		display: none;
	}
}