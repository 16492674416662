.tooltip {
	z-index 			:	99999;
	padding				:	5px;
	display				:	none;
	-moz-box-shadow		:	4px 4px 4px #666;
	-webkit-box-shadow	: 	4px 4px 4px #666;
	box-shadow			:	4px 4px 4px #666;
	text-align			:	left;
	white-space			:	normal;
	max-width	    	:   450px;
	position			:	absolute;
}
.tooltip * {
	padding: 0;
	margin: 0;
}
.ie-tooltip-shadow {
	z-index				:	99998;
	background			:	#000;
	position			:	absolute;
	filter				:	progid:DXImageTransform.Microsoft.Blur(PixelRadius = '4', MakeShadow = 'true', ShadowOpacity = '0.60');
}
/* die tooltipbreite auf der edit-seite kann hier kundenspezifisch eingestellt werden. Dazu tooltip.css für jeden kunden anlegen */
.width-for-editpage {
	width				:	300px;
}
.width-for-editpage table {
	width				:	100%;
}
.infotype_bold{
	font-weight			:	bold;
}
.tooltipTrigger {
	display				:	inline;	
}