@import '../utilities/lib.less';

#searchMask {
	input.hasDatepicker {
		width: @ColsInputTwoThird;
	}
}

div.panel.smartFilter .panelContent {
	.filter {
		margin: 0;
		input.hasDatepicker {
			width: @ColsInputTwoThird;
		}

		.filterGroup.button {
			background: transparent;
			bottom: 30px;
			left: 0;
		}

		.toggleButtonContainer {
			background: transparent;
			
			+ div.bas-formBox {
				background: transparent;
			}
		}

		&:not(:first-of-type) {
			margin-left: 20px;
			&:before {
				content: '';
				height: 100%;
				width: 1px;
				position: absolute;
				left: -10px;
				top: 0;
				background-color: @LightGrey1
			}
		}
		
		.filterGroup.separator {
			border-top: 1px solid @LightGrey1;
			margin-top: 5px;
			padding-top: 5px;
			a {
				font-size: @FontSize;
			}
		}

		&[data-active] , &:hover {
			box-shadow: 0 0 4px 0 @DarkBlue;
		}

		a[data-toggle-target] span {
			line-height: 18px;
		}
	}
}
.bas-inputArea{
	padding-left: 5px;
	margin-top: 2px;
	margin-bottom: 2px;
}

.bas-filterComponent {
	padding: 0 5px;
	margin-bottom: 2px;
	min-height: 34px;
	&:not(.searchButtonPosition) {
		display: flex;
		align-items: center;
	}

	&.searchButtonPosition {
		text-align: right;
		padding: 0;
		.bas-inputButton {
			display: inline-block;
		}
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	> label {
		width: @FieldLabelWidth;
		min-width: @FieldLabelWidth;
		padding: 4px 0;
	}

	&.checkboxContainer {
		label , .bas-checkboxSubContainer {
			display: table-cell ! important;
		}
	}

	.bas-checkboxSubContainer {
		.bas-inputCheck {
			margin-right: 10px;
			width: 180px;
			line-height: 20px;
			> input.checkbox , > input.radio , > input[type="checkbox"] , > input[type="radio"] {
				margin-right: @GuttingDefault;
			}

			> * {
				display: inline-block ! important;
			}

			label {
				width: auto;
				min-width: 0;
			}
		}
	}

	label , .bas-inputCheck , .bas-inputSelect , .bas-inputText {
		display: inline-block ! important;
		margin: 0 0 0 0;
		padding: 2px 0;
	}

	input[type=text] , select {
		width: @ColsInput3;
		height: 30px;
	}
}

