@import "../utilities/lib.less";

div.overlay {
	div.ajaxOverlayImage {
		box-sizing: content-box;
		padding: 15px 95px 40px 95px;
		background: url("../../img/ajax-loader-bg.png") @White 0 0 repeat-x;
		border: 3px solid @White;
		box-shadow: 5px 5px 10px 0 @MediumGrey2;
	}

	.overlayText {
		font-size: 14px;
		font-weight: bold;
		color: @Blue;
		margin: 0 0 5px 0;
	}
}

div.imageLoaderOverlay, div.overlay {
	.loader {
		background: url("../../img/ajax-loader.gif");
		width: 128px;
		height: 18px;
	}
}

.overlay .progressMonitor.upload {
	background: url("../../img/ajax-loader-bg.png") @White 0 0 repeat-x;
	border: 3px solid @White;
	height: auto;
	box-shadow: 5px 5px 10px 0 @MediumGrey2;

	.progressPercentageContainer {
		color: @Blue;
		font-size: 14px;
		padding: 10px 0;
	}

	.progressBarContainer {
		background: transparent;
		height: 30px;

		.progressBar {
			background: @Blue;
		}
	}
}
