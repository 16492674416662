@import '../utilities/lib.less';
div.flex {
	&.vertical {
		.item.horizontal {
			display: flex;
		}

		a.icon.text {
			margin: 0 0 4px;
		}
	}
}

.flex {
	&.linkGroup {
		text-align: center;
	}

	&.horizontal .itemBox {
		margin: 0 2px 2px 0;
	}

	&.horizontal-right > .itemBox {
		float: none;
	}
}
