@import '../utilities/lib.less';
div.overlay , .dialog div.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url("../../img/overlay_background_dark.png");
	z-index: @Index_DialogWrapper;
	.loader {
		background: url("../../img/ajax-loader-darkbg.gif");
		width: 64px;
		height: 64px;
		position: absolute;
	}

	&.ajax {
		background-image: url("../../img/overlay_background.png");
	}

	&.overlayHelper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.overflowHidden {
	overflow: hidden;
}

.sliderContainer {
	position: relative;
	padding-top: 10px;
	width: 100%;
	height: 315px;
	div.overlay {
		position: absolute;
		height: 100%;
		top: inherit;
		left: inherit;
		.sliderLoader {
			background: url("../../img/ajax-loader-darkbg.gif") no-repeat;
			width: 64px;
			height: 64px;
			display: block;
			vertical-align: middle;
			margin: 130px auto;
		}
	}
}

div.ajaxOverlayImage {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

div.imageLoaderOverlay {
	width: 100%;
	height: 100%;
	position: absolute;
	.loader {
		background: url("../../img/ajax-loader-darkbg.gif");
		width: 64px;
		height: 64px;
		position: absolute;
	}
}

.overlay .progressMonitor.upload {
	width: 20%;
	height: 100px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	
	&.mode-circle {
		width: 100%;
		height: 100%;
		position:relative;
		
		.progressPercentageContainer, svg.radial-progress {
			position: absolute;
			top: 50%;
			left: 50%;
		}
		
		.progressPercentageContainer {
		    .translate(-50%,-50%);
		    font-size: 40px;
		    width: auto;
		}
		
		svg.radial-progress {
			display: inline-block;
			transform: rotate(270deg) translate(57%,-90%);
			overflow: visible;
			.radial-progress-circle {
				stroke-width: 1em;
				fill: transparent;
				transform: rotate(0.1deg);
				stroke: #fff;
			}
	
		}
	}
	.progressPercentageContainer {
		width: 100%;
		color: white;
		text-align: center;
	}

	.progressBarContainer {
		background: #fff;
		box-sizing: border-box;
		padding: 10px;
		width: 100%;
		height: 50px;
		.progressBar {
			float: left;
			width: 0;
			height: 100%;
			background: #000;
		}
	}

}

