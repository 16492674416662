@import 'variab.less';
.defaultButton () {
	color: @White;
	height: 22px;
	min-height: 22px;
	line-height: 21px;
	padding: 0 10px 0 10px;
	text-decoration: none;
	display: inline-block;
	width: 100%;
	white-space: nowrap;
	cursor: pointer;
	box-sizing: content-box;
}

.defaultButtonService() {
	color: @White;
	width: 142px;
	height: 22px;
	min-height: 22px;
	line-height: 21px;
	text-decoration: none;
	padding: 0 10px;
	position: relative;
	.bas-gradient (@DarkGrey6 , @DarkGrey5);
}