@import '../utilities/lib.less';
#editMaskContainer {
	.bas-formSection {
		& , .item.horizontal.ym-clearfix .itemBox {
			input[type=text] {
				&[size="1"] {
					width: 37px ! important;
				}

				&[size="2"] {
					width: 42px ! important;
				}

				&[size="3"] {
					width: 47px ! important;
				}

				&[size="4"] {
					width: 52px ! important;
				}

				&[size="5"] {
					width: 57px ! important;
				}

				&[size="6"] {
					width: 62px ! important;
				}

				&[size="7"] {
					width: 66px ! important;
				}

				&[size="8"] {
					width: 71px ! important;
				}

				&[size="9"] {
					width: 76px ! important;
				}

				&[size="10"] {
					width: 81px ! important;
				}

				&[size="11"] {
					width: 86px ! important;
				}

				&[size="12"] {
					width: 91px ! important;
				}

				&[size="13"] {
					width: 96px ! important;
				}

				&[size="14"] {
					width: 100px ! important;
				}

				&[size="15"] {
					width: 105px ! important;
				}

				&[size="16"] {
					width: 110px ! important;
				}

				&[size="17"] {
					width: 115px ! important;
				}

				&[size="18"] {
					width: 120px ! important;
				}

				&[size="19"] {
					width: 125px ! important;
				}

				&[size="20"] {
					width: 130px ! important;
				}

				&[size="21"] {
					width: 135px ! important;
				}

				&[size="22"] {
					width: 139px ! important;
				}

				&[size="23"] {
					width: 144px ! important;
				}

				&[size="24"] {
					width: 149px ! important;
				}

				&[size="25"] {
					width: 154px ! important;
				}

				&[size="26"] {
					width: 159px ! important;
				}

				&[size="27"] {
					width: 164px ! important;
				}

				&[size="28"] {
					width: 169px ! important;
				}

				&[size="29"] {
					width: 173px ! important;
				}

				&[size="30"] {
					width: 178px ! important;
				}

				&[size="31"] {
					width: 183px ! important;
				}

				&[size="32"] {
					width: 188px ! important;
				}

				&[size="33"] {
					width: 193px ! important;
				}

				&[size="34"] {
					width: 198px ! important;
				}

				&[size="35"] {
					width: 203px ! important;
				}

				&[size="36"] {
					width: 207px ! important;
				}

				&[size="37"] {
					width: 212px ! important;
				}

				&[size="38"] {
					width: 217px ! important;
				}

				&[size="39"] {
					width: 222px ! important;
				}

				&[size="40"] {
					width: 227px ! important;
				}

				&[size="41"] {
					width: 232px ! important;
				}

				&[size="42"] {
					width: 237px ! important;
				}

				&[size="43"] {
					width: 241px ! important;
				}

				&[size="44"] {
					width: 246px ! important;
				}

				&[size="45"] {
					width: 251px ! important;
				}

				&[size="46"] {
					width: 256px ! important;
				}

				&[size="47"] {
					width: 261px ! important;
				}

				&[size="48"] {
					width: 266px ! important;
				}

				&[size="49"] {
					width: 271px ! important;
				}

				&[size="50"] {
					width: 275px ! important;
				}

				&[size="51"] {
					width: 280px ! important;
				}

				&[size="52"] {
					width: 285px ! important;
				}

				&[size="53"] {
					width: 290px ! important;
				}

				&[size="54"] {
					width: 295px ! important;
				}

				&[size="55"] {
					width: 300px ! important;
				}

				&[size="56"] {
					width: 305px ! important;
				}

				&[size="57"] {
					width: 309px ! important;
				}

				&[size="58"] {
					width: 314px ! important;
				}

				&[size="59"] {
					width: 319px ! important;
				}

				&[size="60"] {
					width: 324px ! important;
				}
			}
		}
	}
}

