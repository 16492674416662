@import '../utilities/lib.less';

.reportList {
  margin-bottom: 10px;
  padding-top: 10px;
  .defaultLink {
    margin-bottom: 5px;
  }

  span {
    display: block;
    word-break: break-all;
  }

  .descriptionKey {
    margin-bottom: 5px;
  }

  .reportItem {
    margin-bottom: 10px;
    border: 1px solid #D0D3D4;
    padding: 5px;
    .mainRow {
      display: flex;
      > div {
        height: 26px;
        flex-direction: column;
        box-sizing: border-box;
        &.favIcon {
          margin-top: 3px;
          padding: 0;
        }

        &.reportName {
          flex-grow: 1;
          height: 23px;
          margin-top: 3px;
        }

        &.toggleTrigger {
          margin-right: 10px;
          *, & {
            outline: 0;
          }
        }

        &.favIcon, &.expandIcon {
          &:last-child {
            padding-right: 0;
          }

          &:first-child {
            padding-left: 0;
            margin-left: 5px;
          }

          &:not(.inactive) {
            cursor: pointer;
          }

          i {
            margin: 0;
            padding: 0;
          }
        }
      }
    }

    .additionalRow {
      display: none;
      padding: 5px;
      > div {
        display: block;
        margin-bottom: 10px;
        margin-left: 40px;
        margin-right: 30px;

        &.reportId {
          word-break: break-all;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

#favoriteMark {
  display: none;
}

