@import '../utilities/lib';
.chosen-container .chosen-results li {
	&.lessimportant {
		font-style: italic;
		margin-right: 5px;
		border-top: 1px dotted gray;
		~ .lessimportant {
			padding-top: 2px;
			border-top: none;
			margin-right: 0;
		}
		&.hideitem {
			display: none;
		}	
	}
	&.disable.hideitem {
		display: none;
	}	
}

select option {
	&.lessimportant {
		font-style: italic;
	}
	&.hideitem {
		display: none;
	}
}