@import '../../utilities/lib';
.jspTrack , .jspVerticalBar {
	background-color: transparent ! important;
}

.jspVerticalBar {
	border-left: 1px solid @White;
	.jspDrag {
		background-color: @White ! important;
	}
}

.jspArrow {
	&.jspArrowUp {
		background: url("../../../img/icons/panels/panel_close.png") no-repeat scroll -3px -2px @White ! important;
		border-bottom: 1px solid @Blue;
	}

	&.jspArrowDown {
		background: url("../../../img/icons/panels/panel_open.png") no-repeat scroll -3px -2px @White ! important;
		border-top: 1px solid @Blue;
	}
}

