@import '../utilities/lib.less';

textarea {
	resize: vertical;
}

span {
	&.fromTo {
		padding: 0 5px;
	}

	&.textbold {
		font-weight: bold;
	}

	&.infotext_bold {
		color: #868889;
	}

	&.businesshours_inline_block {
		display: inline-block;
	}
}

div.non-breaking-text {
	white-space: nowrap;

	span {
		padding-right: 5px;

		&:last-child {
			padding-right: 0;
		}
	}
}

.hint {
	&.inText {
		margin-top: 5px;
	}

	&.tooltipTrigger.infoIcon {
		margin: 0 0 0 10px;
		vertical-align: top;
	}
}

.tooltip {
	display: none;

	.description {
		padding: 0 20px 20px 0;
	}

	.tooltipColumn {
		display: flex;
	}

	.tooltipCol1 {
		width: 100%;
	}

	.tooltipCol2 {
		width: 50%;
	}

	.tooltipCol3 {
		width: 33.3333%;
	}

	.tooltipCol4 {
		width: 25%;
	}
}

.component.notification {
	width: 100%;
	display: flex;

	.icon_notification {
		width: 148px;
		padding: 20px 36px;
		flex-shrink: 0;
	}

	.text_notification {
		display: inline-block;
		margin-top: 30px;
		flex-grow: 1;

		&.oneLine {
			margin-top: 40px;
		}

		&.top {
			margin-top: 0;
		}
	}

	&.v-whitespace- {
		&0 {
			.v-whitespace-inner(0);
		}

		&10 {
			.v-whitespace-inner(10);
		}

		&20 {
			.v-whitespace-inner(20);
		}

		&30 {
			.v-whitespace-inner(30);
		}

		&40 {
			.v-whitespace-inner(40);
		}

		&50 {
			.v-whitespace-inner(50);
		}
	}
}