@import "../utilities/lib.less";

div.resultSetItem {
	clear: left;
}

div.resultSet.detail {
	.portletInnerContainer {
		width: 100%;
	}
}

.detail {
	.resultImage, .templatePreview {
		img {
			max-width: 150px;
		}
	}

	.familyData {
		padding-left: 0;
		position: relative;
	}
}

.kompakt {
	.resultSetRow {
		display: flex;
	}

	div.resultImage {
		display: block;

		.defaultLink {
			line-height: 0;
		}
	}

	.resultInfo {
		display: block;
	}
}

.ribbon {
	position: absolute;
	right: -4px;
	bottom: -5px;
	z-index: 1;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;

	span {
		display: block;
		position: absolute;
		top: 37px;
		right: -22px;
		font-size: 10px;
		font-weight: bold;
		color: #ffffff;
		text-align: center;
		line-height: 20px;
		width: 100px;
		.rotate(-45deg);
		box-shadow: 0 -2px 10px -5px #000000;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: -6px;
			z-index: -1;
			border-right: 3px solid transparent;
			border-top: 3px solid transparent;
		}
	}

	&:after {
		content: "";
		position: absolute;
		right: -3px;
		top: 2px;
		z-index: -1;
		border-right: 3px solid transparent;
		border-bottom: 3px solid transparent;
		border-top: 3px solid transparent;
	}
}

