@import '../utilities/lib';
#newsletterPreview {
	width: 600px;
	applet, object,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, font, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, 
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td {
		margin:0;
		border:0;
		padding:0;
		line-height: normal;
		-webkit-text-size-adjust: none;
	}
	div,span {
		margin:0;
		border:0;
		padding:0;
		line-height: inherit;
		-webkit-text-size-adjust: none;
	}
	li {
		margin: 0 0 0 30px;
		border:0;
		padding:0;
		line-height: normal;
		-webkit-text-size-adjust: none;
	}
	td, img {
		vertical-align: middle;
	}
	table {
		font-size: medium;
		border-collapse: collapse;
	}
	.editablerichtext > p {
		margin-bottom: 10px;
	}
}

.rectanglesActive {
	outline: solid 2px @Color_CaretActive;
}

.preview-highlight {
	outline: solid 2px @Color_CaretFocus;
}
