@import '../utilities/lib.less';
.item {
	display: block;
	&.horizontal {
		display: flex;
		flex-wrap: wrap;
		&.flex-center {
			display: flex;
			align-items: center;
		}
		&.right{
			justify-content: flex-end;
			&> .itemBox:last-child {
				padding-right: 0;
			}
		}
		&.last-right>.itemBox:last-of-type {
			float: right;
			padding-right: 0;
		}
		> .itemBox {
			display: inline-block;
		}

		> span {
			display: inline;
		}
		
		&.no-white-space > .itemBox {
			span, div {
				margin-right: 0;
			}
		}
	}

	&.horizontal-right {
		justify-content: flex-end;
	}

	&.explode {
		display: flex;
		justify-content: space-between;
	}

	&.vertical {
		.bas-inputButton {
			display: inline-block;
		}

		> .itemBox {
			display: block;
		}
	}

	&.buttonlinetop > * {
		margin-right: 10px;
	}

	&.alignLeft , &.alignLeft.linkGroup {
		text-align: left;
	}
}

td[align='center'] .item.horizontal > .itemBox {
	float: none;
}

td[align='right'] .item.horizontal > .itemBox {
	display: inline-block;
	float: none;
	&:last-child {
		padding-right: 0;
	}
}

#editMaskContainer #imageSelectionRectangleSelect {
	width: 100% !important;
}

.item.horizontal.withPadding {
	padding: 20px 0 0 0;
}
