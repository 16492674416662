@import "../../utilities/lib.less";

@_iconPath: "../../../img/icons";
@imgPath: "../../../img";
@ColorNormal: @DarkGrey_2021;
@ColorHover: @DefaultLinkNormalColor;
@ColorInactive: #c2c2c2;
@OutlineColor: @DefaultOutlineColor;
@ForcedColorNormal: LinkText;
@ForcedColorHover: ButtonText;
@ForcedColorInactive: GrayText;
@ForcedOutlineColor: Highlight;
@Size: 24px;
@FocusTransparentBorderWidth: 2px;

:root,
::before {
	--svg-icon-color-normal: @ColorNormal;
	--svg-icon-color-hover: @ColorHover;
	--svg-icon-color-inactive: @ColorInactive;
	--icon-outline-color-focus: @OutlineColor;

	@media screen and (forced-colors: active) {
		--svg-icon-color-normal: @ForcedColorNormal;
		--svg-icon-color-hover: @ForcedColorHover;
		--svg-icon-color-inactive: @ForcedColorInactive;
		--icon-outline-color-focus: @ForcedOutlineColor;
	}
}

.mask(@svg) {
	&::before {
		mask: url(@svg) no-repeat;
	}
}

a.icon,
div.icon {

	&.moveTop,
	&.move_first {
		.mask("@{_iconPath}/general/icon_arrow_small_top.svg");
	}

	&.moveBottom,
	&.move_last {
		.mask("@{_iconPath}/general/icon_arrow_small_bottom.svg");
	}

	&.openAll,
	&.addNew {
		&,
		&_inactive {
			.mask('@{_iconPath}/general/icon_plus_small.svg');
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.closeAll {
		&,
		&_inactive {
			.mask('@{_iconPath}/general/icon_minus_small.svg');
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.expand_small {
		background-image: url("@{_iconPath}/general/icon_carets_small_open.png");

		&:hover {
			background-image: url("@{_iconPath}/general/icon_carets_small_open_hover.png");
		}
	}

	&.collapse_small {
		background-image: url("@{_iconPath}/general/icon_carets_small_close.png");

		&:hover {
			background-image: url("@{_iconPath}/general/icon_carets_small_close_hover.png");
		}
	}

	&.pdf_preview {
		.mask("@{_iconPath}/general/icon_pdf_preview.svg");
	}

	&.desktopPreview {
		height: 20px;
		width: 20px;
		mask-image: url("@{_iconPath}/icon_desktop_view.svg");

		&:focus {
			background-color: var(--svg-icon-color-hover);
		}
	}

	&.mobilePreview {
		height: 20px;
		width: 20px;
		mask-image: url("@{_iconPath}/icon_mobile_view.svg");

		&:focus {
			background-color: var(--svg-icon-color-hover);
		}
	}

	&.zip {
		margin-top: 1px !important;
		.mask("@{_iconPath}/general/icon_zip.svg");
	}

	&.sortUp {
		.mask("../../../img/12x12/sort_up.svg");
	}

	&.sortDown {
		.mask("../../../img/12x12/sort_down.svg");
	}

	&.redo_small {
		.mask("@{_iconPath}/general/icon_redo_small.svg");
	}

	&.redo_small.resetFilter {
		.mask("@{_iconPath}/general/icon_undo_small.svg");
	}

	&.paste {
		.mask("@{_iconPath}/general/icon_clipboard_small.svg");
	}

	&.copy {
		.mask("@{_iconPath}/general/icon_copy_small.svg");
	}

	&.rename {
		.mask("@{_iconPath}/general/icon_edit_small.svg");
	}

	&.add,
	&.new_small {
		.mask("@{_iconPath}/general/icon_new_small.svg");
	}

	&.upload {

		&,
		&_inactive {
			.mask("@{_iconPath}/general/allianz-upload.svg");
		}

		&_inactive {
			cursor: default;
		}
	}

	&.cut {
		.mask("@{_iconPath}/general/icon_scissor_small.svg");
	}

	&.delete,
	&.delete_small {
		.mask("@{_iconPath}/general/icon_delete_small.svg");
	}

	&.lock_small,
	&.lock_button {

		&,
		&_inactive {
			.mask("../../../img/btn/icon_lock.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
		}
	}

	&.unlock_small,
	&.unlock_button {

		&,
		&_inactive {
			.mask("../../../img/btn/icon_unlock.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
		}
	}

	&.visible_small {
		&,
		&_inactive {
			.mask("../../../img/btn/icon_visible.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
		}
	}

	&.invisible_small {
		&,
		&_inactive {
			.mask("../../../img/btn/icon_invisible.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
		}
	}
}

.icon {
	display: inline-block;
	width: @Size;
	height: @Size;
}

.picturePreview .item.horizontal .icon {
	margin: 0 5px 0 0;
}

.icon {
	&::before {
		display: inline-block;
		width: @Size;
		height: @Size;
		content: "";
		mask-repeat: no-repeat;
		cursor: pointer;
	}

	&,
	a& {
		&::before {
			background-clip: padding-box;
			background-color: var(--svg-icon-color-normal);
		}

		&:hover,
		&:focus {
			&::before {
				background-color: var(--svg-icon-color-hover);
			}
		}

		&.inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.show,
	&.show_small {

		&,
		&_inactive {
			.mask("@{_iconPath}/general/icon_show_small.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.download,
	&.download_small {

		&,
		&_inactive {
			.mask("@{_iconPath}/general/icon_download_small.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.stats {
		.mask("@{_iconPath}/general/icon_stats.svg");

		&_inactive {
			.mask("@{_iconPath}/general/icon_stats.svg");
			cursor: default;
		}
	}

	&.bounces {
		.mask("@{_iconPath}/general/icon_bounces.svg");

		&_inactive {
			.mask("@{_iconPath}/general/icon_bounces.svg");
			cursor: default;
		}
	}

	&.download_final {

		&,
		&_inactive {
			.mask("@{_iconPath}/general/icon_download_final_small.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.download_preview {

		&,
		&_inactive {
			.mask("@{_iconPath}/general/icon_download_preview_small.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.create_final {

		&,
		&_inactive {
			.mask("@{_iconPath}/general/icon_create_final_small.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.create_preview {

		&,
		&_inactive {
			.mask("@{_iconPath}/general/icon_create_preview_small.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.pricelist {

		&,
		&_inactive {
			width: 28px;
			height: 28px;
			margin: 4px 4px 0 0;
			.mask("../../../img/icon_euro.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.next {
		background-image: url("@{_iconPath}/general/icon_arrow_small_right.svg");

		&:hover {
			background-image: url("@{_iconPath}/general/icon_arrow_small_right_hover.svg");
		}
	}

	&.next_inactive {
		background-image: url("@{_iconPath}/general/icon_arrow_small_right_inactive.svg");
		cursor: default;
	}

	&.prev {
		background-image: url("@{_iconPath}/general/icon_arrow_small_left.svg");

		&:hover {
			background-image: url("@{_iconPath}/general/icon_arrow_small_left_hover.svg");
		}
	}

	&.prev_inactive {
		background-image: url("@{_iconPath}/general/icon_arrow_small_left_inactive.svg");
		cursor: default;
	}

	&.star {
		.mask("@{_iconPath}/general/icon_star.svg");
	}

	&.star_inactive::before {
		mask-image: url("@{_iconPath}/general/icon_star_inactive.svg");
		background-color: var(--svg-icon-color-inactive);
	}

	&.pdf_download {
		.mask("@{_iconPath}/general/icon_pdf.svg");
	}

	&.pdf_download_inactive::before {
		mask-image: url("@{_iconPath}/general/icon_pdf_inactive.svg");
		background-color: var(--svg-icon-color-inactive);
	}

	&.favorite {
		&,
		&_inactive {
			.mask("@{_iconPath}/general/icon_favorites_small.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.favorite_choosen {
		&,
		&_inactive {
			.mask("@{_iconPath}/general/icon_favorites_small_hover.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.edit_small,
	&.edit {
		&,
		&_inactive {
			.mask("@{_iconPath}/general/icon_edit_small.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.copy_small {

		&,
		&_inactive {
			.mask("@{_iconPath}/general/icon_copy_small.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.assignment_small {

		&,
		&_inactive {
			.mask("@{_iconPath}/general/icon_assignment_small.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.contactEdit_small {
		.mask("@{_iconPath}/general/icon_contactEdit_small.svg");
	}

	&.languages_small {
		.mask("@{_iconPath}/general/icon_languages_small.svg");
	}

	&.more {
		background-image: url("../../../../../default/img/icon_more.gif");
		height: 25px;
		line-height: 25px;
		padding: 10px 0 10px 30px;

		&:hover {
			background-image: url("../../../../../default/img/icon_more_mouseover.gif");
		}
	}

	&.administrate {
		background-image: url("../../../../../default/img/icon_administrate.gif");
		height: 25px;
		line-height: 25px;
		padding: 10px 0 10px 30px;

		&:hover {
			background-image: url("../../../../../default/img/icon_administrate_mouseover.gif");
		}
	}

	&.trash_bin_small {

		&,
		&_inactive {
			.mask("@{_iconPath}/general/icon_delete_small.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.shopping_cart_small {

		&,
		&_inactive {
			.mask("@{_iconPath}/general/icon_shopping_cart_small.svg");
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.info {
		.mask("@{_iconPath}/general/icon_star.svg");
	}

	&.moveTop.inactive,
	&.move_first_inactive {
		&::before {
			mask-image: url("@{_iconPath}/general/icon_arrow_small_top_inactive.svg");
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.moveBottom.inactive,
	&.move_last_inactive {
		&::before {
			mask-image: url("@{_iconPath}/general/icon_arrow_small_bottom_inactive.svg");
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.move_first,
	&.move_up,
	&.move_down,
	&.move_last {

		&,
		&_inactive {
			padding-right: 3px;
		}
	}

	&.moveUp,
	&.move_up,
	&.move_up_small {
		.mask("@{_iconPath}/general/icon_arrow_small_up.svg");
	}

	&.moveDown,
	&.move_down,
	&.move_down_small {
		.mask("@{_iconPath}/general/icon_arrow_small_down.svg");
	}

	&.moveDownAccordion {
		height: 20px;
		mask-image: url("@{_iconPath}/general/icon_down.svg");
	}

	&.moveUpAccordion {
		height: 20px;
		mask-image: url("@{_iconPath}/general/icon_up.svg");
	}

	&.moveLeft {
		.mask("@{_iconPath}/general/icon_arrow_small_left.svg");
	}

	&.moveRight {
		.mask("@{_iconPath}/general/icon_arrow_small_right.svg");
	}

	&.moveUp.inactive,
	&.move_up_inactive,
	&.move_up_small_inactive {

		&,
		&:hover {
			&::before {
				mask-image: url("@{_iconPath}/general/icon_arrow_small_up_inactive.svg");
				background-color: var(--svg-icon-color-inactive);
				cursor: default;
			}
		}
	}

	&.moveDown.inactive,
	&.move_down_inactive,
	&.move_down_small_inactive {

		&,
		&:hover {
			&::before {
				mask-image: url("@{_iconPath}/general/icon_arrow_small_down_inactive.svg");
				background-color: var(--svg-icon-color-inactive);
				cursor: default;
			}
		}
	}

	&.moveLeft,
	&.move_left_inactive {
		float: right;
	}

	&.moveLeft.inactive,
	&.move_left_inactive {
		&::before {
			mask-image: url("@{_iconPath}/general/icon_arrow_small_left_inactive.svg");
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.moveRight.inactive,
	&.move_right_inactive {
		&::before {
			mask-image: url("@{_iconPath}/general/icon_arrow_small_right_inactive.svg");
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

	&.moveUp_GroupSize {
		.mask("@{_iconPath}/general/icon_up.svg");
	}

	&.moveDown_GroupSize {
		.mask("@{_iconPath}/general/icon_down.svg");
	}

	&.sortUp.inactive,
	&.sortUpSelected {
		&::before {
			mask-image: url("../../../img/12x12/sort_up_inactive.svg");
			background-color: var(--svg-icon-color-inactive);
		}
	}

	&.sortDown.inactive,
	&.sortDownSelected {
		&::before {
			mask-image: url("../../../img/12x12/sort_down_inactive.svg");
			background-color: var(--svg-icon-color-inactive);
		}
	}

	&.mtpage {
		background-image: url("../../../img/mtpage.gif");

		&_error {
			background-image: url("../../../img/mtpage_error.gif");
		}

		&_complete {
			background-image: url("../../../img/mtpage_complete.gif");
		}

		&_current {
			background-image: url("../../../img/mtpage_current.gif");

			&_error {
				background-image: url("../../../img/mtpage_current_error.gif");
			}

			&_complete {
				background-image: url("../../../img/mtpage_current_complete.gif");
			}
		}
	}

	&.arrow_small_down {
		.mask("@{_iconPath}/general/icon_arrow_small_down.svg");
	}

	&.clockpicker-trigger {
		width: 19px;
		height: 19px;
		background-image: url("@{_iconPath}/general/clockpicker.png");

		&.inactive {
			background-image: url("@{_iconPath}/general/clockpicker_inactive.png");
		}
	}

	&.favIcon {
		width: 24px !important;
		height: 24px !important;
		.mask("@{_iconPath}/general/icon_favorites_small.svg");

		&.isFavorite {

			&,
			&.manually {

				&,
				&:hover {
					.mask("@{_iconPath}/general/icon_favorites_small_hover.svg");
				}
			}
		}
	}
}

div {
	&.boxGrid .icon {
		display: block;
		cursor: pointer;
		width: auto;
		height: auto;

		&.inactive {
			line-height: 25px;
			background-position: left top;
			cursor: default;

			&.text {
				padding-left: 28px !important;
			}
		}
	}

	&.copy_inactive::before {
		mask-image: url("@{_iconPath}/general/icon_copy_small_inactive.svg");
		background-color: var(--svg-icon-color-inactive);
		cursor: default;
	}

	&.paste_inactive.inactive::before {
		mask-image: url("@{_iconPath}/general/icon_clipboard_small_inactive.svg");
		background-color: var(--svg-icon-color-inactive);
	}

	&.icon {
		&.copy_inactive::before {
			mask-image: url("@{_iconPath}/general/icon_copy_small_inactive.svg");
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}

		&.rename_inactive::before {
			mask-image: url("@{_iconPath}/general/icon_edit_small_inactive.svg");
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}

		&.add_inactive,
		&.new_small_inactive {
			&::before {
				mask-image: url("@{_iconPath}/general/icon_new_small_inactive.svg");
				background-color: var(--svg-icon-color-inactive);
				cursor: default;
			}
		}

		&.cut {

			&.inactive,
			&_inactive {
				&::before {
					mask-image: url("@{_iconPath}/general/icon_scissor_small_inactive.svg");
					background-color: var(--svg-icon-color-inactive);
					cursor: default;
				}
			}
		}

		&.cut.inactive,
		&.cut_inactive {
			&::before {
				mask-image: url("@{_iconPath}/general/icon_scissor_small_inactive.svg");
				background-color: var(--svg-icon-color-inactive);
				cursor: default;
			}
		}

		&.delete {
			.mask("@{_iconPath}/general/icon_delete_small.svg");

			&.inactive,
			&_inactive,
			&.delete_inactive {
				&::before {
					mask-image: url("@{_iconPath}/general/icon_delete_small_inactive.svg");
					background-color: var(--svg-icon-color-inactive);
					cursor: default;
				}

				padding-left: 20px;
			}
		}

		&.edit {
			.mask("@{_iconPath}/general/icon_edit_small.svg");

			&._inactive,
			&.inactive {
				&::before {
					mask-image: url("@{_iconPath}/general/icon_edit_small_inactive.svg");
					background-color: var(--svg-icon-color-inactive);
					cursor: default;
				}
			}
		}

		&.take {
			.mask("@{_iconPath}/general/icon_download_small.svg");
		}
	}
}

a,
div {
	&.icon {
		background-position: 0 0;
		mask-position: 0 0;

		&.text {
			width: auto !important;
			line-height: 25px;
			display: flex;
			align-items: center;
		}

		&.delete_small_inactive::before {
			mask-image: url("@{_iconPath}/general/icon_delete_small_inactive.svg");
			background-color: var(--svg-icon-color-inactive);
		}
	}
}

.jsTreeControls {
	a.icon.moveUp,
	a.icon.moveDown,
	a.icon.moveLeft,
	a.icon.moveRight,
	div.icon.move_up_inactive,
	div.icon.move_down_inactive,
	div.icon.move_left_inactive,
	div.icon.move_right_inactive {
		height: 24px;
		width: 24px !important;
	}

	div.icon.move_right_inactive {
		background-position: left !important;
	}

	a.icon.moveUp,
	a.icon.moveDown,
	div.icon.move_up_inactive,
	div.icon.move_down_inactive {
		margin: 0 auto ! important;
		background-position: center !important;
	}

	a.icon.moveLeft,
	a.icon.moveRight,
	div.move_right_inactive,
	div.move_left_inactive {
		margin-top: 0 !important;
	}
}

.notepad {
	background-size: 100% 100%;
	background-position: 0 0;
	padding: 0;
	height: 154px;
	width: 350px;
	display: block;

	&.notepad-email {
		background-image: url("@{imgPath}/Notepad_EMail-Signatur_350x154.png");
	}
}

#favoriteMark ~ div.checkbox.favoriteMark {
	width: 24px;
	height: 24px;
	mask-image: url("@{_iconPath}/general/icon_favorites_small.svg");
	background-color: @ColorNormal;
	cursor: pointer;
	padding: 0;
	margin: 0;

	@media screen and (forced-colors: active) {
		background-color: @ForcedColorNormal;
	}

	&:hover {
		mask-image: url("@{_iconPath}/general/icon_favorites_small_hover.svg");
		background-color: @ColorHover;

		@media screen and (forced-colors: active) {
			background-color: @ForcedColorHover;
		}
	}

	&.checked {
		mask-image: url("@{_iconPath}/general/icon_favorites_small_hover.svg");

		&:hover {
			mask-image: url("@{_iconPath}/general/icon_favorites_small.svg");
		}
	}
}

.buttonOption.icon {
	line-height: 16px;

	&.take,
	&.edit,
	&.delete {

		&,
		&:hover {
			background-image: none;
			background-color: unset;
		}
	}

	.iconContainer {
		width: 16px;
		height: 16px;
		margin-right: 5px;
	}

	&.delete {
		.iconContainer {
			.mask("@{_iconPath}/general/icon_delete_small.svg");
		}

		&:hover .iconContainer {
			.mask("@{_iconPath}/general/icon_delete_small_hover.svg");

			&::before {
				background-color: var(--svg-icon-color-hover);
			}
		}
	}

	&.edit {
		.iconContainer {
			.mask("@{_iconPath}/general/icon_edit_small.svg");
		}

		&:hover .iconContainer {
			.mask("@{_iconPath}/general/icon_edit_small_hover.svg");

			&::before {
				background-color: var(--svg-icon-color-hover);
			}
		}
	}

	&.take {
		.iconContainer {
			.mask("@{_iconPath}/general/icon_download_small.svg");
		}

		&:hover .iconContainer {
			.mask("@{_iconPath}/general/icon_download_small_hover.svg");

			&::before {
				background-color: var(--svg-icon-color-hover);
			}
		}
	}
}

#errorpage-triangle {
	height: 58px;
	width: 58px;
	mask: url("@{_iconPath}/general/Exclamation Triangle O.svg") no-repeat;
	background-color: currentColor;

	@media screen and (forced-colors: active) {
		background-color: CanvasText;
	}
}