@import '../utilities/lib.less';
#wofWrapper {
	z-index: @Index_Header + 1;
	.transition (top @DefaultAnimation);
	overflow: hidden;
	.wofSectionGroup {
		width: 31px;
		height: 29px;
		float: right;
		clear: right;
		background: @LightGrey_2021;
		border: 2px solid @White;
		backface-visibility: hidden;
		border-radius: @DefaultBorderRadius;

		+ .wofSectionGroup.wofSeparator {
			margin-top: 6px;
			padding-top: 3px;
		}
		&:last-child {
			border-bottom-width: 2px;
		}

		&.wofSeparator {
			height: 35px;
			color: @DarkGrey_2021;
			.wofElement:after {
				background: @LightGrey_2021;
			}
		}

		&:not(.wofSeparator) {
			&.containsErrors {
						background: @HighlightButtonNormalColor;
						color: @White;
						.wofElement:after {
							background: @HighlightButtonNormalColor url('../../img/icons/panels/panel_close_white_2021.svg') no-repeat 0 2px;
							transform: rotate(0deg)
				}
				&.inviewport {
					background:  @HighlightButtonNormalColor;
					color: @White;
					.wofElement:after {
						background: @HighlightButtonNormalColor url('../../img/icons/panels/panel_close_white_2021.svg') no-repeat 0 2px;
						transform: rotate(0deg);
					}
				}
				&:hover {
					background: @HighlightButtonHoverColor;
					color: @White;
					.wofElement:after {
						background: @HighlightButtonHoverColor url('../../img/icons/panels/panel_close_white_2021.svg') no-repeat 0 2px;
						transform: rotate(0deg);
					}
				}
	
				&:active {
					background: @HighlightButtonPressedColor;
					color: @White;
					.wofElement:after {
						background: @HighlightButtonPressedColor url('../../img/icons/panels/panel_close_white_2021.svg') no-repeat 0 2px;
						transform: rotate(0deg);
					}
				}
			}

			&.inviewport {
				background: @LightBlue_2021;
				color: @White;
				.wofElement:after {
					background: @LightBlue_2021 url('../../img/icons/panels/panel_close_white_2021.svg') no-repeat 0 2px; // Relative to mixin
					transform: rotate(0deg);
				}
			}

			&:hover {
				background: @ButtonHoverColor;
				color: @White;
				.wofElement:after {
					background: @ButtonHoverColor url('../../img/icons/panels/panel_close_white_2021.svg') no-repeat 0 2px;
					transform: rotate(0deg);
				}
			}

			&:active {
				background: @ButtonPressedColor;
				color: @White;
				.wofElement:after {
					background: @ButtonPressedColor url('../../img/icons/panels/panel_close_white_2021.svg') no-repeat 0 2px;
					transform: rotate(0deg);
				}
			}
		}

		.wofElement {
			line-height: 26px;
			padding-right: 0;
			padding-left: 27.5px;
			&:before {
				content: none;
			}

			&:after {
				content: "";
				.bas-gradient-with-image-l2r (@LightGrey_2021 , @LightGrey_2021 , '../../../customer/allianz/img/icons/panels/panel_close_2021.svg' , no-repeat , 0 , 2px);
				transform: rotate(270deg);
				width: 20px;
				height: 24px;
				position: absolute;
				right: 4px;
				top: 1px;
			}
		}
	}

	&:hover .wofSectionGroup .wofElement {
		padding-right: 30px;
	}
}

div.wofArrow {
	z-index: @Index_Header + 1;
	height: 10px;
	width: 28px;
	background-image: url('../../img/icons/panels/panel_close.png');
	background-position: 5px -4px;
	.rotate (180deg);
	&.wofArrowTop {
		background-position: 2px -5px;
		.rotate (0deg);
	}
}

