@import '../utilities/lib.less';
input[type="text"] , input[type="password"] , textarea , .inlineckeditor {
	padding: 0 5px;
	&.alignRight {
		text-align: right;
	}

	.placeholder (#a9b1c6);
}

.inlineckeditor {
	min-height: 20px;
}

input {
	&.checkbox , &.radio , &[type="checkbox"] , &[type="radio"] {
		vertical-align: top;
		height: auto;
		border: 0;
		outline: none;
		margin-right: 4px;
		& , + label {
			cursor: pointer;
		}
	}

	&[type=file] {
		max-width: 350px;
		cursor: pointer;
		background: #fff;
		margin-bottom: 3px;
		&:disabled {
			cursor: default;
		}
	}

	&[size] {
		width: auto;
	}

	&[type="radio"] , &[type="checkbox"] {
		&[disabled] {
			background-color: transparent;
			& , + label {
				cursor: default;
			}
		}
	}
}

html.lt-ie9 {
	input.checkbox , input.radio , input[type="checkbox"] , input[type="radio"] {
		margin-right: 0;
		border-right: 5px solid transparent;
	}
}

input , select , textarea:not(.mat-input-element) , .inlineckeditor {
	&:focus {
		outline: none;
	}

	&[disabled] , &[disabled]:hover , &[disabled]:focus , &[readonly=readonly] {
		background: @InputInactiveColor;
		cursor: default;
		color: @InputFontInactiveColor;
	}
}

div.bas-inputCheck {
	display: table ! important;
	> *:not(.tooltip) {
		vertical-align: middle;
		display: table-cell;
	}

	&.containsSVG {
		&:hover svg {
			cursor: pointer;
		}

		div.checkbox , div.radio {
			display: none;
		}

		input {
			&[type=radio] , &[type=checkbox] {
				display: none;
			}
		}

		svg {
			float: left;
			margin-right: 5px;
		}
	}
}

.bas-lockCheckbox {
	float: right;
}

div.bas-inputCheck.bas-lockCheckbox {
	overflow: hidden;
	width: 20px;
	height: 20px;
	display: inline-block ! important;
}

.groupSeparator div.bas-inputCheck.bas-lockCheckbox {
	text-align: right;
	padding-right: 11px;
	width: 100%;
	height: 14px;
	margin-top: 0;
	box-sizing: border-box;
}

.ym-grid.layoutGrid {
	&.contentWidth {
		width: auto;
	}

	&.fullWidth {
		input[type=text] , textarea {
			width: 100%;
		}
	}
}

option.disable {
	&:before {
		text-decoration: line-through;
	}
}

.grid-table {
	display: table;
	.grid-table-row {
		display: table-row;
		.grid-table-cell {
			display: table-cell;
		}
	}
}

section.bas-formSection.flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

input.selectionInput {
	+ label.optionalcheckbox {
		margin: 0 0 0 0;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		cursor: pointer;
	}

	&:disabled + label.optionalcheckbox {
		cursor: default;
	}

	+ label {
		word-wrap: break-word;
	}
}

span.groupButtons {
	float: right;
	> .icon {
		margin-top: -4px;
		margin-right: -5px;
	}

	div.bas-inputCheck {
		display: inline-block ! important;
		> * {
			display: inline-block;
		}

		margin: 0 0 0 0;
		position: relative;
	}

	.bas-inputCheck + .bas-inputCheck {
		margin-left: 2px;
	}
}

#searchMask div.cols2 .tabulationContainer input.cols2 {
	width: 100%;
}

.selectionGroup {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	input {
		visibility: hidden;
		position: absolute;
	}

	label {
		display: inline-block;
		cursor: pointer;
	}

	.selectionArea {
		&.dummy1 {
			background-image: url("../../img/uilab/dummy1.png");
		}

		&.dummy2 {
			background-image: url('../../img/uilab/dummy2.png');
		}

		&.dummy3 {
			background-image: url('../../img/uilab/dummy3.png');
		}
	}
}

.selectionGroup {
	> .selection , > .tooltipTrigger {
		margin: 0 10px 10px 0;
	}
	
	.selection input:checked {
		& , &:hover {
			~ label.selectionArea:after {
				.bottomRight ();
			}
		}
	}
}

