@import '../utilities/lib';
.jquery_tabs {
	ul.tabs-list {
		border-bottom: 1px solid @MediumGrey7;
		width: 100%;
		line-height: 1em;
		bottom: -1px;
		li , li.current {
			a.error , a.error:focus , a.error:hover , a.error:active {
				background-color: @DarkOrange
			}
		}

		li {
			background: url("../../img/flap-bg.jpg") repeat-x scroll left 5px #ededed;
			margin: 0 0.3em 0 0;
			line-height: 1em;
			padding: 7px 0;
			border: 1px @MediumGrey7 solid;
			border-radius: 0;
			border-bottom: 0 none;
			&.current {
				background: @White;
				border-bottom: none;

				a, a:focus, a:hover, a:active {
					background: url("../../img/arrow_right_blue.png") no-repeat scroll 6px 53% transparent;
					border: none;
					border-bottom: 1px solid @White;
					color: @Blue;
					font-weight: normal;
					text-decoration: none;
					cursor: default;
				}
			}

			a , a:focus , a:hover , a:active {
				background: url("../../img/arrow_right_grey.png") no-repeat scroll 6px 53% transparent;
				border: none;
				color: #4A4B4C;
				padding: 6px 11px 6px 16px;
				display: inline;
				float: none;
				line-height: 1em;
				text-decoration: none;
				border-radius: 0;
			}
		}
	}

	.tabContent {
		margin-top: 10px;
		border-top: 0 none;
	}
}

* + html .jquery_tabs ul.tabs-list li {
	padding: 0;
	&.current {
		a , a:focus , a:hover , a:active {
			border: none;
			bottom: -1px;
		}
	}
}

* html .jquery_tabs .tabContent {
	z-index: -1;
}

