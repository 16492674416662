/**
 * "Yet Another Multicolumn Layout" - YAML CSS Framework
 *
 * (en) Horizontal list navigation "hlist"
 * (de) Horizontale Navigationsliste "hlist"
 *
 * @copyright       © 2005-2013, Dirk Jesse
 * @license         CC-BY 2.0 (http://creativecommons.org/licenses/by/2.0/),
 *                  YAML-CDL (http://www.yaml.de/license.html)
 * @link            http://www.yaml.de
 * @package         yaml
 * @version         4.1.2
 */
@media all {
  .ym-hlist {
    /* (en) containing floats in IE */
    /* (de) Einfassen der Floats im IE */
    width: 100%;
    overflow: hidden;
    position: relative;
    line-height: 1em;
    background: #222;
  }
  .ym-hlist ul {
    margin: 0;
    padding: 0.5em 1.5em;
    display: inline;
    float: left;
  }
  .ym-hlist ul li {
    display: inline;
    float: left;
    font-size: 1.0em;
    line-height: 1;
    list-style-type: none;
    margin: 0 .25em 0 0;
    padding: 0;
  }
  .ym-hlist ul li a, .ym-hlist ul li strong {
    background: transparent;
    color: #aaa;
    display: block;
    font-size: 1em;
    line-height: 2em;
    padding: 0 0.5em;
    font-weight: normal;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    width: auto;
  }
  .ym-hlist ul li a:focus,
  .ym-hlist ul li a:hover,
  .ym-hlist ul li a:active {
    color: #ccc;
    background: #666;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 0.2em;
    text-decoration: none;
    outline: 0 none;
  }
  .ym-hlist ul li.active {
    background: #666;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 0.2em;
    color: #fff;
  }
  .ym-hlist ul li.active strong,
  .ym-hlist ul li.active a:focus,
  .ym-hlist ul li.active a:hover,
  .ym-hlist ul li.active a:active {
    background: transparent;
    color: #fff;
    text-decoration: none;
  }

  /* ------- search form -------- */
  .ym-searchform {
    float: right;
    display: inline;
    line-height: 2;
    padding: 0.5em 1.5em;
  }
  .ym-searchform .ym-searchfield {
    -webkit-appearance: none;
    background: #fff;
    line-height: 1;
    padding: 0.25em;
    width: 12em;
    border: solid 1px rgba(255, 255, 255, 0.1);
    outline: none;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  }
  .ym-searchform .ym-searchbutton {
    -webkit-appearance: none;
    background: #5f5f5f;
    background: rgba(255, 255, 255, 0.2);
    border-radius: .2em;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    line-height: 1.25;
    padding: 0.25em;
    border: solid 1px transparent;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  }
}
