@import '../../utilities/lib.less';
.floatingPageHeader {
	position: initial;
	top: auto;
	left: auto;
	.marketing-planer-kalender {
		padding-top: 10px;
	}
	
	label {
		padding-right: 10px;
	}

	.ym-gl {
		padding-right: 10px;
		box-sizing: border-box;
		border-right-width: 1px;
		border-right-style: solid;
	}

	.ym-gr {
		padding-left: 10px;
		box-sizing: border-box;
	}

	.bas-inputSelect select{
		width: 100%;
	}

	&:not(.active){
		.teilnehmerSelect {
			margin-bottom: 25px;
		}
	}
	
	.budgetsum.horizontal.last-right .item .itemBox:last-child {
		padding-right: 0;
	}

	&:not(.active) .gruppenbudget{
		background: #dadada;
		font-weight: bold;
	}
	.sumsymbol{
		padding-right: 10px;
	}

	&.active .sumsymbol{
		display: none;
	}

	.compact {
		display: none;
	}
	
	.compact {
		.sum {
			position: relative;
			top: -1px;
		}
	}
}

