@import "../utilities/lib.less";

div.dialog {
	display: none;

	.dialogWrapper {
		background-color: @LightGrey_2021;
		@media screen and (forced-colors: active) {
			border: 1px solid CanvasText;
		}

		&.ie8 {
			.dialogHeader div {
				&.closeDialog, &.destroyDialog {
					right: 5px;
					top: 2px;
				}
			}
		}

		&.previewSwitch {
			width: 1150px;
			height: 95%;
			background: #ffffff;
			overflow: hidden;

			&.single {
				.dialogContent {
					position: absolute;
					bottom: 30px;
					top: 40px;
					left: 30px;
					right: 30px;
				}
			}

			.dialogHeader {
				div.closeDialog, div.destroyDialog {
					right: 5px;
					top: 1px;
				}
			}

			.dialogFooter {
				background: #ffffff;
			}
		}

		.dialogHeader {
			h3 {
				padding: 15px 15px 8px 15px;
				line-height: 20px;
			}

			div.closeDialog, div.destroyDialog {
				position: absolute;
				z-index: @Index_DialogClose;
				right: 0px;
				top: 0px;

				&.icon::before {
					display: none;
				}

				> .iconContainer {
					border-radius: 2px;
					border: 1px;
					background-color: @LightGrey_2021;

					&, &::before {
						width: 20px;
						height: 20px;
					}

					&::before {
						display: inline-block;
						content: "";
						background-color: var(--svg-icon-color-normal);
						mask: url("../../img/icons/general/close.svg") 0 0 no-repeat;
					}
				}

				&:hover > .iconContainer::before {
					background-color: var(--svg-icon-color-hover);
				}
			}
		}

		.tabs-list {
			> li {
				border: none;
				color: #ffffff;
				border-radius: 0;
				font-size: @FontSize;
				height: 30px;
				line-height: 30px;
				margin: 0 1px 0 0;
				display: inline-block;
				padding: 5px 10px;
				cursor: pointer;

				&.current {
					background: #ffffff;
					cursor: default;

					&:hover {
						background: #ffffff;
					}
				}
			}
		}

		.tabbody {
			margin-top: -1px;
			padding-top: 10px;

			.iframePreviewContainer {
				position: relative;
				overflow: auto;
				height: 100%;

				> div {
					position: relative;
					display: block;

					> iframe, > div.image {
						border: 0;
						position: absolute;
					}
				}

				iframe {
					background: #ffffff;
					border: 1px solid @VisualLayoutingColor;
					box-sizing: border-box;

				}

				&.tablet {
					div.image {
						display: none;
					}

					iframe {
						width: 1024px;
						height: 500px;
						top: 0;
					}

					object {
						width: 1024px;
					}
				}

				&.mobile {
					div.image {
						background-repeat: no-repeat;
					}

					iframe {
						top: 0;
						.transformScale (1.0);
					}

					object {
						border: 1px solid @VisualLayoutingColor;
					}
				}
			}
		}

		.dialogContainer {
			background: transparent;
			padding: 15px 15px 5px 15px;
			border: none;
			float: none;

			.dialogContent {
				margin-top: 0;

				.bas-formSection {
					padding-left: 0;
					padding-right: 0;
				}

				.field {
					&.noLabel {
						display: block;
						padding-left: 0;
					}

					&.buttonline {
						padding: 15px 0 0 0;

						.button {
							margin-bottom: 0;
							width: auto;
						}
					}
				}

				.browserSupportScreenContent {
					> .container {
						&.left {
							display: none;
						}

						&.right {
							margin-top: 0;
							float: none;
							width: auto;
							padding-right: 0;

							.downloadList {
								list-style: none;
							}
						}
					}
				}
			}
		}

		.dialogFooter {
			text-align: right;
			padding: 10px;
			padding-bottom: 15px;
			display: block;
			background: transparent;

			.dialogButton {
				position: relative;
				display: inline-block;
				color: @White;
				height: 26px;
				min-height: 26px;
				width: auto;
				min-width: 135px;
				line-height: 26px;
				padding: 0 10px 0 10px;
				text-decoration: none;
				text-align: left;
				.bas-gradient (@LightBlue, @Blue);
				margin-right: 5px;
				cursor: pointer;

				&:last-child {
					margin-right: 0;
				}

				&:hover {
					.bas-gradient (@Blue, @LightBlue);
				}
			}
		}
	}
}

