@import "../../utilities/lib.less";

.chosen-container-single, .chosen-container-multi {
	.chosen-single, .chosen-choices {
		font-size: @FontSize;
		border: 1px solid @MediumGrey6;
		border-radius: @DefaultBorderRadius;
		background: @White;
		box-shadow: none;
		color: @Black;
		padding-left: 5px;
		padding-right: 15px;

		> span {
			line-height: 19px;
			font-size: @FontSize;
			font-family: @DefaultFont;
			margin-right: 0;
		}

		div b {
			background-position: 4px 4px;
		}

		li.search-field input[type="text"] {
			padding-left: 3px;
			height: 17px;
		}

		li.search-choice {
			span {
				line-height: 15px;
				margin-right: 1px;
				vertical-align: top;
			}

			height: 17px;
			line-height: 17px;
			padding: 0 20px 0 5px;
			margin: 1px 1px 0 0;
			color: @Black;
			background: @White;
			border-radius: 0;

			&:hover {
				color: @DarkBlue;

				.search-choice-close {
					background-position: 0 -22px;
				}
			}

			.search-choice-close {
				width: 14px;
				height: 15px;
				top: 0;
				right: 0;
				background: url("chosen-sprite-all.png") -38px 0px no-repeat;
			}
		}
	}

	&.chosen-container-active.chosen-with-drop {
		div b {
			background-position: -14px 4px;
		}
	}

	.chosen-drop {
		border-radius: 0;
		box-shadow: none;
		border: 1px solid @MediumGrey6;
	}

	&.chosen-disabled {
		.chosen-single {
			color: graytext;
			background: @LightGrey5;
			border-color: @MediumGrey6;
		}

		opacity: 1 !important;
	}
}

.chosen-container-single {
	.chosen-single {
		height: @DefaultHeight;

		span {
			font-family: @DefaultFont;
			line-height: 27px;
		}
	}
}

.chosen-container-multi {
	.chosen-choices {
		padding: 0 1px;
	}

	.chosen-drop {
		border-top: none;
	}
}

.chosen-container-active {
	.chosen-single > span {
		color: @DarkBlue;
	}

	&.chosen-with-drop {
		.chosen-single {
			background-image: none;
			border: 1px solid @MediumGrey6;
			border-bottom: none;

			.result-selected {
				color: @DarkBlue;
			}
		}

		.chosen-drop {
			input {
				border: 1px solid @MediumGrey6;
			}
		}

		.chosen-results {
			margin: 0 0 0 0;
			color: @Black;

			li.no-results {
				color: @Black;
				display: list-item;
				background: @White;
			}
		}
	}
}

.chosen-container .chosen-results {
	margin: 0 4px 2px 0;
	padding: 0 0 0 5px;

	li {
		padding: 1px 0;
		white-space: nowrap;
		font-size: @FontSize;
		font-family: @DefaultFont;
		line-height: normal;

		&.highlighted {
			background: @White;
			color: @DarkBlue;
		}

		&.disabled-result {
			color: graytext;
		}

		mark {
			color: inherit;
			background-color: inherit;
			font-weight: bold;
		}
	}
}

select:not([multiple]):not([size]) {
	padding: 1px;
}

.chosen-container {
	position: relative;
	max-width: 100%;
	font-size: 100%;
	text-align: left;
	border-radius: @DefaultBorderRadius;

	.chosen-drop {
		z-index: @Index_Header - 1;
	}
}