
.budget-overview{
	.radio-button-group{
		margin-top: 20px;
	}
	.groupSeparator.flex{
		display: flex;
		.headlinewrapper {
		    min-width: 400px;
		    display: flex;
		    justify-content: center;
		}
		.headline{
			display: flex;
			align-items: center;
			padding: 0 10px;
		}
	}
	.werbeformbudgets, .kategorienbudgets, .kategoriewerbeformbudgets {
		display: grid;
		display: -ms-grid;
		grid-template-columns: 20% 75%;
		-ms-grid-columns: 20% 75%;
		grid-template-rows: auto auto;
		-ms-grid-rows: auto auto;
		.interval-container{
			grid-column: 2;
			-ms-grid-column: 2;
			grid-row: 1;	
			-ms-grid-row: 1;
			position: relative;
			height: 20px;
			overflow: visible;
			.interval{
				white-space: nowrap;
				position: absolute;
				transform: translateX(-50%);
			}
		}
		.description-container{
			grid-column:1;
			-ms-grid-column: 1;
			grid-row:2;
			-ms-grid-row: 2;
			.description-wrapper{
				margin: 5px;
				height: 15px;
				cursor: default;
				&.sub {
					margin-left: 30px;
				}
				&.main {
					font-weight: bold;
					&:not(:first-child){
						margin-top: 10px;
					}
					margin-bottom: 10px;
				}
			}
		}
		.diagram-container{
			grid-column:2;
			-ms-grid-column: 2;
			grid-row:2;
			-ms-grid-row: 2;
			.diagram-wrapper{
				margin: 5px 0;
				width: 0;
				height: 15px;
				transition: width 1.25s ease 0.25s;
				.diagram{
					width: 100%;
					height: 100%;
					&.tooltipTrigger{
						display: block;
					}
				}
				&.main {
					&:not(:first-child){
						margin-top: 10px;
					}
					margin-bottom: 10px;
				}
			}
		}
		.message-container{
			grid-column-start:1;
			grid-column-end:3;
			-ms-grid-column-span: 2;
			overflow: visible;
		}
	}
}

.jahresauswahl .item .itemBox {
	float: none;
	height: 100%;
	vertical-align: middle;
	line-height: 11px;
	margin-right: 15px;
}

table.jahresbudget {
	max-width: 750px;
	.kategorietext {
		padding-left: 25px;
		font-weight: normal;
	}
	.summentext {
		padding-left: 25px;
	}
	.fieldsavedicon{
		position: absolute;
		display: block;
		padding-left: 5px;
		padding-right: 4px;
		border-top: 0;
		border-left: 0;
		min-width: 34px;
		min-height: 20px;
		text-align: left;
		div {
			background-image: url("../../../img/icons/marketingplaner/icon_autosaved.png");
			width: 24px;
			min-width: 24px;
			height: 15px;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
	}
}
