.reportList {
	margin-bottom: 0;
	padding-top: 0;
	.defaultLink {
		margin-bottom: 0;
	}

	.reportItem .mainRow {
		> div {
			&.reportName {
				margin-top: 0;
				line-height: 26px;
				> a {
					background-position: 0 10px;
				}
			}

			&.favIcon {
				height: 16px;
				margin-top: 0;
			}

			&.expandIcon {
				padding-top: 5px;
				i.icon.defaultLink {
					background-position: 0;
				}
			}
		}
	}
}

