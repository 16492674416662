@import '../../utilities/lib.less';
.tooltip {
	border: 1px solid #B0B8BD;
	background-color: @TooltipBackgroundColor;
	padding: 7px;
	box-shadow: @MainNavigationShadow;
	word-break: break-word;
	.item {
		padding: 10px 7px 12px 7px;
		background: @TooltipBackgroundColor;
		font-color: @DarkGrey2;
	}
	
	ul.bas-unorderedList {
		padding-left: 20px;
	}

	li{
		margin-left: 1.2em;
	}

}

.infotype , .infotype_bold {
	color: @Black;
}

div.tooltipTrigger {
	display: inline-block;
	margin: 0 3px;
	vertical-align: middle;
	cursor: pointer;

	&.infoIcon {
		&, &::before {
			width: 24px;
			height: 24px;
			display: inline-block;
		}

		&::before {
			content: '';
			background-color: var(--svg-icon-color-normal);
			mask: url('../../../img/infoboxicon.svg') 0 0 no-repeat;
		}

		&:hover::before {
			background-color: var(--svg-icon-color-hover);
		}

		&:focus-visible {
			outline: @DefaultOutlineWidth @DefaultOutlineStyle @DefaultOutlineColor;
		}

		&.selectSeite {
			position: absolute;
			bottom: 0;
			right: 2px;
			
			&, &::before {
				width: 28px;
				height: 28px;
			}
			
			&::before {
				mask: url('../../../img/icon_arrow_tooltip.svg') 0 0 no-repeat;
			}
		}
	}
}

.bas-inputCheck div.tooltipTrigger {
> *:not(.hint) {
		display: table-cell;
	}
	vertical-align: top;
	&.infoIcon {
		max-width: none;
		margin-top: 3px;
	}

}

.tooltipTrigger.elementTrigger {
	margin: 0;
}

