.monthpicker{
	margin: 0 20px 0 20px;
}
.monthpicker, .dayspicker{
	display: inline-block;
	.monthpicker-title , .dayspicker-title{
		text-align: center;
	}
	.monthpicker-calendar, .dayspicker-calendar{
		display:inline-block;
		border-style: solid;
		border-width: 1px;
		padding: 5px;	
		.monthpicker-header, .dayspicker-header{
			display: flex;
			justify-content: space-between;
			font-weight: bold;
			margin-bottom: 10px;
			.year-cell{
				user-select: none;
			}
		}
		.monthpicker-body, .dayspicker-body{
			display: grid;
			display: -ms-grid;
			grid-template-rows: auto auto auto auto;
			-ms-grid-rows: auto auto auto auto;
			.month-cell, .day-cell{
				display:block;
				padding: 0 10px;
				line-height: 30px;
				text-align: center;
				cursor: pointer;
				&.disabled{
					cursor: default;
				}
				&.last-column{
					margin-right: 0;
				}
			}
		}
		.monthpicker-body{
			grid-template-columns: 55px 55px 55px;
			-ms-grid-columns: 55px 55px 55px;
		}
		.dayspicker-body{
			grid-template-columns: 40px 40px 40px 40px 40px 40px 40px;
			-ms-grid-columns: 40px 40px 40px 40px 40px 40px 40px;
		}
	}
}