@import "../utilities/lib.less";

.hasErrors, .hasWarnings {
	position: relative;

	&:not(.panel):not([class*="cols"]):has(> [class*="cols"]),
	&:not(#editMaskContainer *),
	&:has(:is(input, textarea)[style*="width"]) {
		width: fit-content;
	}
}
