.ym-vlist {
	border: 0 none;
	li a , li strong , li span {
		width: auto;
	}

	a , strong , span {
		border: 0 none;
	}

	li ul li {
		a {
			&:focus , &:hover , &:active {
				color: inherit;
				background-color: inherit;
			}
		}

		ul li {
			a {
				&:focus , &:hover , &:active {
					color: inherit;
					background-color: inherit;
				}
			}

			ul li {
				a {
					&:focus , &:hover , &:active {
						color: inherit;
						background-color: inherit;
					}
				}
				a, strong, span {
					width: auto;
				}
			}
			a, strong, span {
				width: auto;
			}
		}
		a, strong, span {
			width: auto;
		}
		
	}
}


