/**
 **********************************
 * common datepicker-custom for all themes
 **********************************
 */

.datepicker-wrapper {
	display: flex;
	[hidden] + img{
		display: none;
	}
}

.ui-datepicker {
	padding			:	0.2em;
} 
.ui-datepicker table {
	margin			:	0;
}
 /*versteckt schliessen-button*/
.ui-datepicker-close {
	display			:	none;
}
/*ausrichtung drop-down jahreszahl*/
.ui-datepicker .ui-datepicker-title select {
	margin-top		:	0;
}
/*feste breite für drop-down jahreszahl*/
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year, 
.ui-datepicker-year {
	width			:	55px;	
}
/*workaround wegen falschem hover-verhalten im ie (icon springt)*/
.ui-datepicker .ui-state-hover, 
.ui-datepicker .ui-widget-content .ui-state-hover, 
.ui-datepicker .ui-widget-header .ui-state-hover, 
.ui-datepicker .ui-state-focus, 
.ui-datepicker .ui-widget-content .ui-state-focus, 
.ui-datepicker .ui-widget-header  .ui-state-focus {
 	border			:	none;
}
.ui-datepicker .ui-datepicker-prev, 
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
	top				: 	15%;
}
.ui-datepicker .ui-datepicker-prev, 
.ui-datepicker .ui-datepicker-prev-hover {
	left			: 	2px;
}
.ui-datepicker .ui-datepicker-next, 
.ui-datepicker .ui-datepicker-next-hover {
	right			:	2px;
}
input.datepicker, input.clockpicker {
	float			:	left; /* HACK: berichtigung der vertikalen ausrichtung für inputs mit angebundenem datepicker */
	margin-right	:	3px;
}
.ui-datepicker-calendar{
	text-align		:	center;	
}
.ui-datepicker th {
	padding			:	4px;
}
/* button panel*/
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
	border 			:	0;
	font-weight		:	bold;
	float			:	none;
	text-decoration :	none;
	padding-bottom	: 	0;
	opacity			: 	1.0;
	filter			:	Alpha(Opacity=100);
}
/* header paging-buttons mouse-pointer */
.ui-datepicker-next-hover, 
.ui-datepicker-prev-hover{
	cursor			:	pointer;
}
.ui-datepicker .ui-datepicker-buttonpane,
.ui-datepicker .ui-datepicker-buttonpane button {
	font-weight		:	bold;
	text-align		:	center;
    text-decoration	:	none;
	float			:	none;
	margin			:	0;
    padding			:	1px;
}
.ui-datepicker-calendar .ui-state-default {
	text-align		:	center;
}
/* header paging-buttons without background */
.ui-datepicker .ui-widget-header .ui-state-hover, 
.ui-datepicker .ui-widget-header .ui-state-focus  {
	background		: 	none;
}
/* IE HACK */
.ui-datepicker-title * {
	vertical-align	:	middle;
}
/* IE HACK */
.ui-datepicker-title span {
	font-size		:	10.6px;
}
/* IE HACK for preventing the datepicker-header to shring randomly in IE 7-9 without set Doctype */
* html .ui-datepicker-header {
	width			:	100%;
}