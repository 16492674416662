@import "../utilities/lib.less";

body {
	text-align: left;
	height: 100%;

	&.readonly {
		color: @InactiveFontColor;
	}
}

#page {
	min-height: 100%;
	.bas-gradient-l2r(@LightGrey1, @MediumGrey3);

	&.login #main {
		background: @White;
		padding: 7px;
	}
}

div.placeholderpicture {
	border: 1px solid @MediumGrey4;
}

div.picturePreview div.placeholderpicture {
	margin: -1px -1px 7px 0;
}

div {
	&.cmsplaceholderpicture, &.placeholderpicture {
		position: relative;
		background-color: @LightGrey2;

		span.infotext {
			.translate(-50%, -50%);
			display: inline-block;
			top: 50%;
			left: 50%;
			position: absolute;
		}
	}

	&.cmsplaceholderpicture {
		padding: 0;
	}
}

.picturePreview img.overview {
	margin-bottom: 7px;
}

.ym-wrapper {
	width: @PageWidth;
	margin: 0 auto;
	padding-bottom: 20px;
	text-align: left;
}

.ym-col1 {
	float: right;
	width: 162px;
	margin-left: 2px;
}

.ym-col3 {
	margin-left: 0;
	width: 818px;
	margin-right: 164px;

	.ym-cbox {
		margin-bottom: 2px;
	}
}

.ym-cbox {
	background-color: @White;
	padding: 7px;
	float: left;
	width: 818px;
	box-sizing: border-box;
}

.input-prompt {
	margin: 4px 0 0 4px;
}

.admin {
	background-color: @LightGrey_2021;
}

table {
	table-layout: fixed;
}

.jcrop-tracker, .jcrop-holder {
	background-color: transparent ! important;
	background-image: url("../../../../default/img/transparent.png") ! important;
	filter: none ! important;
}

.cropBox[data-used="false"] {
	.jcrop-holder > div {
		display: none !important;
	}
}

.jcrop-holder {
	div div .jcrop-tracker {
		background-image: url("../../../../default/img/transparent.png") ! important;
	}

	img {
		background-color: transparent ! important;
		filter: none ! important;
	}
}

.mainContent {
	padding-top: 22px;
}

div.locationmap {
	position: relative;
	height: 550px;
}

#apiMap img {
	max-width: none;
}

.border_white_right {
	border-right: solid 2px @White;
}
