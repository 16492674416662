@import '../../utilities/lib';
#searchfield {
	width: 275px;
	margin-right: 2px;
	float: left;
	border: 1px solid #A7A7A7;
	display: inline;
}

#searchfieldTrigger {
	background: url("../../../img/icons.png") no-repeat scroll 0 -564px @White;
	height: 16px;
	width: 16px;
	position: absolute;
	top: 3px;
	right: 170px;
	cursor: pointer;
	&:hover {
		background-position: 0 -580px;
	}
}

.ui-autocomplete {
	max-height: 230px;
}

.headerSubContainer .input-prompt {
	position: absolute;
	left: 0;
	top: 0;
}