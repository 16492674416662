@import '../utilities/lib';

@iconPath: '../../img/icons';

.lightboxOverlay {
	display: none;
}

.lightboxContainer {
	background-color: @MediumGrey1;
	height: 80% !important;
	width: 853px !important;
	overflow: hidden;
	text-align: center;

	@media screen and (forced-colors: active) {
		border: 1px solid CanvasText;

		.header {
			border-bottom: 1px solid CanvasText;
		}

		.actions {
			border-top: 1px solid CanvasText;
		}
	}

	.header {
		background-color: @LightGrey6;
		padding: 3px;
		margin-bottom: 3px;
		height: 30px;
	}

	.imageContainer {
		padding: 0 32px;

		>img {
			position: static;
			border: 1px solid @MediumGrey1;
			box-shadow: 0 0 5px 0 @MediumGrey1;
		}
	}

	.actions {
		background-color: @LightGrey6;
		padding: 3px;
		height: 30px;
		border-radius: 3px;
	}

	.linkBox a.icon.edit_small {
		display: flex;
	}
}

.actions .switchLeft,
.actions .switchRight,
.closeLightbox,
.scalePreview {
	position: relative;

	&,
	&::before {
		height: 24px;
		width: 24px;
	}

	&::before {
		content: '';
		display: inline-block;
	}
}

.actions {
	.switchLeft {
		&::before {
			mask: url('@{iconPath}/lightbox/browse_left.svg') no-repeat;
		}

		margin-left: 3px;
	}

	.switchRight {
		&::before {
			mask: url('@{iconPath}/lightbox/browse_right.svg') no-repeat;
		}

		margin-right: 3px;
	}

	.switchLeft,
	.switchRight {
		&::before {
			background-color: var(--svg-icon-color-normal);
		}

		&:hover::before {
			background-color: var(--svg-icon-color-hover);
		}

		&.inactive:hover,
		&.inactive {
			&::before {
				background-color: var(--svg-icon-color-inactive);
			}
		}
	}
}

.closeLightbox {
	float: right;
	border-radius: 3px;

	.icon {
		display: none;
	}

	&::before {
		background-color: var(--svg-icon-color-hover);
		mask: url('@{iconPath}/general/close.svg') center no-repeat;
	}


}

.scalePreview {
	float: right;
	margin-right: 2px;

	&::before {
		background-color: var(--svg-icon-color-normal);
		mask: url('@{iconPath}/lightbox/scaleOut.svg') center no-repeat;
	}

	&:hover::before {
		background-color: var(--svg-icon-color-hover);
		cursor: pointer;
	}

	&.undo::before {
		mask-image: url('@{iconPath}/lightbox/scaleOut_undo.svg');
	}

	&.inactive::before {
		background-color: var(--svg-icon-color-inactive);
		cursor: default;
	}
}