@import '../../utilities/lib';
div.error {
	border-radius: @DefaultBorderRadius;
	border: none;
	margin-bottom: 2px;
	padding: 14px 7px;
}

.error {
	&.small {
		padding: 5px;
	}

	h3 {
		font-weight: bold;
		padding-bottom: 5px;
	}

	p {
		margin: 0;
	}
}

.error {
	background-color: @DarkOrange;
	color: #000;
	h3 {
		color: #000;
	}
}

div.box.notification {
	display: none;
}

.tabulationContainer div.box.error {
	margin-bottom: 0;
}

input {
	&.error[type="checkbox"] , &.error[type="radio"] {
		border-color: transparent;
		background-color: transparent;
		padding: 0;
	}
	&.notification {
		background-color: @White;
		color: @Black;
	}
}

input , select , textarea {
	&.error {
		background: @White;
		border: 1px solid @MediumGrey6;
	}
}

select.error {
	padding: 0;
}

html.lt-ie9 {
	input {
		&.error[type="checkbox"] , &.error[type="radio"] {
			border-right-width: 5px;
		}
	}
}

