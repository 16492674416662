@import '../utilities/lib';
#breadCrumb {
	margin: 5px 0;
	display: flex;
	align-items: center;
	
	a {
		font-size: @FontSizeBreadcrumb;
		text-shadow: none;
	}
	span {
		font-size: @FontSizeBreadcrumb;
		white-space: nowrap;
	}
}

.crumbContainer {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	span {
		padding-left: 8px;

		&.breadCrumbSeparator {
			padding: 0;
			display: inline-block;
			width: 16px;
			height: 16px;
			mask: url("../../img/breadcrumb_seperator.svg") no-repeat center center;
			margin: 0 0 0 @GuttingDefault;
			background-color: @DarkGrey_2021;
		
			@media screen and (forced-colors: active) {
				background-color: CanvasText;
			}
		}
	}
}

.icon.toHome {
	margin-right: 10px;
	float: left;
	width: 24px;
	height: 24px;
	&::before {
		width: 24px;
		height: 24px;
		content: '';
		mask: url("../../img/home.svg") no-repeat;
	}
	&:hover::before {
		mask: url('../../img/home_hover.svg') no-repeat;
	}

	&.icon::before {
		mask-position: center center;
	}
}
