@import '../utilities/lib.less';
div.horizontalContainer , div.verticalContainer {
	margin-left: 150px;
}

.yform {
	.item label {
		clear: both;
		float: none;
		width: auto;
	}
}

.bas-inputCheck {
	label {
		vertical-align: middle;
		margin-left: 4px;
	}
}

select , td {
	&.cols_days {
		width: 150px;
	}
}

td {
	&.cols_time {
		width: 138px;
		margin: 0 3px 0 27px;
	}
}

select.cols_hours {
	width: 53px;
}

select.cols_minutes {
	width: 53px;
}

.sourceDestinationSet {
	display: flex;
	justify-content: space-between;
	
	.sourceWrapper, .destinationSet, .sourceSet {
		.item.vertical .itemBox {
			// override cols-classes
			width: auto;
		}
	}
	
	.destinationSet , .sourceSet {
		.bas-inputSelect {
			position: relative;
		}

		select , div.selectBox {
			width: 100%;
			max-width: 100%;
		}
		
		div.selectBox ul.selectBoxHit {
			box-sizing: border-box;
		}
		
	}

	.sourceWrapper {
		@searchHeight: 40px;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 1px;
			left: 1px;
			width: 95%;
			height: @searchHeight;
			background-color: #fff;
			z-index: 99;
		}

		.formsearch {
			width: 93%;
			left: 5px;
			&::-ms-clear {
				display: none;
			}

			& , & + #searchfieldTrigger {
				position: absolute;
				top: 13px;
				z-index: 100;
			}

			+ #searchfieldTrigger {
				right: 8%;
				top: 15px;
			}
		}

		select , div.selectBox ul.selectBoxHit {
			padding-top: @searchHeight;
		}
	}

	> .controls {
		width: 10%;
		display: flex;
		text-align: center;
		flex-direction: column;
		justify-content: center;
		a {
			float: none;
			display: inline-block;
			margin: 0;
		}
	}

	.destinationSet , .sourceSet {
		width: 45%;
	}

	input.formsearch {
		width: 100%;
	}
}

html {
	&.ie {
		.sourceDestinationSet {
			@selectHeight: 270px;
			.sourceSet select {
				height: @selectHeight;
			}
		}
	}

	&.chrome {
		.sourceDestinationSet .sourceWrapper:before {
			width: calc(100% - 20px);
		}
	}
}

video {
	width: 100%;
}

.video-preview{
	width: 576px;
	height: 468px;
}

.audio-preview{
	width: 576px;
	height: 100px;
}

