.show {
	display: block;
	visibility: visible;
}

.hide {
	display: none !important;
	visibility: hidden !important;
}

.visibilityShow {
	visibility: visible;
}

.visibilityHide {
	visibility: hidden;
}