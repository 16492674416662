@import "./style/variableProvider";
@import "./style/default/css/modules/jquery.ui.datepicker.common.less";
@import "./style/default/css/modules/jquery.ui.datepicker.extension.less";
@import "style/default/css/pluginPacks/jqueryUi/jquery-ui.custom.less";
@import "./style/customer/allianz/css/pluginPacks/jqueryUi/jquery.ui.theme.less";
@import "./style/customer/allianz/css/modules/general/datepicker.less";
@import "./style/customer/allianz/css/modules/general/icons.less";


body {
	text-align: left;
	height: 100%;
	&.readonly {
		color: @InactiveFontColor;
	}
}
