/**
 * "Yet Another Multicolumn Layout" - YAML CSS Framework
 *
 * (en) Vertical list navigation "vlist"
 * (de) Vertikale Navigationsliste "vlist"
 *
 * @copyright       © 2005-2013, Dirk Jesse
 * @license         CC-BY 2.0 (http://creativecommons.org/licenses/by/2.0/),
 *                  YAML-CDL (http://www.yaml.de/license.html)
 * @link            http://www.yaml.de
 * @package         yaml
 * @version         4.1.2
 */
@media all {
  /* 4 navigation levels defined */
  .ym-vlist {
    margin: 0 0 1.5em 0;
    list-style-type: none;
    background: transparent;
    border: 0 none;
  }
  .ym-vlist ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: hidden;
    border-top: 2px #ddd solid;
    border-bottom: 2px #ddd solid;
  }
  .ym-vlist ul ul {
    border: 0 none;
  }
  .ym-vlist li {
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #fff;
  }
  .ym-vlist a,
  .ym-vlist strong,
  .ym-vlist span {
    display: block;
    padding: 3px 0 3px 10%;
    text-decoration: none;
    border-bottom: 1px #ddd solid;
  }
  .ym-vlist a,
  .ym-vlist a:visited {
    color: #444;
  }
  .ym-vlist li span {
    display: block;
    font-weight: bold;
    border-bottom: 1px #ddd solid;
  }
  .ym-vlist li.active {
    color: #fff;
    background-color: #444;
  }
  .ym-vlist li.active strong {
    font-weight: bold;
  }
  .ym-vlist li a,
  .ym-vlist li strong,
  .ym-vlist li span {
    width: 90%;
    padding-left: 10%;
  }
  .ym-vlist li a:focus,
  .ym-vlist li a:hover,
  .ym-vlist li a:active {
    background-color: #888;
    color: #fff;
    outline: 0 none;
  }
  .ym-vlist li ul li a,
  .ym-vlist li ul li strong,
  .ym-vlist li ul li span {
    width: 80%;
    padding-left: 20%;
  }
  .ym-vlist li ul li a,
  .ym-vlist li ul li a:visited {
    background-color: #f8f8f8;
    color: #333;
  }
  .ym-vlist li ul li a:focus,
  .ym-vlist li ul li a:hover,
  .ym-vlist li ul li a:active {
    background-color: #888;
    color: #fff;
  }
  .ym-vlist li ul li ul li a,
  .ym-vlist li ul li ul li strong,
  .ym-vlist li ul li ul li span {
    width: 70%;
    padding-left: 30%;
  }
  .ym-vlist li ul li ul li a,
  .ym-vlist li ul li ul li a:visited {
    background-color: #f0f0f0;
    color: #222;
  }
  .ym-vlist li ul li ul li a:focus,
  .ym-vlist li ul li ul li a:hover,
  .ym-vlist li ul li ul li a:active {
    background-color: #888;
    color: #fff;
  }
  .ym-vlist li ul li ul li ul li a,
  .ym-vlist li ul li ul li ul li strong,
  .ym-vlist li ul li ul li ul li span {
    width: 60%;
    padding-left: 40%;
  }
  .ym-vlist li ul li ul li ul li a,
  .ym-vlist li ul li ul li ul li a:visited {
    background-color: #e8e8e8;
    color: #111;
  }
  .ym-vlist li ul li ul li ul li a:focus,
  .ym-vlist li ul li ul li ul li a:hover,
  .ym-vlist li ul li ul li ul li a:active {
    background-color: #888;
    color: #fff;
  }

  /* title */
  .ym-vtitle {
    font-weight: bold;
    font-size: 100%;
    width: 90%;
    padding: 3px 0 3px 10%;
    margin: 0;
    color: #444;
    background-color: #fff;
    border-top: 2px #ddd solid;
  }
  .ym-vtitle + ul {
    border-top: 4px #888 solid;
  }
}
