@import '../utilities/lib.less';
div.selectBox {
	background: #fff;
	ul {
		display: block;
		list-style-type: none;
		margin: 0;
		padding: 0;
		.user-select (none);
		li {
			display: block;
			white-space: nowrap;
			margin: 0;
			padding: 0;
		}

		* ul {
			padding: 0;
		}
	}

	div.selectBoxHit {
		position: relative;
		overflow: hidden;
		li.selectOption {
			cursor: pointer;
		}
	}

	&.disabled div.selectBoxHit li.selectOption {
		cursor: default;
	}

	> ul.selectOptionContainer {
		position: absolute;
		background: #fff;
		overflow: hidden;
		z-index: @Index_SelectOptions;
	}

	span.hitBoxArrow {
		position: absolute;
		top: 0;
		right: 0;
	}

	li.selectOption {
		&.disable {
			& , &:hover {
				color: #96969D;
			}
		}

		&.textstriked {
			text-decoration: line-through;
		}
	}

	&.multiple , &.size {
		> ul.selectOptionContainer {
			position: relative;
			z-index: @Index_SelectOptionsMultiple;
			overflow-y: auto;
		}
	}
}

