.cal_legende {
	margin-left: 10px;
	padding-top: 40px;
	padding-bottom: 15px;
	font-size: 11px;
	
	.cal_legende_header {
		font-weight: bold;
		margin-bottom: 5px;
	}

	.cal_legend_container {
		display: flex;
		
		.title {
			margin-bottom: 5px;
		}
		
		.cal_legend_type_container {
			width:250px;
		}

		.cal_legende_status {
			height: 25px;
			line-height: 25px;
			.cal_legende_status_label {
				white-space: nowrap;
			}
			.cal_legende_status_icon {
				float: left;
				width: 15px;
				height: 15px;
				margin-top: 5px;
				margin-right: 7.5px;
				background-size: cover;
			}
			.cal_legende_status_svg {
				float: left;
				width: 15px;
				height: 15px;
				margin-right: 7.5px;
				background-size: cover;
				#changed_by_user{
					display: inline-block;
					margin-left: -7px;
				}
				#home {
					margin-top: -2px;
				}
			}
		}

		.cal_legende_type {
			height: 25px;
			line-height: 25px;
			display: flex;
			align-items: center;

			.cal_legende_type_label {
				white-space: nowrap;
			}

			.cal_legende_type_preview {
				width: 20px;
				float:left;
				margin: 5px;
			}
		}
	}
}
