@import '../utilities/lib.less';
.fourPictureRow td {
	border-width: 0;
	border-style: none;
}

.picture-footer {
	border-top: none;
}

table.fourPictureRow td.picture-footer div.bas-inputButton {
	margin: 2px 0;
}

table.fourPictureRow {
	width:1200px;
	}

.center-text {
	padding: 5pt;
}

.cms_startpage {
	width: @PageWidth;
	margin-top: 30px;
	box-sizing: content-box;
	.cms-container {
		background-color: @White;
	}

}

.cms-container , .cms-startpage-container-picturerow {
	margin: 2px 0;
	text-align: start;
}

.cms-container {
	padding: 0 0 0 0;
	margin-bottom: 20px;
	h5 {
		margin-bottom: 10px;
	} 
}

.cms-startpage-container-picturerow {
	padding: 0 3px 0 3px;
}

.cms-float img {
	float: left;
	margin: 0 15px 15px 0;
}

.cms-edit-image-preview {
	max-height: 250px;
}

.cms-show-image-startpage {
	width: 100%;
}

.cms-placeholder-withlabel-maxwith {
	max-width: @ColsInput5;
}

.vertical-top {
	vertical-align: top;
}

section.two-columns {
	margin-top: 2px;
	margin-bottom: 2px;
}

.two-columns {
	> .two-columns-left , > .two-columns-right {
		background-color: @White;
	}

	> .two-columns-right {
		display: inline-block;
		width: auto;
		margin-left: 20px;
	}
}
div.cmsplaceholderpicture {
	background-color: @LightGrey2;
	text-align: center;
	vertical-align: middle;
	display: inline-block;
}

.cms-formattedlink {
	margin: 3px 0;
}

.cms-layout2-picture {
	max-height: 100px;
}

.cms-formattedlink-startpage {
	margin: 10px 0 0 0;
}

.cms-startpage-column-1 {
	width: 386px;
	div.cmsplaceholderpicture {
		width: 386px;
		height: 151px;
	}
}

.cms-startpage-column-2 {
	width: 386px;
	padding-left: 21px;
	div.cmsplaceholderpicture {
		width: 386px;
		height: 151px;
	}
}

.cms-startpage-column-3 {
	width: 386px;
	padding-left: 21px;
	div.cmsplaceholderpicture {
		width: 386px;
		height: 151px;
	}
}
.cms-startpage-column-picture {
	height: 182px;
	line-height: 0;
	overflow: hidden;
}


.cms-container-plain .cms-container + .cms-container {
	border-top: 2px solid @White;
}

.startpage-top {
	color: @White;
	font-weight: bold;
	padding: 5px 10px 3px 10px;
	font-size: 12px;
	margin: 5px 0 3px 0;
}

.startpage-header {
	color: @White;
	text-align: center;
	font-weight: bold;
	font-size: 15px;
	padding: 5px 10px 3px 10px;
	margin: 0 0 3px 0;
}

.startpage-title {
	padding: 5px 10px 3px 10px;
	font-size: 12px;
	margin: 0 0 3px 0;
}

.startpage-top-color {
	background-color: @Orange;
}

.startpage-header-color {
	background-color: @LightOrange;
}

.startpage-title-color {
	background-color: @LightestOrange;
}

.cms-startpage-column-description {
	padding: 25px;
}

.cms-container.two-columns-left {
	border-right: 2px solid @LightGrey1;
}

.cms-link-cell {
	background: url("../../img/dotted.png") repeat-x scroll left top transparent;
	margin-top: 5px;
	padding-top: 5px;
	height: 16.75px;
	max-height: 16.75px;
}

.statesemptycell > span {
	display: inline-block;
}

.statesItem , .statesCount {
	display: inline-block;
	float: left;
	height: 16px;
	max-height: 16px;
}

div.tooltip .legendElement {
	margin-right: 7px;
}

.statesHeader {
	text-align: center;
	display: inline-block;
	.tooltipTrigger {
		float: left;
		display: inline-block;
		+ .tooltipTrigger {
			margin-left: 10px;
		}
	}
}

