@import '../utilities/lib.less';


div.buttonline {
	text-align: right;
	.bas-inputButton {
		display: inline-block;
		margin-left: 2px;
		margin-bottom: 2px;
	}
}

div.bas-inputArea {
	input{
		&[type="checkbox"],&[type="radio"]{
			&.cols1, &.cols2, &.cols3, &.cols4, &.cols5, &.cols6 {
				width: auto;
			}	
		}
	}
	.cols1 {
		width: @ColsInput1;
	}
	
	.cols2 {
		width: @ColsInput2;
	}
	
	.cols3 {
		width: @ColsInput3;
	}
	
	.cols4 {
		width: @ColsInput4;
	}
	
	.cols5 {
		width: @ColsInput5;
	}
	
	.cols6 {
		width: @ColsInput6;
	}
	
	img {
		&.cols1 {
			width: auto;
			max-width: @ColsInput1;
		}
		
		&.cols2 {
			width: auto;
			max-width: @ColsInput2;
		}
		
		&.cols3 {
			width: auto;
			max-width: @ColsInput3;
		}
		
		&.cols4 {
			width: auto;
			max-width: @ColsInput4;
		}
		
		&.cols5 {
			width: auto;
			max-width: @ColsInput5;
		}
		
		&.cols6 {
			width: auto;
			max-width: @ColsInput6;
		}
	}
	
	div.cols1 , div.cols2 , div.cols3 , div.cols4 , div.cols5 , div.cols6 {
		margin-right: 0;
		border-radius: @DefaultBorderRadius;
	}
}

.textAlignCenter {
	text-align: center;
}

.layoutContainer {
	overflow: hidden;
	.ym-equalize {
		overflow: auto;
		> [class *= "ym-g"] > [class *= "ym-gbox"] {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
}

.whitespace {
	&_bottom {
		margin-bottom: 20px !important;
	}
}

.browserOutdatedImg {
	float: left;
	margin-top: 40px;
	margin-left: 20px;
}

.browserOutdatedText {
	float: right;
	margin-top: 20px;
	div {
		margin-top: 20px;
	}
}

.browserOutdatedWarn {
	color: @DarkRed;
	font-weight: bold;
	text-decoration: none;
}
					
.mobile-login &.bas-inputArea &.cols3 {
	width: auto;
}

