@import "../../utilities/lib.less";

.cke_panel_container *:focus {
	outline: none;
}

.cke_editor tr:hover {
	background-color: @Yellow ! important;
}

.newslettertext {
	p {
		margin-bottom: 10px;
	}

	li {
		margin-left: 40px;
	}
}

.inlineckeditor {
	background: @White;
	word-wrap: break-word;
}

.bas-inputArea .cms-container span, .inlineckeditor {
	line-height: calc(30 / 16);
	margin: 0 5px 3px 0;

	li {
		margin-left: 1.5em;
	}

	ol, ul {
		&, ul, ol {
			padding-left: 20px;
			margin: 12px 0 12px 0;
		}
	}

	&[readonly=readonly] {
		background: @InputInactiveColor;
		color: @InputFontInactiveColor;
	}
}

