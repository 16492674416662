@import '../utilities/lib.less';

div.tabulation {
	display: table;
	border-collapse: collapse;
	width: 100%;

	div.col {
		color: @DarkGrey2;
		font-size: @FontSize;
		font-weight: bold;
		line-height: 13px;
		border: 1px solid @LightGrey1;
		padding: 4px 0 7px 7px;

		img {
			background: @White;
		}
	}

	div.row {
		display: table-row;

		div.col {
			background: none;
			font-weight: normal;
			vertical-align: top;
		}
	}
}

table.tabulation {
	color: @DarkGrey2;
	line-height: 13px;
	border-collapse: separate;

	tr.header,
	tr.header td,
	tbody tr.header:hover td,
	tbody tr.header:hover td {
		background: @TableColorDarkGrey;
		font-weight: bold;
		border-right: 1px solid @White;
	}

	tr.header.sortRow td,
	tr.header .checkboxGroupSelector {
		line-height: 100%;
	}

	tr {
		&.header {
			height: 25px;

			&.sortRow {
				padding-bottom: 0;
			}

			.withLink {

				&,
				&:hover,
				&:active,
				&:focus {
					font-weight: normal;
				}
			}

			.checkboxGroupSelector .bas-inputCheck {
				margin-bottom: 0;
			}

			td.col span {
				padding: 0 0 0 0;
			}

			.productId,
			.mscId {
				white-space: nowrap;
			}
		}

		&.odd,
		&.odd:hover,
		&.odd:hover>td,
		&:nth-child(odd) {
			background-color: @TableColorLightGrey;
		}

		&.even,
		&.even:hover,
		&.even:hover>td,
		&:nth-child(even) {
			background-color: @White;
		}
	}

	td {
		padding: 4px 5px;

		&.topborder {
			border-top: 2px solid;
		}

		&.col.nowrap {

			a.icon,
			div.icon {
				margin-right: 2px;
			}
		}
	}

	tbody tr.footer {

		th,
		td {
			background-color: @TableColorDarkGrey;
			border-bottom: 1px solid @White;
		}
	}
}

.sortRow {
	height: auto ! important;
}

.sortIcons {
	text-align: center;
	line-height: 0;

	a.icon {
		width: 24px;
		height: 24px;
		margin-right: 0;
	}
}

tbody tr:hover {

	th,
	td {
		background: inherit;
	}
}

tbody {
	td {
		border: 0 none;
	}

	.header td {
		border-top: 0;
	}
}

div.tabulationContainer {
	&.scrollable {
		table {

			&.tabulation,
			&.clonedTempTable {
				border-left: none;
				border-right: none;
			}
		}
	}

	~div.footer {
		padding: 4px 5px;
		border-top: 0;
	}
}