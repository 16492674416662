.ym-g10 {
	width: 10%;
}

.ym-g20 {
	width: 20%;
}

.ym-g16 {
	width: 16.66%;
}

.ym-g30 {
	width: 30%;
}

.ym-g40 {
	width: 40%;
}

.ym-g45 {
	width: 45%;
}

.ym-g60 {
	width: 60%;
}

.ym-g70 {
	width: 70%;
}

.ym-g84 {
	width: 83.33%
}

.ym-g16 , .ym-g84 {
	float: left;
}

.ym-g420 {
	width: 420px;
}

.ym-g460 {
	width: 460px;
	padding-left: 20px;
}

.ym-g17 {
	width: 17px;
}

