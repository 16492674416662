@import "../utilities/lib.less";

@_imgPath: "../../img/icons";

.overlay {
	div.close {
		border-radius: 3px 3px 3px 3px;
		padding: 3px;
		margin: 24px;
		background-color: @LightGrey6;
		height: 30px;
		width: 30px;

		&::before {
			height: 24px;
			width: 24px;
		}

		&::before {
			display: inline-block;
			content: "";
			background-color: var(--svg-icon-color-normal);
			mask: url("@{_imgPath}/general/close.svg") no-repeat;
		}
	}

	.overviewImage {
		border: 0 none;
	}

	.overviewButtons {
		padding: 5px 4px 5px 4px;
	}

	.multiPageSelect {
		.imageContainer img {
			border: 2px solid @MediumGrey3;
			border-radius: 1px;
			box-shadow: 0 0 3px @DarkBlue;

			&.current {
				border-color: #ff8200;
			}
		}

		.imageContainer:hover img {
			border-color: @DarkBlue;
		}
	}
}

.overviewArea {
	border: 1px solid @MediumGrey4;
	padding: 7px;
	margin-top: 5px;
}

.overviewImage {
	border: 1px solid @MediumGrey4;
	.bas-gradient (@MediumGrey5, @MediumGrey4);
}

.picturePreview {
	background: @White;
	border: 1px solid #bebebe;
	padding: 9px 10px 8px 8px;
}

.overviewButtons {
	background: @White;
	border: none;
}

div.overviewButton {
	margin: 1px 0 0 0;
}

div.overviewButtons {
	text-align: center;
	padding: 7px 0 0 0;

	.bas-inputText {
		margin: 0 2px 0 2px;

		input.zoomInput {
			height: 24px;
			margin-top: 1px;
		}
	}

	div.lock {
		display: none !important;
	}

	div.bas-inputButton {
		min-width: 0;
		display: inline-block;
		margin-right: 4px;
		margin-top: 1px;
		vertical-align: top;

		.button {
			width: auto;
		}
	}

	.reload {
		width: 100px;
	}

	hr.v2ughrsolid {
		border-color: @DarkGrey2;
	}
}

div.overviewButton {
	cursor: pointer;
	background: none;

	&,
	&::before {
		height: 24px;
		width: 24px;
	}

	&::before {
		display: inline-block;
		content: "";
		background: var(--svg-icon-color-normal);
		mask-repeat: no-repeat;
		mask-position: center center;
	}

	&:hover,
	&.active {
		background: none;

		&::before {
			background: var(--svg-icon-color-hover);
		}
	}

	&:focus-visible {
		outline: @DefaultOutlineWidth @DefaultOutlineStyle @DefaultOutlineColor;
	}

	&.inactive,
	&.inactive:hover {
		&::before {
			background: var(--svg-icon-color-inactive);
		}
	}

	&.inactive {
		cursor: unset;
	}
}

div.zoomIn::before {
	mask-image: url("@{_imgPath}/editView/zoomin.svg");
}

div.zoomOut::before {
	mask-image: url("@{_imgPath}/editView/zoomout.svg");
}

div.zoomDynamic {
	&::before {
		mask-image: url("@{_imgPath}/editView/zoomDynamic.svg");
	}

	&.active::before {
		mask: none;
		background: url("@{_imgPath}/editView/zoomDynamic_active.svg") center center no-repeat;
	}
}

div.scaleX::before {
	mask-image: url("@{_imgPath}/editView/scalex.svg");
}

div.scaleY::before {
	mask-image: url("@{_imgPath}/editView/scaley.svg");
}

div.allrectangles {
	&::before {
		mask-image: url("@{_imgPath}/editView/allrectangles.svg");
	}

	&.active {
		background: none;

		&::before {
			mask: none;
			background: url("@{_imgPath}/editView/allrectangles_active.svg") center center no-repeat;
		}
	}
}

div.pdfsuperview::before {
	mask-image: url("@{_imgPath}/editView/maximize.svg");
}

div.pdfsuperview_currentpage::before {
	mask-image: url("@{_imgPath}/editView/pdfsuperview.svg");
}

div.pdfsuperview_all::before {
	mask-image: url("@{_imgPath}/editView/maximize.svg");
}

div.pdfDownload::before {
	mask-image: url("@{_imgPath}/editView/pdfsuperview.svg");
}

div.pdfDownload_all::before {
	mask-image: url("@{_imgPath}/editView/icon_pdfsuperview_all.svg");
}

div.minimize::before {
	mask: url("@{_imgPath}/editView/minimize.svg");
}

div.overlay {
	div.overviewButton {
		padding: 0;

		&,
		&::before {
			height: 24px;
			width: 26px;
		}
	}

	div.zoomIn::before {
		mask-image: url("@{_imgPath}/editView/overlay/zoomin.svg");
	}

	div.zoomOut::before {
		mask-image: url("@{_imgPath}/editView/overlay/zoomout.svg");
	}

	div.zoomDynamic::before {
		mask-image: url("@{_imgPath}/editView/overlay/zoomDynamic.svg");
	}

	div.scaleX::before {
		mask-image: url("@{_imgPath}/editView/overlay/scalex.svg");
	}

	div.scaleY::before {
		mask-image: url("@{_imgPath}/editView/overlay/scaley.svg");
	}

	div.allrectangles::before {
		mask-image: url("@{_imgPath}/editView/overlay/allrectangles.svg");
	}

	div.pdfsuperview::before {
		mask-image: url("@{_imgPath}/editView/overlay/maximize.svg");
	}

	div.pdfsuperview_currentpage::before {
		mask-image: url("@{_imgPath}/editView/overlay/pdfsuperview.svg");
	}

	div.pdfsuperview_all::before {
		mask-image: url("@{_imgPath}/editView/overlay/maximize.svg");
	}

	div.pdfDownload::before {
		mask-image: url("@{_imgPath}/editView/overlay/pdfsuperview.svg");
	}

	div.pdfDownload_all::before {
		mask-image: url("@{_imgPath}/editView/overlay/icon_pdfsuperview_all.svg");
	}

	div.minimize::before {
		mask-image: url("@{_imgPath}/editView/overlay/minimize.svg");
	}
}

form[name="loginmask"] .groupSeparator {
	font-size: @FontSize;
}

#editMaskContainer,
#overviewContainer {
	background-color: @White;
}

#overviewContainer {
	.itemBox span {
		margin: 0 0 3px 0;
	}
}

#editMaskContainer {

	input[type=text],
	select,
	textarea,
	.inlineckeditor {
		&:not([class*="cols"]) {
			width: 100%;
			max-width: 100%;
		}
	}

	select {
		height: 21px;
	}

	.item.horizontal {

		input[type=text],
		select,
		textarea,
		.inlineckeditor {
			width: auto;
		}

		.itemBox {

			.bas-inputText .cols3,
			.fckcontainer.ckeditor > span,
			.ckeditor > div,
			textarea.cols3.ckeditor,
			.inlineckeditor.cols3 {
				width: 550px ! important;
			}

			&.next-element-is-tooltip {

				&,
				> * {
					max-width: 96% ! important;
				}

				.chosen-container {
					width: fit-content;
				}
			}

		}
	}

	.charcounter.cols3 {
		width: 550px ! important;
	}

	.bas-formBox > .item > .itemBox {
		margin-bottom: 5px;
	}

	.item.vertical .item.horizontal {
		.itemBox {
			margin-right: 5px ! important;
		}

		.item.horizontal .itemBox .bas-inputCheck {
			margin-right: 0;

			input[type="radio"] {
				height: 21px;
			}
		}
	}

	.bas-inputCheck span span div {
		line-height: 21px;
	}

	.tooltipTrigger.elementTrigger {
		width: 100%;
	}

	div.bas-formBox {
		&.admin {
			background-color: @LightGrey_2021;
		}

		&.familiylibrary {
			background-color: @LightGrey_2021;
		}
	}

	.itemBox:has(input[type="checkbox"]) + .itemBox.contains-input {
		flex-grow: 1;
	}
}

#editMaskContainer .imgButton {
	width: unset;
}

.imgButton_container {
	position: relative;
	outline: 2px solid @Blue;

	img {
		width: 100%;
		background-color: @Blue;
	}

	&:hover {
		outline-color: @DarkBlue;

		img {
			background-color: @DarkBlue;
		}
	}

	&.selected,
	&.selected[data-disabled]:hover {
		outline-color: @Orange;

		img {
			background-color: @Orange;
		}
	}

	.isSelected {
		background-color: @White;
		border-color: @White;
	}
}

.overview {
	display: block;
	margin: 0 auto;
	border: 1px solid @MediumGrey4;
}

.superView .overviewArea {
	position: relative;
	width: 100%;
	height: 100% ! important;
}

.multiPagePreviewContainer > img {
	margin: 9px 0;
}

.scrollUp,
.scrollDown {
	width: 100%;
	height: 22px;
	background-color: red;
}

div[id ^="caret:"] {

	&.active > div,
	&.active {
		border-color: @DarkOrange;

		@media screen and (forced-colors: active) {
			forced-color-adjust: none;
			color: LinkText;
			border-color: @DarkOrange;
		}
	}

	&.rotated.focus,
	&.rotated.active {
		> div > div {
			background-color: @DarkOrange !important;

			@media screen and (forced-colors: active) {
				forced-color-adjust: none;
				color: LinkText !important;
				border-color: @DarkOrange !important;
			}
		}
	}

	&.active.focus,
	&.inactive:hover,
	&.active:hover {
		> div {
			border-color: @Green;

			@media screen and (forced-colors: active) {
				forced-color-adjust: none;
				color: HighlightText;
				border-color: @Green;
			}
		}
	}

	&.rotated.active.focus,
	&.rotated.inactive:hover,
	&.rotated.active:hover {
		> div > div {
			background-color: @Green !important;

			@media screen and (forced-colors: active) {
				forced-color-adjust: none;
				color: HighlightText !important;
				border-color: @Green !important;
			}
		}
	}
}

hr {

	&.v2ughrsolid,
	&.v2ughrdashed,
	&.v2ughrdotted {
		border-width: 0 0 1px;
		height: 1px;
		margin: 8px 0 5px;
		padding: 0;
		border-color: @DarkGrey2;
	}
}

.overviewButtons a.button.reload {
	height: auto;
	min-height: 0;
	min-width: 0;
	line-height: 24px;
}

.overviewButtons a.icon.pdfsuperview {
	margin-left: 0;

	&,
	&::before {
		height: 20px;
		width: 22px;
	}

	&::before {
		background-color: var(--svg-icon-color-normal);
		mask: url("@{_imgPath}/editView/maximize.svg") no-repeat center center;
	}

	&:hover,
	&.active {
		&::before {
			background-color: var(--svg-icon-color-hover);
		}
	}
}

#editMask {
	&.ym-equalize {
		overflow: visible;
		display: flex;
		justify-content: space-between;
	}

	&.ym-equalize > [class *="ym-g"] > [class *="ym-gbox"] {
		margin-bottom: 0;
		padding-bottom: 0;
	}

	div.image {
		border: solid 1px @LightGrey3;
	}
}

.userImageSelection > .itemBox {
	text-align: center;
}