@import "../utilities/lib.less";
input[type=text], input[type=password], select, textarea, .inlineckeditor {
	.bas-box-sizing (border-box);
}

.bas-formBox, .field.noLabel {
	display: table;
	width: 100%;
	table-layout: fixed;
	list-style-type: none;
}

.bas-label {
	width: 150px;
	float: left;
}

.bas-inputArea {
	width: auto;
	margin-left: 150px;
	position: relative;
	.bas-inputText.multiAddons {
		display: flex;
		flex-direction: column;
	}
}

.noLabel > .bas-inputArea, #searchMask ~ .contentContainer .bas-inputArea {
	margin-left: 0;
	padding-left: 0;
}

#editMaskContainer {
	input[type=text], select, textarea, .inlineckeditor {
		&:not([class*="cols"]) {
			width: auto;
		}
	}
}
