.legend.horizontal {
	.legendItem {
		padding: 5px 10px;
	}

	.legendHeader {
		padding: 5px 10px 0 0;
	}
}

.legend.vertical .legendHeader {
	padding: 5px 10px 5px 0;
}

