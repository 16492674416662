@LoginFormWidth: 610px;
@LoginButtonWidth: 310px;
@LoginButtonHeight: 50px;
@LoginInputHeight: 50px;

form[name="loginmask"], .layout#loginmask {
	width: @LoginFormWidth;
	margin: 0 auto;

	h1 {
		line-height: unset;
		margin-bottom: var(--spacing-l) !important;
	}

	.bas-formBox, .bas-formSection, .bas-inputArea, .itemBox {
		display: contents !important;
	}

	.field.noLabel {
		margin-bottom: var(--spacing-xs);
	}

	input {
		height: @LoginInputHeight;
		font-size: 24px;
	}

	.vertical input {
		width: 100%;
		margin-bottom: var(--spacing-l);
	}

	.horizontal {
		align-items: center;
		margin-top: var(--spacing-xs);

		input {
			margin-right: 10px;

			&.cols2 {
				width: 50px;
			}

			&.cols3 {
				width: 70px;
			}

			&.cols4 {
				width: 90px;
			}
		}

		.itemBox:nth-child(4) {
			display: block !important;
			flex-grow: 1;
		}

		.icon.text:before {
			order: 2;
		}
	}

	.bas-inputButton {
		display: block !important;
		width: @LoginButtonWidth;
		height: @LoginButtonHeight;
		margin: 0 auto;

		.button.content {
			width: 100%;
			height: 100%;
			line-height: @LoginButtonHeight;
			padding: 0;
		}
	}

	.field--sso-login .bas-inputButton {
		margin-top: var(--spacing-xxl);
		text-transform: uppercase;
	}

	.field--login .bas-inputButton {
		margin-bottom: var(--spacing-xxl);
		text-transform: uppercase;
	}

	.hr-text-separator > .groupSeparator {
		display: flex;
		align-items: center;
		border: none;
		margin: var(--spacing-xxl) 0;

		&:before, &:after {
			content: "";
			flex: 1 1;
			border-bottom: 1px solid @LightGrey5;
		}

		&:before {
			margin-right: var(--spacing-xs);
		}

		&:after {
			margin-left: var(--spacing-xs);
		}
	}

	.icon.text {
		gap: 10px;

		div.iconContainer {
			display: none;
		}
	}

	.sso-error .field {
		margin-bottom: var(--spacing-m);
	}
}