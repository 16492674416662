.ym-hlist {
	background: none;
	border: 0 none;
	ul {
		border: 0 none;
		padding: 0;
		li {
			border: 0 none;
			a , strong {
				padding: 0;
				text-shadow: 0 0 0 #000;
				color: transparent;
			}

			a:focus , a:hover , a:active {
				background: none;
				color: inherit;
			}
		}
	}
}

.ym-hlist {
	overflow: visible;
	ul * ul {
		position: absolute;
	}

	ul li {
		position: relative;
		ul li {
			float: left;
			position: static;
		}
	}

	ul li.hover ul {
		display: block;
		position: absolute;
		top: 1.2em;
		padding-top: 25px;
		left: 0;
		width: 500px;
		li {
			float: left;
		}
	}

	ul li.hover ul li a , ul li a.hover ul li a {
		border: 0;
		margin: 0;
		height: auto;
		line-height: 1em;
		padding: 5px;
		width: 86px
	}
}
