#slider {
	width: 700px;
	height: 390px;
	list-style: none;
	overflow-y: auto;
	overflow-x: hidden
}

.navigation-text-styling {
	font: 11px/18px Georgia, Serif;
	height: 18px;
	padding: 2px 8px;
	margin: 0 5px 0 0
}

.navigation-background {
	background: url(images/default.png) center -288px repeat-x
}

.navigation-background-hovered {
	background-image: none
}

.anythingSlider-default {
	margin: 0 auto;
	padding: 0 45px 28px 45px
}

.anythingSlider-default .anythingWindow {
	border-top: 3px solid #777;
	border-bottom: 3px solid #777
}

.anythingSlider-default .anythingControls a {
	background: #777777 url(images/default.png) repeat-x center -288px;
	color: #000000;
	border-radius: 0 0 5px 5px;
	-moz-border-radius: 0 0 5px 5px;
	-webkit-border-radius: 0 0 5px 5px
}

.anythingSlider-default .anythingControls a span {
	visibility: visible
}

.anythingSlider-default .anythingControls a.cur {
	background: #888;
	color: #000
}

.anythingSlider-default .anythingControls a.start-stop {
	background-color: #040;
	color: #ddd
}

.anythingSlider-default .anythingControls a.start-stop.playing {
	background-color: #800
}

.anythingSlider-default .anythingControls a.start-stop:hover,.anythingSlider-default .anythingControls a.start-stop.hover,.anythingSlider-default .anythingControls a.start-stop .anythingControls ul a:hover
	{
	color: #fff;
	background-image: none
}

.anythingSlider-default.activeSlider .anythingWindow {
	border-color: #7c9127
}

.anythingSlider-default.activeSlider .anythingControls a {
	background-color: #7c9127
}

.anythingSlider-default.activeSlider .anythingControls a.cur,.anythingSlider-default.activeSlider .anythingControls a:hover
	{
	background: #7c9127
}

.anythingSlider-default.activeSlider .anythingControls a.start-stop {
	background-color: #080;
	color: #fff
}

.anythingSlider-default.activeSlider .anythingControls a.start-stop.playing
	{
	color: #fff;
	background-color: #d00
}

.anythingSlider-default.activeSlider .start-stop:hover,.anythingSlider-default.activeSlider .start-stop.hover
	{
	color: #fff
}

.anythingSlider-default .arrow {
	top: 50%;
	position: absolute;
	display: block
}

.anythingSlider-default .arrow a {
	display: block;
	width: 45px;
	height: 140px;
	margin: -70px 0 0 0;
	text-align: center;
	outline: 0;
	background: url(images/default.png) no-repeat
}

.anythingSlider-default .back {
	left: 0
}

.anythingSlider-default .back a {
	background-position: left top
}

.anythingSlider-default .back a:hover,.anythingSlider-default .back a.hover
	{
	background-position: left -140px
}

.anythingSlider-default .forward {
	right: 0
}

.anythingSlider-default .forward a {
	background-position: right top
}

.anythingSlider-default .forward a:hover,.anythingSlider-default .forward a.hover
	{
	background-position: right -140px
}

.anythingSlider-default .anythingControls {
	outline: 0;
	display: none
}

.anythingSlider-default .anythingControls ul {
	margin: 0;
	padding: 0;
	float: left
}

.anythingSlider-default .anythingControls ul li {
	margin: 0;
	display: inline
}

.anythingSlider-default .anythingControls ul a {
	font: 11px/18px Georgia, Serif;
	height: 18px;
	padding: 2px 8px;
	margin: 0 5px 0 0;
	display: inline-block;
	text-decoration: none;
	text-align: center;
	outline: 0
}

.anythingSlider-default .anythingControls .anythingNavWindow {
	overflow: hidden;
	float: left
}

.anythingSlider-default .anythingControls .start-stop {
	padding: 2px 5px;
	width: 40px;
	text-align: center;
	text-decoration: none;
	float: right;
	z-index: 100;
	outline: 0
}

.as-oldie .anythingSlider-default .arrow {
	top: 35%
}

.as-oldie .anythingSlider-default .arrow a {
	margin: 0
}

.as-oldie .anythingSlider-default .anythingControls li {
	margin-left: 3px
}

.as-oldie .anythingSlider-default .anythingControls a {
	margin: 0
}

.as-oldie .anythingSlider-default .anythingNavWindow {
	margin: 0 2px
}

.as-oldie .anythingSlider-default .anythingNavWindow li {
	padding: 3px 0 0 0
}

.anythingSlider {
	display: block;
	overflow: visible !important;
	position: relative
}

.anythingSlider .anythingWindow {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%
}

.anythingSlider .anythingBase {
	background: transparent;
	list-style: none;
	position: absolute;
	overflow: visible !important;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0
}

.anythingSlider .arrow span {
	display: block;
	visibility: hidden
}

.anythingSlider .arrow.disabled {
	display: none
}

.anythingSlider .panel {
	background: transparent;
	display: block;
	overflow: hidden;
	float: left;
	padding: 0;
	margin: 0
}

.anythingSlider .vertical .panel {
	float: none
}

.anythingSlider .fade .panel {
	float: none;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0
}

.anythingSlider .fade .activePage {
	z-index: 1
}

.anythingSlider.rtl .anythingWindow {
	direction: ltr;
	unicode-bidi: bidi-override
}

.anythingSlider.rtl .anythingControls ul {
	float: left
}

.anythingSlider.rtl .anythingControls ul a {
	float: right
}

.anythingSlider .anythingWindow,.anythingSlider .anythingControls ul a,.anythingSlider .arrow a,.anythingSlider .start-stop
	{
	transition-duration: 0s;
	-o-transition-duration: 0s;
	-moz-transition-duration: 0s;
	-webkit-transition-duration: 0s;
}