.legendGroup {
	margin-top: 20px;
	&.horizontal {
		> .legends > .legend {
			display: inline-block;
			vertical-align: top;
		}

		> .legends > .legend + .legend {
			margin-left: 15px;
		}
	}

	.legends .legend .legendHeader {
		font-weight: normal;
	}
}

.legend.horizontal , .legend.horizontal .legendBody {
	display: table;
}

.legend {
	.legendItem {
		display: table-row;
	}

	&.horizontal {
		.legendItem , .legendElement , .legendText {
			display: table-cell;
		}
	}

	&.vertical {
		.legendItem {
			margin-bottom: 3px;
		}
	}
}

.legendHeader {
	font-weight: bold;
}

.legendElement {
	display: inline-block;
	margin-right: 7px;
	vertical-align: middle;
	margin-top: 1px;
	.icon {
		width: auto;
		height: auto;
	}
}

.legendTrashIcon {
	margin-right: 4px;
}

.legendText {
	display: inline-block;
}

