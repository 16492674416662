@import "../utilities/lib.less";

div.dialog {
	display: none;
	position: absolute;

	div.closeDialog, div.destroyDialog {
		position: absolute;
		padding-right: 0;
		margin-right: 0;
	}

	.bas-formSection:has(bwc-date-picker .dropdown-menu) {
		margin-bottom: 300px;
	}
}

div.dialogOverlay {
	z-index: @Index_DialogOverlay;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url("../../img/overlay_background.png");
	position: fixed;
}

div.dialogContainer {
	overflow-y: auto;
	padding: 15px;
	min-width: 300px;
	.bas-gradient (#ffffff; #eeeeee);
	border: 1px solid #dcdcdc;
	float: left;

	section.bas-formSection {
		background: transparent;
	}

	.bas-inputArea {
		width: auto;
	}
}

div.dialogWrapper {
	position: fixed;
	z-index: @Index_DialogWrapper;

	div.dialogHeader {
		cursor: move;
	}

	table.tabulation {
		tr.even span, tr.odd span {
			white-space: nowrap;
		}
	}

	.marginContainer {
		height: 21px;

		.chosen-container {
			position: absolute;
		}
	}

	&.newsletterPreview {
		width: 830px;
		height: 80%;
		background: #ffffff;
		overflow: hidden;

		.destroyDialog {
			right: 0;
		}

		div.dialogContainer {
			float: none;
			background: #ffffff;
			filter: none;
			border: none;
			padding: 0 30px 30px 30px;
			height: 100%;

			div.dialogContent {
				bottom: 30px;
				left: 30px;
				position: absolute;
				right: 30px;
				top: 40px;
			}

			h3 {
				margin-top: 30px;
			}
		}
	}

	&.previewSwitch {
		width: 1150px;
		height: 80%;
		background: #ffffff;
		overflow: hidden;
		filter: none;

		&.single {
			.dialogContent {
				position: absolute;
				bottom: 30px;
				top: 50px;
				left: 30px;
				right: 30px;
			}
		}

		.jquery_tabs {
			margin: 0;
		}

		.destroyDialog {
			right: 0;
		}

		iframe {
			border: 0;
		}

		div.dialogHeader {
			float: none;
			min-height: 20px;
			margin-bottom: 15px;
		}

		div.dialogContainer {
			float: none;
			background: #ffffff;
			filter: none;
			border: none;
			padding: 0 30px 30px 30px;
		}

		.tabbody {
			margin-top: -1px;
			padding-top: 10px;

			.iframePreviewContainer {
				position: relative;
				overflow: auto;
				height: 100%;

				> div {
					position: relative;
					display: block;

					> iframe, > div.image {
						border: 0;
						position: absolute;
					}
				}

				&.tablet {
					div.image {
						width: 896px;
						height: 654px;
						background-size: contain;
						background-repeat: no-repeat;
					}

					div.object {
						height: calc(100% - 10px);
					}

					iframe {
						width: 828px;
						height: 465px;
						top: 35px;
					}

					object {
						height: 100%;
						width: 828px;
						top: 0;
					}
				}

				&.mobile {
					div.image {
						width: 330px;
						height: 654px;
						background-size: contain;
						background-repeat: no-repeat;
					}

					div.object {
						height: calc(100% - 10px);
					}

					iframe {
						width: 359px;
						height: 595px;
						.transformScale (0.8);
						top: 96px;
					}

					object {
						width: 359px;
						height: 595px;
						max-height: 100%;
						.transformScale (1.0);
						position: absolute;
						top: 50%;
						transform: translate(-0%, -50%);
					}
				}
			}
		}
	}

	&.browsersupportDialog {
		width: 550px;

		.dialogFooter {
			padding: 30px;
			padding-bottom: 10px;
		}

		.dialogContainer {
			overflow: auto;

			.dialogContent {
				.browserSupportScreenContent {
					.container {
						&.left {
							width: 150px;
							text-align: center;
							height: 150px;
							margin-left: 30px;
							float: left;

							img {
								top: 40px;
								position: relative;
							}
						}

						&.right {
							position: relative;
							width: 350px;
							padding-right: 40px;
							margin-top: 40px;
							box-sizing: border-box;
							float: right;

							.headline {
								margin-bottom: 15px;
							}

							.downloadList {
								margin-top: 20px;
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}

	.dialogFooter {
		background: #ffffff;
	}
}

div.dialogContent {
	margin-top: 15px;
	overflow: hidden;

	> span ~ span {
		display: block;
	}

	.field.noLabel {
		display: block;
	}
}

.openDialog, .closeDialog, .destroyDialog {
	cursor: pointer;
}

.buttonline div.dialog {
	text-align: left;
}

.dialog .dialogButtonline {
	margin-top: 10px;

	.destroyDialog, .closeDialog {
		position: relative;
	}

	.bas-inputButton {
		.button {
			&, &:active, &:focus {
				margin-right: 0;
			}
		}

		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}

