/*
	YAML RESETS
*/
.treeNode {
	table {
		margin: 0;
		border: 0 none transparent;
		width: auto;
		td {
			border: 0 none transparent;
			padding: 0;
			vertical-align: middle;
			line-height: normal;
		}

		input[type="image"] {
			border: 0 none transparent;
			margin: 0;
			padding: 0;
		}
	}
}