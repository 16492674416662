@import "../utilities/lib.less";

.img-button.detail {
	border-style: solid;
	border-width: 1px;
	cursor: pointer;
	float: left;
	margin-right: 5px;
	margin-top: 5px;
	padding: 15px;
	width: 175px;
	
	.preview {
		position: relative;
		margin-bottom: 9px;
		
		.isSelected {
			border-radius: 50%;
			bottom: 0;
			left: 100px;
			max-width: 25px;
			min-width: 15px;
			position: absolute;
			width: 33%;
			.translate (-40% , 40%);
		}
		
		img {
			border-style: solid;
			border-width: 1px;
			height: 100px;
			width: 100px;
		}
	}
	
	.description {
		padding: 10px 5px 5px 0;
	}
}