/*
popModal - 1.15 [24.02.15]
Author: vadimsva
Github: https://github.com/vadimsva/popModal
*/
/* popModal */
.popModal {position:absolute;background:#fff;padding:15px;border:1px solid #aaa;box-shadow:0 2px 6px rgba(0, 0, 0, 0.3);text-align:left;max-width:300px;min-width:180px;z-index:9997;box-sizing:border-box;transform:translateZ(0);-webkit-backface-visibility:hidden;backface-visibility:hidden}
.popModal .close {line-height:12px;font-size:25px;font-weight:bold;font-family:serif;position:absolute;right:8px;top:8px;color:#000;opacity:0.2;padding:0;cursor:pointer;background:transparent;border:0;outline:0}
.popModal .close:hover {opacity:1}
.popModal .popModal_content {margin:15px auto;word-wrap:break-word;font-family:sans-serif;font-size:14px;font-weight:initial;font-style:initial;color:initial;text-decoration:initial;text-transform:initial;padding:initial}
.popModal .popModal_content.popModal_contentOverflow {max-height:200px;overflow-y:auto;overflow-x:hidden;margin:15px -8px;padding:0 7px}
.popModal .popModal_content:before {content: "";position:absolute;left:0;right:0;height:20px;bottom:-20px}
.popModal .popModal_footer {background:#fafafa;padding:15px;border-top:1px solid #ddd;text-align:right;position:absolute;bottom:0;left:0;right:0}
.popModal .popModal_footer button {margin:0 0 0 5px}
.popModal:before,
.popModal:after {content: "";position:absolute;border:10px solid transparent}
.popModal:after {z-index:1}
.popModal.bottomRight {right:0}
.popModal.bottomLeft:before,
.popModal.bottomRight:before,
.popModal.bottomCenter:before {border-top-width:0;border-bottom-color:#aaa;top:-10px;left:6px}
.popModal.bottomLeft:after,
.popModal.bottomRight:after,
.popModal.bottomCenter:after {border-top-width:0;border-bottom-color:#fff;top:-9px;left:6px}
.popModal.bottomRight:before,
.popModal.bottomRight:after {left:auto;right:6px}
.popModal.bottomCenter:before,
.popModal.bottomCenter:after {left:50%;margin-left:-10px}
.popModal.leftTop:before,
.popModal.leftCenter:before {border-right-width:0;border-left-color:#aaa;top:6px;right:-10px}
.popModal.leftTop:after,
.popModal.leftCenter:after {border-right-width:0;border-left-color:#fff;top:6px;right:-9px}
.popModal.rightTop:before,
.popModal.rightCenter:before {border-left-width:0;border-right-color:#aaa;top:6px;left:-10px}
.popModal.rightTop:after,
.popModal.rightCenter:after {border-left-width:0;border-right-color:#fff;top:6px;left:-9px}
.popModal.leftCenter:before,
.popModal.rightCenter:before {top:50%;margin-top:-10px}
.popModal.leftCenter:after, 
.popModal.rightCenter:after {top:50%;margin-top:-10px}
@media (max-width: 400px){
.popModal {position:relative;top:10px!important;left:auto!important;right:auto!important;width:100%!important;max-width:none}
}


/* notifyModal */
.notifyModal {position:fixed;z-index:999999;background:rgba(255,255,255,0.5);opacity:0;cursor:pointer;transition:all 200ms ease;transform:translateZ(0);-webkit-backface-visibility:hidden;backface-visibility:hidden}
.notifyModal.overlay {top:0;bottom:0;left:0;right:0}
.notifyModal.overlay.center {top:0;bottom:0}
.notifyModal.open {opacity:1}
.notifyModal.open .notifyModal_content {-webkit-transform:scale(1,1);-ms-transform:scale(1,1);transform:scale(1,1)}
.notifyModal .notifyModal_content {position:absolute;background:#F9EDBE;width:380px;padding:25px;margin:auto;border:1px solid #CAB388;text-align:center;box-shadow:0 2px 6px rgba(0, 0, 0, 0.3);transition:all 200ms ease;-webkit-transform:scale(0.95,0.95);-ms-transform:scale(0.95,0.95);transform:scale(0.95,0.95);font-family:sans-serif;font-size:14px;font-weight:initial;font-style:initial;color:initial;text-decoration:initial;text-transform:initial;line-height:18px;box-sizing:border-box}
.notifyModal.alert .notifyModal_content {background:#A24343;border-color:#8A2525;color:#fff}
.notifyModal.simple .notifyModal_content {background:#fff;border-color:#aaa}
.notifyModal.dark .notifyModal_content {background:#333;border-color:#000;color:#ccc}
.notifyModal.dark .notifyModal_content .close {color:#ccc}
.notifyModal.leftTop {top:0;left:0}
.notifyModal.centerTop {top:0;left:0;right:0}
.notifyModal.rightTop {top:0;right:0}
.notifyModal.center {top:35%;bottom:auto;left:0;right:0}
.notifyModal.leftBottom {bottom:0;left:0}
.notifyModal.centerBottom {bottom:0;left:0;right:0}
.notifyModal.rightBottom {bottom:0;right:0}
.notifyModal.center .notifyModal_content {left:0;right:0;top:35%}
.notifyModal.leftTop .notifyModal_content {left:20px;top:20px}
.notifyModal.centerTop .notifyModal_content {left:0;right:0;top:20px}
.notifyModal.rightTop .notifyModal_content {right:20px;top:20px}
.notifyModal.leftBottom .notifyModal_content {left:20px;bottom:20px}
.notifyModal.centerBottom .notifyModal_content {left:0;right:0;bottom:20px}
.notifyModal.rightBottom .notifyModal_content {right:20px;bottom:20px}
.notifyModal .notifyModal_content .close {line-height:12px;font-size:25px;font-weight:bold;font-family:serif;position:absolute;right:8px;top:8px;color:#000;opacity:0.2;padding:0;cursor:pointer;background:transparent;border:0;outline:0}
.notifyModal .notifyModal_content .close:hover {opacity:1}
@media (max-width: 400px){
.notifyModal .notifyModal_content {width:auto;margin:auto 20px}
.notifyModal.center .notifyModal_content {top:15%}
}


/* hintModal */
.hintModal {
	position: relative;
	overflow: visible;
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden
}

.hintModal .hintModal_container {
	display: none;
	left: 0;
	margin: 0;
	position: absolute;
	background: #f8f4e1;
	padding: 10px;
	border: 1px solid #b4a984;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	opacity: 0;
	text-align: left;
	max-width: 260px;
	min-width: 260px;
	z-index: 1;
	line-height: 16px;
	font-size: 12px;
	font-weight: normal;
	font-family: sans-serif;
	font-style: initial;
	margin-top: 6px;
	top: 100%;
	box-sizing: border-box;
	color: initial;
	text-decoration: initial;
	text-transform: initial
}

.hintModal .hintModal_container:before,
.hintModal .hintModal_container:after {
	content: "";
	position: absolute;
	border: 6px solid transparent;
	border-top-width: 0;
	border-bottom-color: #b4a984;
	top: -6px;
	left: 6px}
.hintModal .hintModal_container:before {border-bottom-color:#B4A984;top:-6px}
.hintModal .hintModal_container:after {border-bottom-color:#F8F4E1;top:-5px;z-index:1}
.hintModal.bottomRight .hintModal_container {left:auto;right:0}
.hintModal.bottomRight .hintModal_container:before,
.hintModal.bottomRight .hintModal_container:after {left:auto;right:6px}
.hintModal.bottomCenter .hintModal_container {left:-120px;right:-120px;margin-left:auto;margin-right:auto}
.hintModal.bottomCenter .hintModal_container:before,
.hintModal.bottomCenter .hintModal_container:after {left:50%;margin-left:-6px}
.hintModal:hover .hintModal_container {display:block}


/* dialogModal */
.dialogModal {position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,0.7);z-index:888888;overflow-y:scroll;opacity:0;transition:all 200ms ease;-webkit-backface-visibility:hidden;backface-visibility:hidden}
.dialogModal.open {opacity:1}
.dialogModal * {box-sizing:border-box}
.dialogModal .dialogModal_top {position:fixed;left:0;right:0;z-index:2;opacity:0;transform:translateZ(0);-webkit-backface-visibility:hidden;backface-visibility:hidden}
.dialogModal .dialogModal_top .dialogModal_header {padding:15px 25px;line-height:30px;height:60px;font-size:120%;margin:auto;width:650px;background:#fff;border-bottom:1px solid #f5f5f5;position:relative}
.dialogModal .dialogModal_top .dialogModal_header .close {line-height:30px;font-size:40px;font-family:serif;color:#000;opacity:0.2;padding:0;cursor:pointer;background:transparent;border:0;outline:0;float:right}
.dialogModal .dialogModal_top .dialogModal_header .close:hover {opacity: 1
}

.dialogModal .dialogModal_top .dialogModal_header .dialogPrev.notactive,
.dialogModal .dialogModal_top .dialogModal_header .dialogNext.notactive {
	opacity: 0.1 !important;
	cursor: default
}

.dialogModal .dialogModal_top .dialogModal_header .dialogPrev,
.dialogModal .dialogModal_top .dialogModal_header .dialogNext {
	line-height: 25px;
	font-size: 30px;
	color: #000000;
	opacity: 0.2;
	padding: 0 0 5px;
	cursor: pointer;
	background: transparent;
	border: 0;
	outline: 0;
	float: right;
	margin-right: 10px;
	width: 22px;
	text-align: center
}

.dialogModal .dialogModal_top .dialogModal_header .dialogPrev:hover,
.dialogModal .dialogModal_top .dialogModal_header .dialogNext:hover {
	opacity:1}
.dialogModal .dialogModal_top .dialogModal_header span {display:inline-block;overflow:hidden;white-space:nowrap;width:calc(100% - 90px);text-overflow:ellipsis}
.dialogModal .dialogModal_top .dialogModal_header:after {content: "";position:absolute;bottom:-11px;left:0;right:0;background:rgba(255,255,255,0.5);height:10px}
.dialogModal .dialogModal_body {position:relative;width:650px;left:0;right:0;margin:60px auto 40px;background:#fff;font-family:sans-serif;font-size:14px;font-weight:initial;font-style:initial;color:initial;text-decoration:initial;text-transform:initial;opacity:0;transform:translateZ(0);-webkit-backface-visibility:hidden;backface-visibility:hidden}
.dialogModal .dialogModal_body .dialogModal_header {display:none}
.dialogModal .dialogModal_content {padding:10px 25px 25px;line-height:20px}
.dialogModal .dialogModal_footer {background:#fafafa;padding:15px 25px;border-top:1px solid #ddd;text-align:right}
.dialogModal .dialogModal_footer button {margin:0 0 0 5px}
body.dialogModalOpen {overflow:hidden}
@media (max-width: 680px){
.dialogModal .dialogModal_top .dialogModal_header, .dialogModal .dialogModal_body {width:100%}
}


/* titleModal */
.titleModal {}
.titleModal_container {position:absolute;z-index:99999;background:#000;color:#fff;padding:3px 10px;line-height:16px;margin-top:12px;border-radius:4px;font-size:12px;text-align:center;max-width:260px;opacity:0;white-space:normal;font-family:sans-serif;font-weight:initial;font-style:initial;text-decoration:initial;text-transform:initial;transform:translateZ(0);-webkit-backface-visibility:hidden;backface-visibility:hidden}
.titleModal_container:after {content: "";position:absolute;border:6px solid transparent;border-top-width:0;border-bottom-color:#000;top:-6px;left:50%;margin-left:-6px}
.titleModal_container.top {margin-top:-6px}
.titleModal_container.top:after {border:6px solid transparent;border-bottom-width:0;border-top-color:#000;top:auto;bottom:-6px;left:50%;margin-left:-6px}
.titleModal_container.left {margin-top:auto;margin-left:3px}
.titleModal_container.left:after {border:6px solid transparent;border-right-width:0;border-left-color:#000;top:50%;left:auto;right:-6px;margin-left:0;margin-top:-6px}
.titleModal_container.right {margin-top:auto;margin-left:-3px}
.titleModal_container.right:after {border:6px solid transparent;border-left-width:0;border-right-color:#000;top:50%;left:0;margin-top:-6px}
.titleModal.light .titleModal_container {background:#fff;color:#000;border:1px solid #ccc}
.titleModal.light .titleModal_container:after {border-bottom-color:#fff}
.titleModal.light .titleModal_container:before {content: "";position:absolute;border:7px solid transparent;border-top-width:0;border-bottom-color:#ccc;top:-7px;left:50%;margin-left:-7px}
.titleModal.light .titleModal_container.top:after {border:6px solid transparent;border-bottom-width:0;border-top-color:#fff}
.titleModal.light .titleModal_container.top:before {border:6px solid transparent;border-bottom-width:0;border-top-color:#ccc;top:auto;bottom:-7px;left:50%;margin-left:-6px}
.titleModal.light .titleModal_container.left:after {border:6px solid transparent;border-right-width:0;border-left-color:#fff}
.titleModal.light .titleModal_container.left:before {border:7px solid transparent;border-right-width:0;border-left-color:#ccc;top:50%;left:auto;right:-7px;margin-left:0;margin-top:-7px}
.titleModal.light .titleModal_container.right:after {border:6px solid transparent;border-left-width:0;border-right-color:#fff}
.titleModal.light .titleModal_container.right:before {border:7px solid transparent;border-left-width:0;border-right-color:#ccc;top:50%;left:0;margin-top:-7px}



/* confirmModal */
.confirmModal {position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,0.7);z-index:888888;overflow:auto;opacity:0;transition:all 200ms ease}
.confirmModal.open {opacity:1}
.confirmModal * {box-sizing:border-box}
.confirmModal .confirmModal_body {position:relative;width:400px;left:0;right:0;margin:0 auto 40px;background:#fff;font-family:sans-serif;font-size:14px;font-weight:initial;font-style:initial;color:initial;text-decoration:initial;text-transform:initial;opacity:0;transform:translateZ(0);-webkit-backface-visibility:hidden;backface-visibility:hidden}
.confirmModal .confirmModal_content {padding:25px;line-height:20px}
.confirmModal .confirmModal_footer {background:#fafafa;padding:15px 25px;border-top:1px solid #ddd;text-align:right}
.confirmModal .confirmModal_footer button {margin:0 0 0 5px}
body.confirmModalOpen {overflow:hidden}
@media (max-width: 430px){
.confirmModal .confirmModal_body {width:100%}
}


/* animation */
.animated {-webkit-animation-duration:0.2s;animation-duration:0.2s;-webkit-animation-fill-mode:both;animation-fill-mode:both}
.fadeInLeft {-webkit-animation-name:fadeInLeft;animation-name:fadeInLeft}
.fadeOutLeft {-webkit-animation-name:fadeOutLeft;animation-name:fadeOutLeft}
.fadeInRight {-webkit-animation-name:fadeInRight;animation-name:fadeInRight}
.fadeOutRight {-webkit-animation-name:fadeOutRight;animation-name:fadeOutRight}
.fadeInTop {-webkit-animation-name:fadeInTop;animation-name:fadeInTop}
.fadeInTopBig {-webkit-animation-name:fadeInTopBig;animation-name:fadeInTopBig}
.fadeOutTop {-webkit-animation-name:fadeOutTop;animation-name:fadeOutTop}
.fadeInBottom {-webkit-animation-name:fadeInBottom;animation-name:fadeInBottom}
.fadeOutBottom {-webkit-animation-name:fadeOutBottom;animation-name:fadeOutBottom}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeOutLeft {
  100% {
    opacity: 0;
    -webkit-transform: translateX(-10px);
  }
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes fadeOutLeft {
  100% {
    opacity: 0;
    transform: translateX(-10px);
  }
  0% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeOutRight {
  100% {
    opacity: 0;
    -webkit-transform: translateX(10px);
  }
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes fadeOutRight {
  100% {
    opacity: 0;
    transform: translateX(10px);
  }
  0% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInTopBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInTopBig {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeOutTop {
  100% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
  }
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeOutTop {
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
  0% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeOutBottom {
  100% {
    opacity: 0;
    -webkit-transform: translateY(10px);
  }
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeOutBottom {
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
  0% {
    opacity: 1;
    transform: translateY(0);
  }
}