@import '../../utilities/lib';
@import '../../variableProvider.less';


.tooltip {
	border: 1px solid @TooltipBorderColor;
	background-color: @TooltipBackgroundColor;
	padding: 10px;
	border-radius: @DefaultBorderRadius;
	box-shadow: @MainNavigationShadow;
	max-width: 472px;
	&.informationBlock:not(.errorTooltip):not(.noNose) {
		&:before {
			content: "";
			height: 15px;
			display: block;
			position: absolute;
			width: 15px;
			border-right: 1px solid @TooltipBorderColor;
			border-bottom: 1px solid @TooltipBorderColor;
			background: @TooltipBackgroundColor !important;
		}
		&.topright{
			border-bottom-left-radius: 0;
		}
		&.topleft{
			border-bottom-right-radius: 0;
		}
		&.bottomleft{
			border-top-right-radius: 0;
		}
		&.bottomright{
			border-top-left-radius: 0;
		}
		&.topright:before {
			bottom: -9px;
			left: 3px;
			transform: rotate(45deg);
		}

		&.topleft:before {
			bottom: -9px;
			right: 3px;
			transform: rotate(45deg);
		}

		&.bottomleft:before {
			top: -9px;
			right: 3px;
			transform: rotate(225deg);
		}

		&.bottomright:before {
			top: -9px;
			left: 3px;
			transform: rotate(225deg);
		}
	}
	&.selectionTooltip {
		max-width: 600px;
	}
	
	ul {
		&.bas-unorderedList, &.bas-orderedList {
			padding: 10px 20px;
		}
	}
}

div.errorTooltip {
	max-width: 322px;
	display: flex !important;
	position: absolute;
	z-index: @Index_Tooltip !important;
	border-radius: @DefaultBorderRadius;
	border-bottom-left-radius: 0;
	
	.errorcontainer {
		display: inline-block;
		max-width: 260px;
		min-width: 50px;
		text-align: left;
		overflow: hidden;
		padding: 0 10px;
		margin-bottom: 0;
	    background-color: transparent;
	}
	
	.iconContainer {
		flex-shrink: 0;
		display: inline-block;
		width: 22px;
		height: 22px;
		border-radius: 100%;
		text-align: center;
		font-size: 20px;
		line-height: 22px;
		font-weight: bold;
	}
	
	&.tt_hasWarnings, &.tt_hasErrors {
		.errorcontainer {
			>div {
				&:first-child:not(:last-child) {
					margin-bottom: 5px;
				}
				&:not(:first-child) {
					margin-top: 10px;
				}
				&:not(:last-child) {
					padding-bottom: 10px;
				}
			}
		}
	}
	
	&.tt_hasWarnings {
		.errorcontainer {
			color: @MediumGrey1;
			>div:not(:last-child) {
				border-bottom: 1px solid @MediumGrey1;
			}
		}
		.iconContainer {
			border: 1px solid @MediumGrey1;
			color: @MediumGrey1;
		}
	}
	&.tt_hasErrors {
		border: 1px solid @Color_Error;
		
		.errorcontainer {
			>div:not(:last-child) {
				border-bottom: 1px solid @Color_Error;
			}
			color: @Color_Error;
		}
		.iconContainer {
			border: 1px solid @Color_Error;
			color: @Color_Error;
		}
	}
}