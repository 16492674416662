.ym-col1{
	float:right;
	width:20%;
}
.ym-col3{
	margin:0;
	margin-right:20%;
}


