@import "../utilities/lib.less";

.bas-formSection {
	&.ym-grid {
		width: auto;
	}

	margin-bottom: 2px;
	padding: 5px 4px;

	> .bas-formSection {
		padding: 0;
		display: table;

		&.hide {
			display: none;
		}

		width: 100%;
	}

	.bas-formSection .bas-formSection {
		padding: 0;
	}

	.bas-formBox {
		min-height: 21px;

		&.noLabel {
			background: none;
		}

		header {
			font-size: @FontSizeHeadline;
			margin: 3px 0 6px;
			vertical-align: middle;
		}

		.item.vertical .itemBox > .bas-inputButton {
			margin-top: 1px;
			margin-bottom: 1px;
		}
	}

	.item {
		vertical-align: top;
	}

	.bas-label {
		width: @FieldLabelWidth;
		min-width: @FieldLabelWidth;
		padding: 4px 5px;
	}
}

.ym-grid.ym-equalize {
	margin-bottom: 2px;
}

#editMaskContainer .groupSeparator {
	display: block;
	padding: 4px 5px;
}

.inputSeparator, .inputSeparator-nomargin {
	background: url("../../img/dotted.png") repeat-x scroll left top transparent;
	height: 1px;
	border: none;
}

.inputSeparator {
	margin: 7px;
}

.inputSeparator-nomargin {
	margin: 0;
}

.bas-inputArea {
	padding-left: 9px;
	margin-left: @FieldLabelWidth;

	&.horizontal > * {
		float: left;
		margin-right: 2px;
	}

	.bas-inputCheck > * {
		float: none;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	span {
		line-height: calc(30 / 16);
		margin: 0 5px 3px 0;
	}

	.inlineckeditor {
		span {
			margin: unset;
		}
	}
}

div.checkboxArray {
	display: inline-block;
}

.nofilterresult > .bas-formSection {
	padding-top: 20px;
}


