@import '../utilities/lib.less';

.img-button.detail {
	border-color: @LightGrey1;
	
	&:hover{
		border-color: @LightBlue_2021;
	}
	&.selected {
		border-color: @LightBlue_2021;

		.description {
			display: inline-block;
			min-height: 60px;
			color: @LightGrey_2021;
		}
	}
	
	.preview {
		img {
			background: @ButtonPressedColor;
			border-color: @LightGrey1;
		}
	}
	
	.description {
		display: inline-block;
		min-height: 60px;
		color: @ButtonPressedColor;
	}
}