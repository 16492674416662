@import '../utilities/lib';
div.mySet {
	border: 1px solid @MediumGrey1;
	background-color: @White;
	width: auto;
	max-height: 250px;
	height: 250px;
	overflow: auto;
	div.row {
		border-top: 1px solid @LightGrey1;
		div.col {
			padding: 0;
			border: 0;
			vertical-align: middle;
			&.text {
				width: 100%;
			}
			&.last:first-child {
				padding: 14px 0 12px 7px;
			}

			.icon {
				display: block;
				margin: 14px 0 12px 7px;
				background-position: 0 0;
				&.expand_small , &.collapse_small {
					display: inline;
					padding: 0 25px 5px 0;
				}
			}

			span {
				float: none;
				&.important {
					display: block;
				}

				&.bold {
					margin-bottom: 3px;
				}
			}

			div.tooltipTrigger {
				float: right;
				margin: 8px 14px 0 0;
			}
		}

		div.last {
			padding-left: 21px;
			width: 100%;
		}
	}

	div.tabulation > .row:last-child {
		border-bottom: 1px solid @LightGrey1;
	}

	div.row.childrow , div.tabulation > .row:first-child {
		border-top: none;
	}

	div.floatingContainer {
		float: left;
	}
}

* + html div.mySet div.row {
	div.last {
		width: auto;
		height: 100%;
		> span {
			line-height: 40px;
		}
	}

	div.col {
		div.tooltipTrigger , div.floatingContainer {
			margin-top: 7px;
		}
	}
}

