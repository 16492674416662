@import '../../utilities/lib.less';

.ym-grid.layoutGrid {
	display: flex;
	column-gap: 20px;
}

.kampagne-title-line .bas-inputArea {
	h1 {
		float: left;
	}

	.kampagnen-buttons {
		float: right;
	}
}


.statusLine {
	min-height: 16px;
}

.bas-formBox.layout-66-33,
.bas-formBox.layout-80-20 {
	display: flex;

	.bas-label {
		padding: 0;
	}

	div.bas-inputArea {
		margin-left: 0;

		.flex.budget-container {
			width: 100%;
		}
	}

	label {
		display: flex;
		justify-content: space-between;

		&[for]:hover {
			cursor: pointer;
		}
	}
}

.bas-formBox.layout-66-33-reverse-combobox {
	.bas-inputArea {
		width: 100%;
		margin-left: 0;

		.bas-inputCheck {
			display: flex !important;
			flex-direction: row-reverse;
			justify-content: flex-end;
			margin-right: 0;

			label {
				width: 66%;
			}

			div.checkbox {
				padding-right: 0;
			}
		}
	}
}

.bas-formBox.layout-66-33 {
	.bas-label {
		width: 66%;
	}

	div.bas-inputArea {
		width: 33%;
	}
}

.bas-formBox.layout-80-20 {
	.bas-label {
		width: 80%;
	}

	div.bas-inputArea {
		width: 20%;
	}
}

.wmDialog,
.floatingPageHeader {
	div.bas-inputCheck {
		.fieldsavedicon {
			position: unset;
		}
	}

	.fieldsavedicon {
		position: absolute;
		display: inline-block;
		background: #fff;

		padding-left: 5px;
		padding-right: 4px;
		border-top: 0;
		border-left: 0;
		min-width: 34px;
		min-height: 20px;
		text-align: left;

		div {
			background-image: url("../../../img/icons/marketingplaner/icon_autosaved.png");
			width: 24px;
			min-width: 24px;
			height: 15px;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
	}
}

.werbemittel-container {
	margin: 5px;
	width: ~"calc(50% - 14px)";
	display: inline-block;
	vertical-align: top;

	.bas-inputArea {
		width: auto;
	}

	.content-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
}

div.wmDialog.dialogWrapper {
	background: #fff;

	.marginContainer {
		height: auto;

		.chosen-container {
			position: relative;
		}
	}

	input[type=text],
	textarea {
		width: 100%;
	}
}

div.dialog div.dialogWrapper.wmDialog .dialogHeader {
	h3 {
		line-height: 26px;
		padding: 5px;
		margin: 0;
	}

	.closeDialog {
		display: none;
	}
}


.wmDialog .dialogContainer {
	padding: 0 20px 0 20px;
	margin-bottom: 20px;

	.teilnahmeElement {
		margin-top: 25px;
	}

	.werbemittel-bestelllink {
		margin-top: 30px;
	}

	.werbemittel-erscheinungstermine {
		margin-top: 5px;
	}

	.fill {
		max-width: 100%;
		width: 100%;
	}

	[name*=selectedWerbemittelUsers] {
		width: 100%;
	}

	.werbemittel-vorschaubild {
		height: 100%;
		margin-left: 20px;

		>.dummy {
			background-color: #d0d0d0;
			padding: 100px 10px;
			text-align: center;
			display: inline-block;
			margin: auto;
		}
	}

	.gruppenMitglieder .horizontal.last-right {
		line-height: 25px;

		.itemBox,
		label {
			line-height: 25px;
		}
	}
}

.werbemittel-container,
.dialogWrapper {
	position: relative;

	.werbemittel-title,
	.dialogHeader h3 {
		font-weight: bold;
		flex-grow: 99;
		margin: auto;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.werbemittel-footer {
		min-height: 35px;

	}

	.werbemittel-header {
		display: flex;
		padding: 5px;
		flex-direction: row;
		flex-wrap: nowrap;

		.werbemittel-buttons {
			align-self: baseline;
			display: flex;

			:last-child {
				padding-right: 0;
				margin-right: 0;
			}
		}
	}

	.werbemittel-description {
		padding: 0 5px;
		flex-grow: 99;

		span.truncate {
			display: inline-block;
			max-height: 50px;
			overflow: hidden;

			&:before {
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				background: linear-gradient(transparent 30px, white);
			}
		}
	}

	.werbemittel-additionalFiles {
		padding: 0 5px;
	}

	.werbemittel-zeiten,
	.werbemittel-werbeform {
		flex-grow: 99;
	}

	.werbemittel-section {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		padding: 0 5px 5px 5px;

		&:first-child {
			padding-top: 5px;
			padding-bottom: 5px;
		}

		&.alignItemsCenter {
			align-items: center;
		}

		.status-icon {
			width: 15px;
			min-width: 15px;
			height: 15px;
			margin-left: 5px;
			margin-right: 5px;
			background-size: cover;

			&.done {
				background-image: url("../../../img/icons/marketingplaner/icon_check.png");
			}

			&.gespeichert {
				background-image: url("../../../img/icon_manually_saved.png");
			}

			&.budget {
				background-image: url("../../../img/icons/marketingplaner/icon_budget.png");
			}

			&.no_budget {
				background-image: url("../../../img/icons/marketingplaner/icon_no_budget.png");
			}

			&.last-child {
				padding-right: 0;
				margin-right: 0;
			}
		}

		svg#changed_by_user {
			width: 20px;
		}

		&.budget {
			background-image: url("../../../img/icons/marketingplaner/icon_budget.png");
		}

		&.no_budget {
			background-image: url("../../../img/icons/marketingplaner/icon_no_budget.png");
		}

		.werbemittel-planen {
			margin-right: 20px;
			align-self: flex-end;
		}

		.werbemittel-bestelllink {
			align-self: flex-end;
		}

		.werbemittel-item .bas-inputArea>.item.horizontal>.itemBox>.item.horizontal>.itemBox {
			padding-right: 5px;

			&:last-child {
				padding-right: 0;
			}
		}
	}

	.werbemittel-budget {
		&>span {
			display: none;
		}

		&.itemBox {
			white-space: nowrap;
		}
	}

	.werbemittel-item {
		display: flex;
		flex-direction: column;
		flex-grow: 99;

		&.infoSpalte {
			width: 500px;
		}

		&.bildSpalte {
			max-width: 300px;
		}

		&.fill {
			flex-grow: 99;
		}
	}

	.teilnehmerSelect {
		margin-bottom: 25px;
	}

	.werbemittel-image {
		min-width: 350px;
	}
}