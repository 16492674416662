@import "../utilities/lib.less";

@imgPathInputMask: "../../img/inputmask";
.overviewArea, .overviewImage {
	position: relative;
}

#editMask {
	&.newsletter {
		.overlay {
			position: absolute
		}
	}

	&.ym-equalize {
		overflow: auto;

		> [class *= "ym-g"] > [class *= "ym-gbox"] {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}

	.newslettertm object#preview {
		&.preview_small {
			width: 750px;
		}

		&.mobile {
			width: 350px;
		}
	}
}

.ym-equalize.choosepicture {
	overflow: auto;

	> [class *= "ym-g"] > [class *= "ym-gbox"] {
		margin-bottom: 0;
		padding-bottom: 0;
	}
}

.overlay {
	.overviewImage, .overviewArea {
		.bas-box-sizing (border-box);
	}

	.overviewArea {
		background-color: transparent;
		margin: 0;
		padding: 40px 40px 80px;

		.image {
			display: inline-block;
			position: absolute;

			img {
				max-width: none;
			}
		}

		.overviewImage {
			text-align: center;
		}
	}

	.close {
		position: absolute;
		top: 0;
		right: 0;
		z-index: @Index_OverlayClose;
	}

	.overviewButtons {
		position: fixed;
		bottom: 20px;
		box-shadow: 0 0 5px #666666;
		background-color: #eeeeee;
		padding: 5px 1px 5px 5px;
		height: 34px;
		border-radius: 3px;
	}

	.overviewImage {
		background: transparent;
	}

	.multiPageSelect {
		width: 180px;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border-right: 1px solid #ffffff;

		.imageContainer {
			margin: 7px 14px;
			text-align: center;

			img {
				border: 2px solid #dddddd;
			}
		}

		.imageContainer:hover {
			cursor: pointer;

			img {
				border-color: red;
			}
		}
	}

	div[id ^= "caret:"] {
		display: none ! important;
	}
}

.overviewArea {
	background-color: #ffffff;

	.overlay {
		position: relative;
	}

	.bas-inputText {
		display: inline-block;
		margin: 0 4px 0 0;
		vertical-align: top;
	}

	input[type=text] {
		width: 51px;
		text-align: center;
	}

	&.hasMultiPagePreview {
		.overviewButtons {
			margin-left: 85px;
		}

		.overviewImage {
			margin-left: 175px;
		}
	}
}

.superView {
	.overviewArea {
		.overlay {
			position: fixed;
		}
	}
}

.overviewImage {
	background: #ffffff;
	border: 0 none;
	overflow: hidden;

	&.standard, &.pos, &.newsletter, &.newslettertm, &.some {
		height: 400px;
	}

	&.some {
		.image {
			display: flex;
			flex-direction: column;

			.videoNotReadyHint {
				width: 100%;
			}

			iframe {
				width: 100%;
				height: 100%;
			}
		}
	}

	&.newsletter, &.newslettertm {
		cursor: move;

		.preview-highlight {
			cursor: pointer;
		}
	}

	.overlay {
		z-index: @Index_OverviewImageOverlay;
	}

	.image #overview {
		visibility: hidden;
	}
}

.overviewButtons {
	background: #cfd0d2;
	border: solid 1px #4d4d4d;
	border-top: solid 1px #cfd0d2;
	padding: 5px;

	&.aligncenter {
		text-align: center;
	}

	&.alignleft {
		text-align: left;
	}

	&.alignright {
		text-align: right;
	}
}

div.close {
	width: 16px;
	height: 16px;
	cursor: pointer;
}

.multiPagePreviewContainer {
	width: 162px;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
}

div.overviewButton {
	width: 20px;
	height: 20px;
	min-height: 20px;
	min-width: 20px;
	display: inline-block;
}

div.reload {
	background: url("@{imgPathInputMask}/button-redo.gif") no-repeat left center;
}

div.zoomIn {
	background: url("@{imgPathInputMask}/button_zoomin.gif") no-repeat left center;
}

div.zoomOut {
	background: url("@{imgPathInputMask}/button_zoomout.gif") no-repeat top left;
}

div.zoomDynamic {
	background: url("@{imgPathInputMask}/button_zoomdynamic_off.gif") no-repeat top left;

	&.active {
		background: url("@{imgPathInputMask}/button_zoomdynamic_on.gif") no-repeat top left;
	}
}

div.scaleX {
	background: url("@{imgPathInputMask}/button_scalex.gif") no-repeat top left;
}

div.scaleY {
	background: url("@{imgPathInputMask}/button_scaley.gif") no-repeat top left;
}

div.allrectangles {
	background: url("@{imgPathInputMask}/button_allrectangles_off.gif") no-repeat top left;

	&.active {
		background: url("@{imgPathInputMask}/button_allrectangles_on.gif") no-repeat top left;
	}
}

div.lock {
	background: url("@{imgPathInputMask}/button_lock_off.gif") no-repeat top left;
}

div.pdfsuperview {
	background: url("@{imgPathInputMask}/button_pdfsuperview.gif") no-repeat top left;

	&.active {
		background: url("@{imgPathInputMask}/button_pdfsuperview_over.gif") no-repeat top left;
	}
}

div.pdfsuperview_currentpage {
	background: url("@{imgPathInputMask}/button_pdfsuperview_currentpage.gif") no-repeat top left;
}

div.pdfsuperview_all {
	background: url("@{imgPathInputMask}/button_pdfsuperview_all.gif") no-repeat top left;
}

div.reset {
	background: url("@{imgPathInputMask}/button_reset.gif") no-repeat top left;
}

#editMaskContainer {
	section.bas-formSection {
		div.bas-label {
			display: block;
			float: none;
			width: auto;
		}

		div.bas-inputArea {
			display: block;
			float: none;
			width: auto;
			margin-left: 0;
			padding-left: 0;
		}

		.item.vertical > .item.horizontal {
			clear: left;
		}
	}

	div.bas-formBox {
		background: none;
	}

	input[type=text], select, textarea, .inlineckeditor {
		max-width: 100%;
	}

	input[type=text], textarea, .inlineckeditor {
		&:not([class*="cols"]) {
			width: 100% ! important;
		}
	}

	.item.horizontal {
		input[type=text][size], select.combo, .inlineckeditor {
			width: auto ! important;
		}
	}

	input[type=text][size], textarea[cols], .inlineckeditor {
		width: auto ! important;
	}

	input.readonly[type="text"], textarea.readonly {
		background: @InputInactiveColor;
		color: @InputFontInactiveColor;
	}

	.imgButton {
		cursor: pointer;

		&:not(.imgButton_container) {
			border: 2px solid transparent;
			margin-right: 2px;

			&:hover {
				border-color: #000000;
			}

			&.selected {
				border: 2px solid #000000;

				&[data-disabled]:hover {
					border: 2px solid #000000;
				}
			}

			&[data-disabled] {
				&:hover {
					border-color: transparent;
				}
			}
		}

		&[data-disabled] {
			cursor: default;
		}

		&.selected {
			cursor: default;
		}
	}

	.item.vertical {
		.item.horizontal > .itemBox {
			margin: 0;
		}
	}
}

.imgButton_container {
	line-height: normal;
	font-size: 0;
	padding: 0;
	margin: 0;
	margin-right: 9px;
	margin-bottom: 15px;
	position: relative;
	cursor: pointer;
	display: inline-block;

	&, &.selected {
		&, &:hover {
			border: 0 none;
		}
	}

	.isSelected {
		position: absolute;
		width: calc(33% + 4px);
		max-width: 29px;
		min-width: 19px;
		border-radius: 50%;
		bottom: 0;
		right: 5px;
		border: 2px solid transparent;
		.translate (40%, 40%);
	}
}

.ie .imgButton_container .isSelected svg {
	height: calc(33% + 4px);
	max-height: 29px;
	min-height: 19px;
}

#overview {
	max-width: none;
}

object {
	text-align: center;
}

object#overview {
	cursor: move;
	display: none;
}

embed#overview.hidden {
	display: none;
}

embed#overview {
	display: block;
}

object#overview {
	display: block;
}

object#overview {
	display: flex;
	justify-content: center;
	align-items: center;
}

#editMask {
	div.image {
		position: absolute;
	}

	input[type=text][size] {
		width: auto ! important;
	}
}

div.dynamicZoomSelection {
	background-image: url("../../img/transparent.png");
	border: 2px solid red;
	border-image: url("../../img/border.gif") 2 repeat;
	width: 1px;
	height: 1px;
	z-index: @Index_EditZoomSelection;
	position: absolute;
}

div.dynamicZoomWrapper {
	background: transparent;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	cursor: crosshair;
}

div.dragWrapper {
	background: transparent;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
}

.overlayhorizontal {
	border-top: 1px solid transparent;
}

.overlayvertical {
	border-left: 1px solid transparent;
}

div[id ^= caret] {
	background-image: url("../../img/transparent.png");

	> div {
		border-width: 2px;
	}

	&.active {
		border-color: @RectangleColor;

		> div {
			border-color: @RectangleColor;
		}

		&.focus, &:hover {
			> div {
				border-color: @RectangleHighlightColor;
			}
		}
	}

	&.inactive {
		> div {
			border-color: transparent;
		}

		&:hover > div {
			border-color: @RectangleHighlightColor;
		}
	}

	&.rotated {
		&.inactive {
			> div > div {
				background-color: transparent ! important;
			}

			&:hover > div > div {
				background-color: @RectangleHighlightColor ! important;
			}
		}

		&.active, &:hover, &.focus {
			> div > div {
				background-color: @RectangleColor ! important;
			}
		}
	}
}

hr {
	&.v2ughrsolid, &.v2ughrdashed, &.v2ughrdotted {
		border-color: #000000;
	}

	&.v2ughrsolid {
		border-style: solid;
	}

	&.v2ughrdashed {
		border-style: dashed;
	}

	&.v2ughrdotted {
		border-style: dotted;
	}
}

.edit-external-graphic-preview {
	max-width: 300px;
	max-height: 80px;
	box-shadow: 0 0 5px #666666;
}

.picturePreview .infotext {
	display: inline-block;
}

.webtm #editMaskContainer .bas-formSection .item.horizontal.ym-clearfix.webtm-iconContainer {
	display: none;
}

.choosepicture .bas-formBox, .choosepicture .field {
	max-width: 425px;
}

.item.horizontal:has(> .itemBox > .imgButton) {
	flex-wrap: nowrap;
}

.itemBox:has(> .imgButton) {
	display: contents !important;
}
