@import '../utilities/lib.less';
.bas-inputButton {
	.button {
		border-color: transparent;
		&.withOptions {
			text-align: left;
			width: auto;
			& , &:hover , &:active , &:focus {
				outline: 0;
			}
		}
	}

	&.withOptions .elementTrigger.tooltipTrigger {
		float: left;
	}

	.optionTrigger {
		position: relative;
		.hoverContainer {
			display: none;
			left: -12px;
			position: absolute;
			.buttonOptions {
				display: none;
			}
		}

		&:hover .hoverContainer {
			display: block;
			padding-bottom: 20px;
			padding-left: 10px;
			.buttonOptions {
				position: relative;
				display: block;
				z-index: @Index_Overlay - 1;
				cursor: pointer;
				&:before , &:after {
					position: absolute;
					content: '';
					z-index: @Index_Overlay - 1;
				}

				.icon.buttonOption {
					display: flex;
					text-align: left;
					outline: 0;
					margin: 0;
					width: 100%;
					white-space: nowrap;
					height: unset;
					.iconContainer {
						float: left;
					}
				}
			}
		}
	}
}

input[type="text"].autowidth , input[type="password"].autowidth , select.autowidth , textarea.autowidth {
	width: auto;
	max-width: 100%;
}

.bas-inputButton.dummy .button {
	cursor: default;
}
a.button.autowidth {
	width: auto !important;
}
.loginformessage{
	margin-bottom: 1em;
}
