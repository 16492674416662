@import '../../utilities/lib.less';
.selectionGroup {
	.selection input {
		+ label.selectionArea , &:disabled:checked + label.selectionArea {
			&.dbImage, &.uploadImage, &.defaultImage, &.noImage {
				width: 100px;
				height: 78px;
			}
		}
	}
}