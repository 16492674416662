@import '../utilities/lib';
#header {
	height: 75px;
	background: url("../../img/header.png") repeat-x;
	padding-top: 19px;
	.ym-wrapper {
		padding-bottom: 0;
	}

	.ym-wbox {
		display: table;
		table-layout: fixed;
		width: 100%;
		position: relative;
	}
}

#logo {
	padding-right: 23px;
	width: 179px;
	float: left;
}

div.headerSubContainer {
	float: right;
	min-width: 440px;
	position: absolute;
	right: 0;
	bottom: 2px;
}

div.applicationName {
	position: absolute;
	font-size: 1.2em;
	color: @Blue;
	margin-top: -3px;
	right: 0;
	top: 0;
}


