.bas-gradient (@startColor: #000; @endColor: #fff) {
	background: @startColor;
	background: -moz-linear-gradient(top, @startColor 0%, @endColor 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @startColor), color-stop(100%, @endColor));
	background: -webkit-linear-gradient(top, @startColor 0%, @endColor 100%);
	background: -o-linear-gradient(top, @startColor 0%, @endColor 100%);
	background: -ms-linear-gradient(top, @startColor 0%, @endColor 100%);
	background: linear-gradient(to bottom, @startColor 0%, @endColor 100%);
	filter: ~"progid:DXImageTransform.Microsoft.gradient( startColorstr='@{startColor}', endColorstr='@{endColor}',GradientType=0 )";
}

.bas-gradient-with-image (@startColor: #000;
@endColor: #fff; @imageURL; @imageRepeat; @imagePosX; @imagePosY) {
	background: @startColor url("@{imageURL}") @imageRepeat @imagePosX @imagePosY;
	background: url("@{imageURL}") @imageRepeat @imagePosX @imagePosY, -moz-linear-gradient(top, @startColor 0%, @endColor 100%);
	background: url("@{imageURL}") @imageRepeat @imagePosX @imagePosY, -webkit-gradient(linear, left top, left bottom, color-stop(0%, @startColor), color-stop(100%, @endColor));
	background: url("@{imageURL}") @imageRepeat @imagePosX @imagePosY, -webkit-linear-gradient(top, @startColor 0%, @endColor 100%);
	background: url("@{imageURL}") @imageRepeat @imagePosX @imagePosY, -o-linear-gradient(top, @startColor 0%, @endColor 100%);
	background: url("@{imageURL}") @imageRepeat @imagePosX @imagePosY, -ms-linear-gradient(top, @startColor 0%, @endColor 100%);
	background: url("@{imageURL}") @imageRepeat @imagePosX @imagePosY, linear-gradient(to bottom, @startColor 0%, @endColor 100%);
	filter: ~"progid:DXImageTransform.Microsoft.gradient( startColorstr='@{startColor}', endColorstr='@{endColor}',GradientType=0 )";
}

.bas-gradient-with-image-l2r (@startColor: #000;
@endColor: #fff; @imageURL; @imageRepeat; @imagePosX; @imagePosY) {
	background: @startColor url("@{imageURL}") @imageRepeat @imagePosX @imagePosY;
	background: url("@{imageURL}") @imageRepeat @imagePosX @imagePosY, -moz-linear-gradient(left, @startColor 0%, @endColor 100%);
	background: url("@{imageURL}") @imageRepeat @imagePosX @imagePosY, -webkit-gradient(linear, left top, right top, color-stop(0%, @startColor), color-stop(100%, @endColor));
	background: url("@{imageURL}") @imageRepeat @imagePosX @imagePosY, -webkit-linear-gradient(left, @startColor 0%, @endColor 100%);
	background: url("@{imageURL}") @imageRepeat @imagePosX @imagePosY, -o-linear-gradient(left, @startColor 0%, @endColor 100%);
	background: url("@{imageURL}") @imageRepeat @imagePosX @imagePosY, -ms-linear-gradient(left, @startColor 0%, @endColor 100%);
	background: url("@{imageURL}") @imageRepeat @imagePosX @imagePosY, linear-gradient(to right, @startColor 0%, @endColor 100%);
	filter: ~"progid:DXImageTransform.Microsoft.gradient( startColorstr='@{startColor}', endColorstr='@{endColor}',GradientType=0 )";
}

.bas-gradient-l2r (@startColor: #000;
@endColor: #fff) {
	background: @startColor;
	background: -moz-linear-gradient(to right, @startColor 0%, @endColor 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%, @startColor), color-stop(100%, @endColor));
	background: -webkit-linear-gradient(to right, @startColor 0%, @endColor 100%);
	background: -o-linear-gradient(to right, @startColor 0%, @endColor 100%);
	background: -ms-linear-gradient(to right, @startColor 0%, @endColor 100%);
	background: linear-gradient(to right, @startColor 0%, @endColor 100%);
	filter: ~"progid:DXImageTransform.Microsoft.gradient(startColorstr='@{startColor}', endColorstr='@{endColor}', GradientType=1)";
}

.bas-gradient-noFilter (@startColor: #000;
@endColor: #fff) {
	background: @startColor;
	background: -moz-linear-gradient(top, @startColor 0%, @endColor 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @startColor), color-stop(100%, @endColor));
	background: -webkit-linear-gradient(top, @startColor 0%, @endColor 100%);
	background: -o-linear-gradient(top, @startColor 0%, @endColor 100%);
	background: -ms-linear-gradient(top, @startColor 0%, @endColor 100%);
	background: linear-gradient(to bottom, @startColor 0%, @endColor 100%);
}

.bas-box-sizing (@sizing) {
	-webkit-box-sizing: @sizing;
	-moz-box-sizing: @sizing;
	-ms-box-sizing: @sizing;
	box-sizing: @sizing;
}

.triangle (@Color_Top: transparent , @Color_Right: #000 , @Color_Bottom: #000 , @Color_Left: transparent , @Triangle_Size: 10px) {
	width: 0;
	height: 0;
	display: block;
	border-style: solid;
	border-top-color: @Color_Top;
	border-right-color: @Color_Right;
	border-bottom-color: @Color_Bottom;
	border-left-color: @Color_Left;
	border-width: @Triangle_Size;
}

.iconContainerSizeAndPosition (@ImageWidth: 16px , @ImageHeight: 16px , @BackgroundPosition: 0 0) {
	width: @ImageWidth;
	height: @ImageHeight;
	background-position: @BackgroundPosition;
}

.rotate (@Degree: 45deg) {
	-moz-transform: rotate(@Degree);
	-ms-transform: rotate(@Degree);
	-o-transform: rotate(@Degree);
	-webkit-transform: rotate(@Degree);
	transform: rotate(@Degree);
}

.rotate90 () {
	-moz-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=-1.8369701961596905e-16, M12=-1, M21=1, M22=-1.8369701961596905e-16, SizingMethod='auto expand')";
}

.rotate180 () {
	-moz-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
	-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=-1, M12=1.224646798818428e-16, M21=-1.224646798818428e-16, M22=-1, SizingMethod='auto expand')";
}

.rotate270 () {
	-moz-transform: rotate(270deg);
	-webkit-transform: rotate(270deg);
	-o-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
	-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0, M12=1, M21=-1, M22=0, SizingMethod='auto expand')";
}

.FontSize_PointToPixel(@Point) {
	font-size: ((@Point * 72) / 96)px;
}

.LineHeight_PointToPixel(@Point) {
	line-height: ((@Point * 72) / 96)px;
}

.transformScale(@Faktor) {
	transform: scale(@Faktor);
	transform-origin: 50% 0;
	-webkit-transform: scale(@Faktor);
	-webkit-transform-origin: 50% 0;
	-ms-transform: scale(@Faktor);
	-ms-transform-origin: 50% 0;
}

.doubleStrike(@margin-left:0, @color:#000, @distance-top:40%, @distance-bottom:40%) {
	position: relative;
	&:after {
		content: ' ';
		font-size: inherit;
		display: block;
		position: absolute;
		margin-left: @margin-left;
		right: 0;
		left: 0;
		top: @distance-top;
		bottom: @distance-bottom;
		color: inherit;
		border-top: 1px solid @color;
		border-bottom: 1px solid @color;
	}
}

.center(){
	position:absolute;
	top:50%;
	left:50%;
	.translate(-50%,-50%);
}
.bottomRight(){
	position:absolute;
	top:100%;
	left:100%;
	.translate(-50%,-50%);
}
.v-whitespace-inner(@v-padding:0){
	padding-top: ~"@{v-padding}px";
	padding-bottom: ~"@{v-padding}px";
}
