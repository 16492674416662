@import "../utilities/lib.less";

@ForcedWarningColor: CanvasText;
@ForcedErrorColor: LinkText;

.chrome,
.firefox,
.ie {

	.hasErrors,
	.hasWarnings {
		&.select::after {
			right: 23px;
		}
	}
}

.ie {

	&.ie11,
	&.ie10 {

		.hasErrors,
		.hasWarnings {
			&.input.fileError::after {
				right: 123px;
			}
		}
	}
}

#editMask {

	div,
	table {

		&.hasErrors,
		&.hasWarnings {
			&.div.ckeditorError {
				display: inline-block;
				width: 100%;
			}

			&.verification-container {
				width: 100% !important;
			}
		}
	}
}

div,
table {

	&.hasErrors,
	&.hasWarnings {

		&::after {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 10px 0;
			border-color: transparent #9d9d9d transparent transparent;
			content: "";
			position: absolute;
			right: 0;
			top: 0;

			@media screen and (forced-colors: active) {
				forced-color-adjust: none;
			}
		}

		&.textarea {
			&.ckeditorError {
				&::after {
					right: -1px;
				}

				.cke {
					box-shadow: none;
					border-color: #9d9d9d
				}
			}

			&:not(.ckeditorError)::after {
				right: 1px;
				top: 1px;
			}
		}

		&.input {
			&.datepickerError {

				img.ui-datepicker-trigger,
				div.clockpicker-trigger {
					position: absolute;
					top: 0;
					right: 0;
					border-left: 0;

					&:hover {
						border-left: 0;
					}
				}

				&::after {
					right: 25px;
				}
			}

			&.checkboxError::after {
				right: 5px;
			}

			&::after {
				right: 1px;
				top: 1px;
			}
		}

		&.select::after {
			right: 25px;
			top: 1px;
		}

		&.section,
		&.div {
			&.item {

				&::after {
					visibility: visible;
				}
			}

			&.blockContainer,
			&.block {
				margin-left: 10px;
				padding-top: 5px;
				padding-bottom: 5px;

				> * {
					padding-left: 0;

					&:last-child {
						padding-bottom: 0;
					}
				}

				&::after {
					visibility: visible;
				}
			}
		}

		&.tabulation {
			display: table;
			outline: 1px solid #9d9d9d;
		}
	}
}

div,
table {
	&.hasErrors {

		input,
		input[type=text],
		select,
		textarea,
		svg {

			&,
			&:active,
			&:focus,
			&:hover {
				box-shadow: 0 0 4px 0 @ErrorWidget_ErrorColorBackground;
			}
		}

		input,
		input[type=text],
		select,
		textarea,
		svg,
		.inlineckeditor {

			&,
			&:active,
			&:focus,
			&:hover {
				border: 1px solid @ErrorWidget_ErrorColorBackground;
				outline: 0;

				@media screen and (forced-colors: active) {
					border-color: @ForcedErrorColor;
				}
			}
		}

		&.input.datepickerError {

			img.ui-datepicker-trigger,
			div.clockpicker-trigger {
				box-shadow: 1px 0 3px 0 @ErrorWidget_ErrorColorBackground;

				&,
				&:hover {
					border: 1px solid @ErrorWidget_ErrorColorBackground;

					@media screen and (forced-colors: active) {
						border-color: @ForcedErrorColor;
					}
				}
			}
		}

		&::after {
			border-color: transparent @ErrorWidget_ErrorColorBackground transparent transparent;

			@media screen and (forced-colors: active) {
				border-color: transparent @ForcedErrorColor transparent transparent;
			}
		}

		&.section,
		&.div,
		&.div.ckeditorError .cke,
		&.tabulation {
			box-shadow: 0 0 4px 0 @ErrorWidget_ErrorColorBackground;

			.chosen-container-single, .chosen-container-multi {
				.chosen-single, .chosen-choices {
					@media screen and (forced-colors: active) {
						border-color: @ForcedErrorColor;
					}
				}
			}
		}
	}

	&.hasWarnings {
		&.input.datepickerError {

			img.ui-datepicker-trigger,
			div.clockpicker-trigger {
				border: 1px solid @ErrorWidget_HintColorBackground;

				&:hover {
					border: 1px solid @ErrorWidget_HintColorBackground;
				}
			}
		}

		&.section,
		&.div,
		&.div.ckeditorError .cke,
		&.tabulation {
			box-shadow: 0 0 4px 1px @ErrorWidget_HintColor;
		}

		&::after {
			border-color: transparent @ErrorWidget_HintColor transparent transparent;

			@media screen and (forced-colors: active) {
				border-color: transparent @ForcedWarningColor transparent transparent;
			}
		}
	}
}

fieldset[bas-form-error]:not([ng-reflect-error-ref]) {
	&.hasErrors {

		&,
		&:active,
		&:focus,
		&:hover {
			box-shadow: 0 0 4px 0 @ErrorWidget_ErrorColorBackground;
			border: 1px solid @ErrorWidget_ErrorColorBackground;
			outline: 0;
		}

		&::after {
			width: 0;
			height: 0;
			border-style: solid;
			display: inline;
			border-width: 0 10px 10px 0;
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			border-color: transparent @ErrorWidget_ErrorColorBackground transparent transparent;
		}
	}
}