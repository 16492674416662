@import '../utilities/lib';
.ym-hlist ul li {
	position: static;
	ul {
		width: 803px;
		padding: .6em 0;
		position: absolute;
		top: 2.5em;
		left: -23px;
		display: block;
	}
}

