@import "../utilities/lib.less";

input[type="text"], input[type="password"], select, textarea, input[type="file"], .inlineckeditor {
	padding: 3px 5px;
}

input[type="file"] {
	padding-top: 2px;
}

input[type="text"], input[type="password"], input[type="file"], .bas-inputSelect > select, textarea, .inlineckeditor {
	border: 1px solid @MediumGrey6;
	width: @ColsInput3;
}

input.radio, input.checkbox {
	&[disabled] + label {
		color: @InactiveFontColor;
	}

	border: none;
}

input[type="radio"], input.radio {

	margin-right: 4px;
	vertical-align: top;
}

input, input[type="text"] {
	&.cols_tele_1 {
		width: 43px;
	}

	&.cols_tele_2 {
		width: 89px;
	}

	&.cols_tele_3 {
		width: 276px;
	}

	&.cols_tele_4 {
		width: 103px;
	}

	&.cols_tele_34 {
		width: 393px;
	}

	&.colsRegNr1 {
		width: 35px;
	}

	&.colsRegNr2 {
		width: 67px;
	}

	&.colsRegNr3, &.colsRegNr4 {
		width: 107px;
	}

	&.colsRegNr5 {
		width: 579px;
	}

	&.colsAda1 {
		width: 146px;
	}

	&.colsAda2, &.colsAda3 {
		width: 150px;
	}
}

textarea, input, select, bwc-date-picker {
	border-radius: @DefaultBorderRadius;

	&.colsQuarter {
		width: @ColsInputQuarter;
	}

	&.colsThird {
		width: @ColsInputThird;
	}

	&.colsHalf {
		width: @ColsInputHalf;
	}

	&.colsTwoThird {
		width: @ColsInputTwoThird;
	}

	&.cols1 {
		width: @ColsInput1;
	}

	&.cols2 {
		width: @ColsInput2;
	}

	&.cols3 {
		width: @ColsInput3;
	}

	&.cols4 {
		width: @ColsInput4;
	}

	&.cols5 {
		width: @ColsInput5;
	}

	&.cols6 {
		width: @ColsInput6;
	}
}

textarea, input, select {
	&[type=checkbox].lockcheckbox {
		visibility: hidden;
		position: absolute;
	}

	&[disabled] {
		color: @InactiveFontColor;
		background-color: @InactiveInputColor;
	}
}

.bas-inputArea {
	select, input {
		height: @DefaultHeight;
		border-radius: @DefaultBorderRadius;
	}

	input {
		&[type="radio"], &[type="checkbox"] {
			height: 14px;
			margin-top: 4px;
		}
	}

	select[size], select[multiple] {
		height: auto;
	}
}

.bas-inputSelect:focus-within .chosen-container {
	outline: @DefaultOutlineWidth @DefaultOutlineStyle @DefaultOutlineColor;

	+ .chosen-container:empty {
		outline: none;
	}
}

.treeNode {
	.bas-inputCheck, .bas-inputRadio {
		height: 30px;
	}
}

.bas-inputCheck, .bas-inputRadio {
	&:focus-within {
		outline: @DefaultOutlineWidth @DefaultOutlineStyle @DefaultOutlineColor;
	}
}

div.bas-inputText {
	&, &.hasErrors, &.hasWarnings {
		input[type="text"]:focus, textarea:focus {
			outline: @DefaultOutlineWidth @DefaultOutlineStyle @DefaultOutlineColor;
		}
	}
}

.item.linkGroup a.editPage:focus-visible {
	outline: @DefaultOutlineWidth @DefaultOutlineStyle @DefaultOutlineColor;
}

.bas-inputButton {
	a:focus-visible {
		outline: @DefaultOutlineWidth @DefaultOutlineStyle @DefaultOutlineColor;
	}

	display: inline-block;
}

div.cols1 .bas-inputButton {
	display: block;

	a {
		width: 89%;
		width: fill-available;
	}
}

.bas-label .bas-inputButton {
	display: block;
	min-width: 135px;
}

option[disabled], option.disable {
	color: @InactiveFontColor;
}

.bas-formSection.search select {
	:focus-visible {
		outline: @DefaultOutlineWidth @DefaultOutlineStyle @DefaultOutlineColor;
	}

	width: 55px;
	float: right;
}

.bas-inputCheck {
	margin-right: 5px;

	&.noPadding {
		margin-right: 0;

		input.noPadding {
			margin-right: 0;
		}
	}

	margin-bottom: 3px;

	&.bas-lockCheckbox {
		overflow: hidden;
		width: 25px !important;
		height: 25px !important;
		display: inline-block ! important;
	}

	div.item {
		display: inline-block;
	}

	label span {
		display: inline-block;
		line-height: 30px;
	}

	label {
		line-height: 30px;
	}
}

html.lt-ie9 {
	input[type="text"], input[type="password"], .inlineckeditor {
		line-height: 18px;
	}

	input[type="radio"], input.radio {
		padding-right: 4px;
	}
}

.ym-g50 .panel .bas-label {
	margin-right: 10px;
}

div.checkbox {
	&.lockcheckbox {
		background-image: url("../../img/btn/icon_unlock.svg");
		background-position: 0 0;
		height: 24px;
		width: 24px;
		padding-right: 0;
	}

	&.lockcheckbox, &.lockcheckboxunchecked {
		&:hover {
			background-image: url("../../img/btn/icon_unlock_hover.svg");
			cursor: pointer;
		}
	}
}

div.lockcheckbox {
	background-repeat: no-repeat;
	cursor: default;

	&.inactive:hover, &.inactive {
		background-image: url("../../img/btn/icon_unlock_inactive.svg");
	}

	&.inactive {
		&.checked, &.checked:hover {
			background-image: url("../../img/btn/icon_unlock_inactive.svg");
		}
	}

	&.lockcheckboxchecked, &.checked {
		background-image: url("../../img/btn/icon_lock.svg");

		&:hover {
			background-image: url("../../img/btn/icon_lock_hover.svg");
			cursor: pointer;
		}
	}
}

div.selection {
	position: relative;

	input.selectionInput {
		+ label.optionalcheckbox {
			height: 36px;
			width: 36px;
			opacity: 1;
			content: "";
			position: absolute;
			top: -4px;
			left: -14px;
			background-color: @White;
			background-repeat: no-repeat;
			background-image: url("../../img/icons/general/icon_partner_red.svg");

			&:hover {
				background-image: url("../../img/icons/general/icon_partner_red_hover.svg");
			}
		}

		&:checked + label.optionalcheckbox {
			background-image: url("../../img/icons/general/icon_partner_green.svg");

			&:hover {
				background-image: url("../../img/icons/general/icon_partner_green_hover.svg");
			}
		}

		&:disabled, &:disabled:checked {
			+ label {
				&, &:hover {
					background-image: url("../../img/icons/general/icon_partner_inactive.svg");
				}
			}
		}
	}
}

.ym-g50 .panel {
	input[type=text] {
		&.datepicker, &.clockpicker {
			&.cols1, &.cols2 {
				width: 178px;
			}
		}
	}

	input[type=text], select, textarea, .inlineckeditor {
		&.cols1, &.cols2 {
			width: 198px;
		}
	}
}

.jumpedAtElement:focus {
	-webkit-animation-duration: 1s;
	-webkit-animation-name: onFocusBlinking;
	animation-name: onFocusBlinking;
	animation-duration: 1s;
}

@keyframes onFocusBlinking {
	100% {
		background: white;
	}
	0% {
		background: @DarkBlue;
	}
}
