.switch {
	display: inline-block;
	position: relative;
	font-weight: 400;
	min-width: 3em;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(255,255,255,0);
	
	&::after {
		content: '.';
		display: inline-block;
		width: 0;
		visibility: hidden;
	}
	&:hover:not(.is-disabled) {
		.switch_label {
			color: #000;
		}
		.switch_track {
			border-color: #000;
		}
	}
	
	&.label_equal {
		display: flex;
		
		.switch_label {
			&.left {
				margin-right: 1em;
			}
			&.right {
				-ms-flex-order: 3;
				order: 3;
				color: rgba(0,0,0,.5);
				margin-left: 1em;
			}
		}
		
		.switch_track {
			position: relative;
			display: block;
			left: auto;
			-ms-flex: 0 0 3em;
			flex: 0 0 3em;
			-ms-flex-order: 2;
			order: 2;
			
			.switch_track_thumb {
				background: rgba(0,0,0,.8);
				transform: translate(-50%,-50%) scale(.5,.5);
			}
		}
	}
	
	.switch_label {
		display: inline-block;
		color: #333;
		transition: color .15s linear;
	}
	
	.switch_track {
		box-sizing: content-box;
		border: 1px solid rgba(0,0,0,.5);
		vertical-align: middle;
		transition: box-shadow .15s linear,border .15s linear;
		top: 0;
		left: 0;
		width: 3em;
		border-radius: .75em;
		
		&, .switch_track_thumb {
			position: absolute;
			height: 1.5em;
		}
		
		.switch_track_thumb {
			top: 50%;
			left: .75em;
			width: 1.5em;
			border-radius: 50%;
			transform: translate(-50%,-50%) scale(.5,.5);
			background: rgba(0,0,0,.1);
			transition: transform .15s cubic-bezier(.75,.02,.5,1),left .3s cubic-bezier(.75,.02,.5,1),background .3s cubic-bezier(.75,.02,.5,1);
		}
	}
	
	.checkbox {
		display: none;
	}
	
	input[type='checkbox']{
		visibility: hidden;
		position: absolute;
	
		&:checked {
			&~.switch_label.left {
				color: rgba(0,0,0,.5);
			}
			&~.switch_label.right {
				color: #333;
			}
			&~.switch_track {
				.switch_track_thumb {
					background: rgba(0,0,0,.8);
					transform: translate(-50%,-50%) scale(.5,.5);
					left: 2.125em;
				}
			}
		}
		&:focus~.switch_track {
			box-shadow: 0 0 3px 3px rgba(0,0,0,.1);
		}
	}
	
	&.label_left {
		.switch_label {
			padding-left: 0;
			padding-right: 4em;
		}
		
		.switch_track {
			left: auto;
			right: 0;
		}
		
		
	}
	.is-disabled {
		opacity: .3;
		cursor: default;
	}
}
