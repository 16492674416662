@import '../../utilities/lib.less';
.bas-form .button {
	width: 162px;
}

.button {
	.defaultButton ();
	&.service {
		.defaultButtonService ();
		&:active , &:hover , &:focus {
			.bas-gradient (@DarkGrey5 , @DarkGrey6);
		}

		&:visited {
			color: @White;
		}

		&#preferences , &#admin , &#slicelist, &#servicearchive, &#archive , &#dauerbestellungen , &#faq , &#hotline , &#video , &#shoppingcart {
			padding-left: 33px;
			&:before {
				position: absolute;
				top: 3px;
				left: 10px;
			}
		}

		&#preferences {
			&:before {
				content: url('../../../img/user_data.png');
			}
		}

		&#admin {
			&:before {
				content: url('../../../img/admin.png');
			}
		}

		&#slicelist {
			&:before {
				content: url('../../../img/documents.png');
			}
		}
		
		&#servicearchive {
			&:before {
				content: url('../../../img/documents.png');
			}
		}

		&#archive {
			&:before {
				content: url('../../../img/bookings.png');
			}
		}

		&#dauerbestellungen {
			&:before {
				content: url('../../../img/dauerbestellung.png');
			}
		}

		&#faq {
			&:before {
				content: url('../../../img/question_mark_white.png');
			}
		}

		&#hotline {
			&:before {
				content: url('../../../img/hotline.png');
			}
		}

		&#video {
			&:before {
				content: url('../../../img/video.png');
			}
		}

		&#shoppingcart {
			&:before {
				content: url('../../../img/shopping_cart.png');
			}
		}

		&#logout {
			.bas-gradient (@LightRed , @DarkRed);
			&:active , &:hover , &:focus {
				.bas-gradient (@DarkRed , @LightRed);
			}

			&:after {
				content: url('../../../img/logout.png');
				position: absolute;
				top: 2px;
				right: 8px;
			}
		}
	}

	&.service {
		img.buttonImg {
			float: left;
			margin-top: 3px;
			padding: 0 7px 0 0;
		}
	}

	&.content {
		color: @White;
		height: @DefaultButtonHeight;
		min-height: @DefaultButtonHeight;
		min-width: 135px;
		width: auto;
		line-height: @DefaultButtonHeight;
		padding: 0 10px 0 10px;
		text-decoration: none;
		text-align: center;
	}

	&.content {
		img {
			margin-right: 6px;
		}
	}

	&:hover , &:focus {
		.bas-gradient (@Blue , @LightBlue);
	}
}

.button , .button:active {
	.bas-gradient (@LightBlue , @Blue);
}

div.bas-inputButton {
	border-radius: @DefaultBorderRadius;
	background-color: @ButtonNormalColor;
	min-width: 135px;

	&:hover{
		background-color: @ButtonHoverColor;
	}

	&:active {
		background-color: @ButtonPressedColor;
	}

	&.inactive {
		background-color: @ButtonDisabledColor;
		cursor: default;
		.button {
			color: @DefaultButtonDisabledFontColor;
			cursor: default;
		}
	}

	&.highlight {
		background-color: @HighlightButtonNormalColor;
		&:hover {
			background-color: @HighlightButtonHoverColor;
		}
		&:active {
			background-color: @HighlightButtonPressedColor;
		}
		&.inactive {
			background-color: @HighlightButtonDisabledColor;
			cursor: default;
			.button {
				color: @DefaultButtonDisabledFontColor;
				cursor: default;
			}
		}
	}

	.button {
		filter: none;
		background: none;
	}

	&.withOptions {
		& , &:hover , &:active , &:focus {
			.bas-gradient (@MediumGrey2 , @DarkGrey6);
			& , .button.content {
				min-width: unset;
			}

			&.active {
				.bas-gradient (@LightOrange , @Orange);
			}

			.button.withOptions, .optionTrigger {
				float: left;
			}

			.button.withOptions {
				color: @White;
				padding: 0 0 0 10px;
			}

			.optionTrigger {
				height: 30px;
				width: 30px;
				margin-right: 2px;
				margin-left: 10px;
				padding: 4px 0 4px 4px;
				box-sizing: content-box;
				&:before {
					content: "";
					height: 80%;
					width: 0;
					border-right: 2px solid @White;
					position: absolute;
					top: 10%;
					left: 0;
				}

				> svg.preferences .cog , > svg.preferences_scheduled .cog {
					transition-duration: 0.8s;
					transition-property: transform;
					transform-origin: 57% 58%;
				}
	
				&:hover {
					svg.preferences .cog , svg.preferences_scheduled .cog {
						.rotate (360deg);
					}
				}

				.hoverContainer {
					padding-bottom: 20px;
					bottom: 25px;
					left: -4px;
					.buttonOptions {
						background-color: @White;
						border: 2px solid @Orange;
						padding: 2px 0 2px 2px;
						&:before , &:after {
							width: 0;
							height: 0;
							border-style: solid;
						}

						&:before {
							left: 0;
							bottom: -10px;
							border-width: 10px 10px 0 10px;
							border-color: @Orange transparent transparent transparent;
						}

						&:after {
							left: 2px;
							bottom: -7px;
							border-width: 8px 8px 0 8px;
							border-color: @White transparent transparent transparent;
						}

						.buttonOption.icon {
							margin-right: 2px;
							padding: 2px;
							color: @Blue;
							white-space: nowrap;
							&:hover {
								color: @DarkBlue;
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}
}

.bas-label .bas-inputButton {
	overflow: hidden;
}

html.lt-ie9 .ui-datepicker-trigger {
	min-width: 19px;
}