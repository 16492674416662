/**
 * "Yet Another Multicolumn Layout" - YAML CSS Framework
 *
 * (en) Uniform design of standard content elements
 * (de) Einheitliche Standardformatierungen für die wichtigten Inhalts-Elemente
 *
 * @copyright       © 2005-2013, Dirk Jesse
 * @license         CC-BY 2.0 (http://creativecommons.org/licenses/by/2.0/),
 *                  YAML-CDL (http://www.yaml.de/license.html)
 * @link            http://www.yaml.de
 * @package         yaml
 * @version         4.1.2
 */
@media all {

  /**
  * (en) reset monospaced elements to font size 16px in all browsers
  * (de) Schriftgröße von monospaced Elemente in allen Browsern auf 16 Pixel setzen
  *
  * @see: http://webkit.org/blog/67/strange-medium/
  */
  textarea,
  pre,
  code,
  kbd,
  samp,
  var,
  tt {
	  font-family: Consolas, "Lucida Console", "Andale Mono", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
  }

  /* --- general text formatting | Allgemeine Textauszeichnung ------------------------------------------ */

  big {
    /* font-size: 16px; */
    font-size: 114.28571%;
    line-height: 1.3125;
  }

  small {
    /* font-size: 12px; */
    font-size: 85.71429%;
    line-height: 1.75;
  }

  pre,
  code,
  kbd,
  tt,
  samp,
  var {
    font-size: 100%;
  }

  pre {
    font-size: 100%;
    line-height: 1.5;
    margin: 0 0 1.5em 0;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  pre,
  code {
    color: #880000;
  }

  kbd,
  samp,
  var,
  tt {
    color: #666666;
    font-weight: bold;
  }

  var,
  dfn {
    font-style: italic;
  }

  acronym,
  abbr {
    border-bottom: 1px #aaa dotted;
    font-variant: small-caps;
    letter-spacing: .07em;
    cursor: help;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  mark {
    background: yellow;
    color: black;
  }

  hr {
    color: #fff;
    background: transparent;
    margin: 0 0 0.75em 0;
    padding: 0 0 0.67857em 0;
    border: 0;
    border-bottom: 1px #eeeeee solid;
  }

  /*--- Links ----------------------------------------------------------------------------------------- */
  a:active {
    outline: none;
  }

  /* --- images ------------------ */
  img,
  figure {
    margin: 0;
  }

  .flexible {
    margin-bottom: 1.5em;
    max-width: 100%;
    height: auto;
  }

  /* IE6 workaround - 2% space for possible borders */
  * html .flexible {
    width: 98%;
  }

  .bordered {
    margin-bottom: 1.5em;
    border: 2px white solid;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  }

  /**
  * ----------------------------------------------------------------------- #
  *
  * Generic Content Classes
  *
  * (en) standard classes for positioning and highlighting
  * (de) Standardklassen zur Positionierung und Hervorhebung
  *
  * @section content-generic-classes
  */
  .highlight {
    color: #cc3300;
  }

  .dimmed {
    color: #888888;
  }

  .box {
    border-radius: 0.3em;
    border-width: 1px;
    border-style: solid;
    border-color: #888;
    border-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
    color: #444;
    color: rgba(0, 0, 0, 0.8);
    padding: 1.42857em;
    margin: 0 0 1.5em 0;
  }

  .box>*:last-child {
    margin-bottom: 0;
  }

  .label {
    font-family: Verdana, Geneva, sans-serif;
    padding: 1px 6px 2px;
    display: inline-block;
    vertical-align: middle;
    letter-spacing: normal;
    white-space: nowrap;
    border-radius: 3px;
    background: #06C;
    color: #fff;
    font-size: 10px;
    line-height: 12px;
  }

  .success {
    background: #88cc88;
  }

  .warning {
    background: #cccc88;
  }

  .error {
    background: #cc8888;
  }

  /**
  * ------------------------------------------------------------------------------------------------- #
  *
  * Tables | Tabellen
  *
  * (en) Generic classes for table-width and design definition
  * (de) Generische Klassen für die Tabellenbreite und Gestaltungsvorschriften für Tabellen
  *
  * @section content-tables
  */
  table {
    width: 100%;
    border-collapse: collapse;
    color: #444444;
    border-top: 1px #ccc solid;
    border-bottom: 1px #ccc solid;
    margin: 0 0 1.35714em 0;
  }

  table.narrow {
    margin: 0 0 1.42857em 0;
  }

  table.narrow th,
  table.narrow td {
    padding: 0 0.5em;
    line-height: 1.42857;
  }

  table.fixed {
    table-layout: fixed;
  }

  table.bordertable tbody th[scope="row"] {
    background: #f0f0f0;
  }

  table.bordertable tbody th {
    border-right: 1px solid #ccc;
  }

  table.bordertable tbody td {
    border-right: 1px solid #ccc;
  }

  caption {
    font-variant: small-caps;
  }

  th,
  td {
    line-height: 1.5em;
    vertical-align: top;
    padding: 0.71429em 0.5em;
  }

  th *:first-child,
  td *:first-child {
    margin-top: 0;
  }

  th.nowrap,
  td.nowrap {
    white-space: nowrap;
  }

  thead th {
    text-align: left;
    color: #000;
  }

  tbody {
    /* highlight row on mouse over */
  }

  tbody th {
    text-align: left;
    border-top: 1px solid #ccc;
  }

  tbody td {
    text-align: left;
    border-top: 1px solid #ccc;
  }

  tbody tr:hover th,
  tbody tr:hover td {
    background: #f8f8f8;
  }
}