div.link {
	display: inline;
}

#col3_content .linkGroup .defaultLink,
#logo .defaultLink,
.resultImage .defaultLink,
.templatePreview .defaultLink {
	background: none;
}

a {
	&.debugItem.defaultLink {
		display: inline-block;
		margin: 5px 10px 5px 0;
	}

	&.icon.text {
		margin-right: 5px;
	}
	
	&.cms-homepage-link {
		background: none;
		padding-left: 0;
		&:focus {
			border: 2px solid black;
		}
	}
}

span + .dummyLink,
.dummyLink + span,
.cms-formattedlink span + .defaultLink,
.cms-formattedlink .defaultLink + span {
	margin-left: 5px;
}

div.icon.text {
	margin-right: 5px;
}
