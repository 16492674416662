.ym-form {
	div.message {
		
		@defaultColor : #AA0225;
		
		border: 1px solid;
		padding: 10px;
		
		h1 {
			color: @defaultColor;
		}

		h2 {
			color: @defaultColor;
		}

		h3 {
			color: @defaultColor;
		}

		&.info {
			color: #4C73A1;
			background-color: #EAF7FF;
			border-color: #C5DBE6;
		}

		&.success {
			color: #4F8A10;
			background-color: #DFF2BF;
		}

		&.warning {
			color: #9E7D4A;
			background-color: #FBF6DE;
			border-color: #B1905C;
		}

		&.error {
			color: @defaultColor;
			background-color: #F6D3CF;
			border-color: #D66C68;
		}
	}
}

