@import "../utilities/lib.less";

.tooltipTrigger {
	&.icon, &.infoIcon {
		display: inline-block;
	}
}

.tooltip, .errorTooltip {
	box-shadow: none;
	padding: 10px;
}

.tooltip:not(.errorTooltip):not(.noNose).informationBlock {
	&:before {
		content: "";
		height: 15px;
		display: block;
		position: absolute;
		width: 15px;
		border-top: 0;
		border-left: 0;
		background: #ffffff;
	}

	&.topright:before {
		bottom: -9px;
		left: 2px;
		transform: rotate(45deg);
	}

	&.topleft:before {
		bottom: -9px;
		right: 2px;
		transform: rotate(45deg);
	}

	&.bottomleft:before {
		top: -9px;
		right: 3px;
		transform: rotate(225deg);
	}

	&.bottomright:before {
		top: -9px;
		left: 2px;
		transform: rotate(225deg);
	}
}

.item.horizontal .itemBox:last-of-type .tooltipTrigger.infoIcon {
	margin-right: 0;
}