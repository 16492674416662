@import '../utilities/lib.less';
div.item {
	&.buttonline {
		> * {
			margin: 0 15px 0 0;
		}

		&.top {
			text-align: left;
			background: @TableColorLightGrey;
			padding: 9px 7px 5px;
			border: 1px solid @MediumGrey1;
			border-radius: @DefaultBorderRadius @DefaultBorderRadius 0 0;
			border-bottom: 0;
			span {
				color: @DarkGrey7;
			}
		}
		&.mySet_footer {
			background: @TableColorLightGrey;
			border: 1px solid @MediumGrey1;
			border-radius: 0 0 @DefaultBorderRadius @DefaultBorderRadius;
			border-top: 0;
			padding: 4px;
		}
	}

	&.vertical {
		.item.horizontal {
			display: flex;
		}

		a.icon.text {
			margin: 0 0 4px;
		}
	}
}

.item {
	&.linkGroup {
		text-align: center;
	}

	&.horizontal .itemBox {
		margin: 0 2px 2px 0;
	}

	&.horizontal-right > .itemBox {
		float: none;
	}
}

#editMaskContainer #imageSelectionRectangleSubmit {
	text-align: right;
}

.picturePreview div.item.buttonline {
	& , * {
		margin-bottom: 0;
	}
}

.field.buttonline .item.horizontal {
	display: inline-block;
}