
.lightboxContainer , .actions , .closeLightbox , .linkBox {
	position: absolute;
}

.actions {
	width: 30%;
	.switchLeft , .switchRight {
		height: 10px;
		width: 10px;
		cursor: pointer
	}

	.switchLeft {
		float: left;
	}

	.switchRight {
		float: right;
	}

	a , div {
		display: table-cell;
		vertical-align: middle;
	}

	a {
		background-position: left center;
		cursor: pointer;
	}

	div.inactive {
		cursor: auto;
	}

	.linkBox {
		a.icon.edit_small {
			display: inline-block
		}
	}
}

.lightboxTrigger {
	cursor: pointer;
}

.closeLightbox {
	height: 10px;
	width: 10px;
	cursor: pointer
}

.hoverEffect {
	background-image: url("../../img/overlay_background_dark.png");
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: none;
}

.previewIcon {
	background-image: url("../../img/loupe.png");
	height: 26px;
	width: 26px;
	position: absolute;
}

.resultImage > a , .resultImage > div, .hoverEffectWrapper {
	position: relative;
	display: inline-block;
	border: none;

	&:hover {
		border: none;
	}

	&.hover > .hoverEffect {
		display: block;
		overflow: hidden;
	}
}

.hoverEffectWrapper {
	line-height: 0;
}
