@import '../../utilities/lib.less';

@RowHeight: 25px;
@CampaignHeight: 20px;
@WmHeight: 8px;
@CentralWmHeight: 4px;
@AusspielungWmHeight: 14px;
@ErscheinungsHeight: 6px;
@CellCount: 12;
@GridLineColor: #d5d5d5;

@TodayIndicatorIndex: 120;

.marketing-planer-kalender {
	margin-top: 10px;
	width: ~"calc(100% - 25px)";
	display: flex;
	flex-flow: column;
	-webkit-display: -webkit-flex;
	-webkit-flex-flow: column;

	&.sticky {
		.cal_header_sticky {
			position: fixed;
			z-index: 9999;
			top: 0;
		}
	}

	.calender {
		margin-bottom: 20px;
	}

	.cal_header_rough {
		display: flex;
		font-size: 12px;

		.cal_header_rough_cell {
			&:not(:first-child) {
				border-left-style: solid;
				border-left-width: 1px;
				margin-left: -1px;
			}
		}
	}

	.cal_interaction_container {
		order: 0;
		width: 100%;
		-webkit-order: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;

		.switch_container {
			padding-top: @RowHeight;
			padding-bottom: @RowHeight;

			.switch {
				float: right;
			}
		}

		.bas-inputButton {
			margin-right: 50px;

			&.dummy.button.today {
				margin-top: @RowHeight;
			}
		}
	}

	.cal_header {
		width: 66.666%;
		margin-left: 33.333%;
		height: @RowHeight * 2;
		order: 1;
		-webkit-order: 1;

		&.month .cal_cell.detail:not(.nohover) {
			cursor: pointer;
		}

		&.kw .cal_cell.rough:not(.nohover) {
			cursor: pointer;
		}

		.prev,
		.next {
			width: @RowHeight;
			height: @RowHeight;
			position: absolute;
			cursor: pointer;
			line-height: @RowHeight;
			text-align: center;
			font-size: 16px;
			display: block;
			.user-select (none);
		}

		.prev {
			left: 0;
			margin-left: -@RowHeight;
		}

		.next {
			right: 0;
			margin-right: -@RowHeight;
		}

		.cal_row {
			height: 25px;
			display: flex;

			&.rough {
				font-size: 12px;
			}

			&.detail {
				position: relative;
			}
		}

		.cal_cell.header {
			cursor: default;
			overflow: hidden;
			text-align: center;
			line-height: @RowHeight;
			height: 100%;
			float: left;
			box-sizing: border-box;

			&.rough {
				transition: all .5s linear;
				transition-property: width;

				&:not(:first-child) {
					border-left-style: solid;
					border-left-width: 1px;
				}
			}

			&.detail {
				width: calc(100% / @CellCount);
				border-right: 1px solid transparent;

				&.inactive {
					display: none;
				}
			}
		}
	}

	.cal_row {
		&.message {
			.text {
				line-height: @RowHeight;
				padding-bottom: 1px;
				margin-top: -1px;
				margin-right: 30px;
				height: 100%;
				float: left;

				.infoIcon {
					margin-left: 5px;
				}
			}
		}
	}

	.calender {
		position: relative;
		margin-top: 50px;
		order: 2;
		-webkit-order: 2;

		.graph {
			background: repeating-linear-gradient(90deg, @GridLineColor, @GridLineColor 1px, transparent 0px, transparent 8.33%);
		}

		.today_indicator_container {
			position: absolute;
			z-index: @TodayIndicatorIndex;
			left: 0;
			right: 0;
			bottom: 0;
			top: -38px;
			pointer-events: none;

			.gantt {
				overflow: initial !important;
			}

			.today_indicator {
				height: 100%;
				pointer-events: none;
				z-index: 1;

				.cal_today {
					height: 100%;

					.cal_today_font {
						margin-left: -74px;
						font-size: 10px;
						text-align: center;
						width: 150px;
						line-height: 12px;
					}

					.cal_today_indicator {
						border-right-width: 2px;
						border-right-style: solid;
						margin-right: 3px;
						width: 0;
						height: ~"calc(100% - 24px)";
					}
				}

				.cal_campaign.gantt {
					overflow: hidden;
					width: 66.6666%;
					float: right;
					pointer-events: none;
					height: 100%;
					display: flex;
				}
			}
		}

		.expand.icon {

			&.openAll,
			&.closeAll {
				order: -1;
				display: block;
				width: @RowHeight;
				background-position: center;
				height: @RowHeight;
			}
		}

		.cal_row {
			transition: height, min-height .5s linear;
			width: 100%;
			min-height: @RowHeight;
			line-height: @RowHeight;
			overflow: hidden;

			.cal_werbemittel.info .cal_description {
				width: ~"calc(33.3333% - 80px)";
			}

			&:not(.empty) {
				&:not(.cal_kategorie) {
					.cal_cell {
						position: relative;
					}

					&:not(.firstInKategorie),
					&.only {
						.cal_campaign {

							&.info,
							&.gantt {
								border-top-width: 0;
								border-top-style: solid;
							}
						}

						.cal_werbemittel {

							&.info,
							&.gantt {
								border-top-width: 0;
								border-top-style: none;
								margin-top: 0;
							}
						}
					}
				}

				.cal_werbemittel.info .cal_description {
					margin-left: 60px;
				}

				.cal_campaign.info .cal_description:not(.single_campaign) {
					padding-left: 50px;
				}

				.cal_campaign,
				.cal_werbemittel {

					&.gantt {
						width: 66.6666%;
						overflow: hidden;
						float: right;
						margin-bottom: -1px;
						display: flex;

						.cal_cell {
							float: left;
							height: inherit;
							width: 100% / 12;
							padding-bottom: 1px;

							&.inactive {
								display: none;
							}

							.last {
								margin-left: -1px;
							}

							.wm {
								display: flex;

								.defaultLink:not(.icon) {
									width: 100%;
									position: relative;
								}
							}

							.defaultLink:not(.icon) {
								border: 0;
							}

							.defaultLink:not(.icon) {
								margin-left: 0;
								padding-left: 0;
								color: transparent;
								display: block;

								&.wm {
									display: flex;
								}

								&:not(.hover) {
									background: none;
								}
							}
						}
					}

					&.info {
						.expanded {
							.cal_description {
								&.werbemittel {
									display: flex;
								}
							}
						}

						.cal_description {
							height: @RowHeight;
							line-height: @RowHeight;
							display: flex;
							align-items: center;

							&.werbemittel {
								display: none;
								overflow: hidden;
							}

							.desc_wrapper {
								flex-grow: 99;
								display: flex;
								align-items: center;
								max-width: calc(100% - 35px);

								.defaultLink {
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								}

								>span {
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									cursor: default;
								}
							}
						}
					}
				}
			}
		}
	}

	svg.filter {
		margin-bottom: -3px;
		padding-right: 3px;
	}

	input {
		&::-ms-clear {
			display: none;
		}
	}
}

.marketing-planer-kalender,
.cal_legende {

	.cal_icon,
	.cal_legende_status_icon {
		&.locked {
			background-image: url("../../../img/icons/marketingplaner/icon_locked.png");
		}

		&.done {
			background-image: url("../../../img/icons/marketingplaner/icon_check.png");
		}

		&.gespeichert {
			background-image: url("../../../img/icon_manually_saved.png");
		}

		&.budget {
			background-image: url("../../../img/icons/marketingplaner/icon_budget.png");
		}

		&.no_budget {
			background-image: url("../../../img/icons/marketingplaner/icon_no_budget.png");
		}

		&.changes {
			background-image: url("../../../img/icons/marketingplaner/icon_aktualisieren.png");
		}
	}

	.cal_icon {
		width: 15px;
		min-width: 15px;
		height: 15px;
		margin-left: 5px;
		margin-right: 5px;
		background-size: cover;

		&.changed-by-user {
			width: 20px;
			height: 20px;
			margin-left: 2.5px;
			margin-right: 2.5px;
		}

		svg#changed_by_user {
			margin: -4px;
		}
	}

	svg#changed_by_user {
		pointer-events: none;
	}

	.kampagne {
		box-sizing: border-box;

		&.row {
			height: @RowHeight;
			position: relative;
			display: flex;
			align-items: center;
		}

		&.normal,
		&.own {
			background-color: black;
			height: @CampaignHeight;
			width: 100%;
		}

		&.own {
			border-width: 2px;
			border-style: solid;
		}
	}

	.wm {
		box-sizing: border-box;

		&.cal_legende_type_preview {
			position: relative !important;
			display: block;
		}

		&.bar {
			//background: grey;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
		}

		&.row {
			height: @RowHeight;
			position: relative;
		}

		&.own {
			border-width: 1px;
			border-style: solid;
		}

		&.normal,
		&.own,
		&.normal:not(.teilgenommen) {
			height: @WmHeight;
		}

		&.national,
		&.sperrfrist {
			height: @CentralWmHeight;
		}

		&.werbezeitraum {
			height: @AusspielungWmHeight;
			border-width: 2px;
			border-style: solid;
		}

		&.erscheinungstermin {
			height: @ErscheinungsHeight;
			position: absolute;
		}
	}

	.calendar_container {
		display: flex;
		flex-direction: row;
	}

	.descriptions {
		min-width: calc(33.3333% - 20px);
		max-width: calc(33.3333% - 20px);
		margin-right: 20px;
	}

	.graph {
		overflow-x: hidden;
		width: 100%;
	}

	.graph .category_graph_container:first-child .category,
	.descriptions .category_description_container:first-child .category {
		height: 30px;
		padding-top: 0;
	}

	.category_description_container,
	.category_graph_container {
		&.collapsed {

			.cal_row,
			.campaign_container,
			.divider {
				display: none;
			}
		}
	}

	.category {
		background-color: white;
		height: 50px;
		padding-top: 20px;
		padding-bottom: 2px;
		font-weight: bold;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;
		padding-left: 35px;
	}

	.description {
		box-sizing: border-box;
	}

	.description.wm {
		padding-left: 20px;
	}

	.description.campaign {
		display: flex;
	}

	.campaign_container.expanded {
		background-color: rgba(240, 240, 240, 0.6);

		.wm.row {
			display: flex;
			align-items: center;

			.wm.bar {
				display: block;
			}
		}
	}

	.bar {
		color: white;
		position: relative;
		box-sizing: border-box;
	}

	.divider {
		border-top: 1px solid @GridLineColor;
	}

	.wm {
		display: none;
	}

	.tooltip {
		display: none;
	}

	.wm:hover .tooltip {
		display: block;
		position: fixed;
		overflow: hidden;
		background: lightgray;
		border: 1px solid grey;
		padding: 10px;
		z-index: 100;
	}
}