@import '../../utilities/lib.less';

.defaultLink,
.dummyLink{
	:focus-visible {
		outline: @DefaultOutlineWidth @DefaultOutlineStyle @DefaultOutlineColor !important;
	}
	display: inline-block;
	color: @DefaultLinkNormalColor;
	&.show_extern {
		background-position: 10px 5px !important;
		margin-top: 0 !important;
	}
	&.inactive {
		color: @DefaultLinkDisabledColor;
	}
}

.tabindex:focus-visible {
	outline: @DefaultOutlineWidth @DefaultOutlineStyle @DefaultOutlineColor;
}

span.link_inactive {
	color: @DefaultLinkDisabledColor;
}

.dummyLink:hover {
	text-decoration: none;
	color: @DefaultLinkHoverColor;
}

span + .dummyLink,
.dummyLink + span,
.cms-formattedlink span + .defaultLink,
.cms-formattedlink .defaultLink + span {
	margin-left: 5px;
}

.defaultLink.icon , #logo .defaultLink,
.resultImage .defaultLink {
	padding-left: 0;
}

.itemBox .defaultLink {
	line-height: 21px;
	background-position: 0 7px;
}

.item.vertical .itemBox .defaultLink {
	line-height: normal;
	background-position: 0 4px;
}

.itemBox .defaultLink.icon,
.item.vertical .itemBox .defaultLink.icon {
	background-position: 0 0;
}

.nutzungsbedingung img {
	width: 400px;
	height: 400px;
	margin-top: 20px;
	margin-left: 284px;
}

.nutzungsbedingung-header,
.nutzungsbedingung-text {
	color: @Blue;
	text-align: center;
	padding: 5px 10px 3px 10px;
	margin: 0 0 7px 0;
}

.nutzungsbedingung-header {
	font-weight: bold;
	font-size: 15px;
}

.nutzungsbedingung-icon{
	margin-left: 203px;
}

.nutzungsbedingung-text {
	font-size: 12px;
}

.nutzungsbedingung-background .bas-formSection {
	background-color: @White;
}

.cms-container > p > .defaultLink {
	line-height: 14px;
}

.bas-inputButton + a.defaultLink {
    margin-left: 10px;
}
